const SuccessSmile = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.5" d="M0 17C0 7.61116 7.61116 0 17 0V0C26.3888 0 34 7.61116 34 17V17C34 26.3888 26.3888 34 17 34V34C7.61116 34 0 26.3888 0 17V17Z" fill="#74DD9E" />
    <ellipse cx="9.00513" cy="10.9615" rx="2.00513" ry="1.96154" fill="#3F8452" />
    <ellipse cx="25.0464" cy="10.9615" rx="2.00513" ry="1.96154" fill="#3F8452" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.54241 15.5386C9.39425 15.5386 10.0848 16.1972 10.0848 17.0097C10.0848 21.1953 13.3159 24.3655 17.0256 24.3655C20.7354 24.3655 23.9665 21.1953 23.9665 17.0097C23.9665 16.1972 24.657 15.5386 25.5089 15.5386C26.3607 15.5386 27.0513 16.1972 27.0513 17.0097C27.0513 22.5741 22.6862 27.3078 17.0256 27.3078C11.365 27.3078 7 22.5741 7 17.0097C7 16.1972 7.69056 15.5386 8.54241 15.5386Z" fill="#3F8452" />
  </svg>
);

export default SuccessSmile;
