import Money from 'components/global/Money';

const InvoiceAgingItem = ({ type, amount }) => (
  <div>
    <div className="lead border-bottom border-gray-100">{type} Past Due</div>
    <h3 className={`lead mt-8 ${type === 'Overdue' ? 'text-danger' : 'text-black'}`}><Money amount={amount} /></h3>
  </div>
);

export default InvoiceAgingItem;
