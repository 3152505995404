import { Tab, Tabs } from 'react-bootstrap';
import EmailSignupForm from 'components/views/Auth/SignUp/_components/EmailSignupForm';
import VerificationForm from 'components/views/Auth/SignUp/_components/VerificationForm';
import PasswordForm from 'components/views/Auth/SignUp/_components/PasswordForm';
import { $signUpFunctionality } from 'components/views/Auth/SignUp/Signup.helpers';

const SignUpForm = () => (
  <Tabs activeKey={$signUpFunctionality.value?.currentPage} className="border-0 d-none">
    <Tab eventKey="emailForm" title="Email Form">
      <EmailSignupForm
        isEmbed
      />
    </Tab>
    <Tab eventKey="verify" title="Verify">
      <VerificationForm
        invitedUser={false}
        isEmbed
      />
    </Tab>
    <Tab eventKey="createPassword" title="Create Password">
      <PasswordForm isEmbed />
    </Tab>
    <Tab eventKey="earlyAccess" title="Early Access">
      <h2 className="text-center text-primary">
        Welcome to Accru! We&apos;ve created your account and have added you to our early access waitlist. We will be in touch as soon as Accru is ready to go
      </h2>
    </Tab>
  </Tabs>
);

export default SignUpForm;
