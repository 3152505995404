import $user from 'signals/User.signals';

export function getBaseRequest(filterData) {
  const take = filterData.perPage || 20; // Todo: Move this amount into a CONST so that we can default to 20 for all situations
  const baseRequest = {
    organizationId: $user?.value?.currentOrganization?.id,
    skip: (filterData.page - 1) * take,
    take,
    sorting: filterData.selectedSorting,
  };

  return baseRequest;
}

export default getBaseRequest;
