/* eslint-disable jsx-a11y/no-static-element-interactions */
import { forwardRef } from 'react';

const CustomForm = forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

export default CustomForm;
