import { Row, Col, Container, Button } from 'react-bootstrap';
import { $invoiceForm, $invoiceUpload, handleAddInvoice } from 'components/views/InvoiceAdd/InvoiceAdd.helpers';
import BillHero from 'components/views/Bills/_components/BillHero';
import BillSummary from 'components/views/Bills/_components/BillSummary';

const InvoicePreview = ({ handleNext }) => {
  const invoicesToAdd = $invoiceUpload.value.length
    ? $invoiceUpload.value
    : [$invoiceForm.value];

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-56">
            Invoice Preview
          </h1>
        </Col>
      </Row>
      <Container className="bg-white p-48 z-index-1" style={{ maxWidth: 1300 }}>
        <Row className="d-flex justify-content-center pb-64">
          <Button
            type="button"
            variant="primary"
            className="w-25 py-16 mb-24 mx-8"
            onClick={() => handleAddInvoice({ handleNext, send: true })}
          >
            SAVE & SEND
          </Button>
          <Button
            type="button"
            variant="outline-primary border border-2 border-primary"
            className="w-25 py-16 mb-24 mx-8"
            onClick={() => handleNext({ current: 'preview', next: 'form' })}
          >
            EDIT INVOICE
          </Button>
        </Row>
        <Container className="p-48 custom-shadow position-relative z-index-1">
          <Row className="z-index-1 text-start">
            <BillHero data={invoicesToAdd[0]} />
            <BillSummary data={invoicesToAdd[0]} />
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default InvoicePreview;
