import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/global/Loader';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import TabNav from 'components/global/TabNav/TabNav';
import CouponCard from 'components/views/SettingsSubscription/_components/CouponCard';
import PaymentInformation from 'components/views/SettingsSubscription/_components/PaymentInformation';
import PlanList from 'components/views/SettingsSubscription/_components/PlanList';
import SubscriptionErrorFallback from 'components/views/SettingsSubscription/_components/SubscriptionError';
import { $settingsSubscriptionUI, featuresArray, fetchAndSetOrganizationSubscriptions, resetSubscriptionPurchase, subscriptionRecurrenceMap } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import { useEffect } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter';

const SettingsSubscriptionView = () => {
  const { view, recurrence, isCompareModalOpen, isLoading, subscriptions, pricing, selectedPricing } = $settingsSubscriptionUI.value;

  useEffect(() => {
    $settingsSubscriptionUI.reset();
    fetchAndSetOrganizationSubscriptions();
  }, []);

  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Subscription</h1>
      <Row className="mx-0 mb-16">
        <Col sm={9} className="px-0 my-auto">
          <h4 className="mb-0">Here you can choose, and manage your current plan.</h4>
        </Col>
        <Col sm={3} className="d-flex justify-content-end px-0">
          <Button variant="link" className="p-0" onClick={() => $settingsSubscriptionUI.update({ isCompareModalOpen: true })}>Compare Plans</Button>
        </Col>
      </Row>
      {(isLoading || !subscriptions?.items || !pricing?.options) ? (<Loader className="d-flex align-items-center justify-content-center my-40" />) : (
        <>
          {view === 'PLAN_LIST' && (
          <>
            <CouponCard coupon={pricing?.organization_coupon} />
            <TabNav
              tabs={pricing.options.map(({ renew_interval }) => ({ key: renew_interval, title: subscriptionRecurrenceMap[renew_interval] || capitalizeFirstLetter(renew_interval) }))}
              TabPaneComponents={pricing.options.map(() => <PlanList />)}
              onClick={(t) => $settingsSubscriptionUI.update({ recurrence: t.key })}
              activeTab={recurrence}
              defaultActiveTab="info"
              template="underline"
            />
          </>
          )}
          {view === 'PLAN_PURCHASE' && (
          <>
            <CouponCard coupon={(!!selectedPricing && selectedPricing.price_total_amount < selectedPricing.original_price_total_amount) ? selectedPricing?.organization_coupon : null} />
            <PaymentInformation type={recurrence} />
          </>
          )}
        </>
      )}

      <SimpleModal
        isShow={isCompareModalOpen}
        onClose={() => $settingsSubscriptionUI.update({ isCompareModalOpen: false })}
        onCancel={() => $settingsSubscriptionUI.update({ isCompareModalOpen: false })}
        cancelText="Close"
        title="Compare Plans"
        isHideSave
        className="p-0"
      >
        <Table striped responsive className="mb-0">
          <thead className="bg-light">
            <tr className="">
              <td
                className="border-0 fw-800 bg-light py-16 ps-16"
              >
                Features
              </td>
              <td
                className="border-0 fw-800 bg-light py-16 text-center"
              >
                Free
              </td>
              <td
                className="border-0 fw-800 bg-light py-16 text-center"
              >
                Basic
              </td>
              <td
                className="border-0 fw-800 bg-light py-16 text-center pe-16"
              >
                Pro
              </td>
            </tr>
          </thead>
          <tbody>
            {featuresArray.map((feature) => (
              <tr key={feature?.id} className="px-16">
                <td className="py-16 ps-16">{feature?.label}</td>
                <td className="py-16 text-center"><FontAwesomeIcon icon={feature.free ? faCheck : faXmark} className={feature.free ? 'text-success-700' : 'text-dark'} /></td>
                <td className="py-16 text-center"><FontAwesomeIcon icon={feature.basic ? faCheck : faXmark} className={feature.basic ? 'text-success-700' : 'text-dark'} /></td>
                <td className="py-16 text-center pe-16"><FontAwesomeIcon icon={feature.pro ? faCheck : faXmark} className={feature.pro ? 'text-success-700' : 'text-dark'} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </SimpleModal>
    </div>
  );
};

const SettingsSubscription = () => (
  <ErrorBoundary
    FallbackComponent={SubscriptionErrorFallback}
    onReset={() => {
      resetSubscriptionPurchase();
    }}
  >
    <SettingsSubscriptionView />
  </ErrorBoundary>
);

export default SettingsSubscription;
