import Loadable from 'components/global/Loadable';
import { Form } from 'react-bootstrap';

const DatePicker = ({
  name,
  value,
  placeholder,
  signal,
  loadableAnimation,
  className,
  variant = 'form-control', // || form-control-border
}) => {
  if (!signal || !name) {
    return new Error(`DatePicker has no signal or name (Name: ${name})`);
  }
  return (
    <Loadable signal={signal} animation={loadableAnimation} className={className}>
      <Form.Control
        className={`w-100 text-start bg-white ${variant} ${className}`}
        type="date"
        placeholder={placeholder}
        value={value || signal.value[name]}
        onChange={(e) => signal.update({ [name]: e.target.value })}
        onKeyDown={(e) => e.preventDefault()}
      />
    </Loadable>
  );
};

export default DatePicker;
