import { ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL } from 'accru-client';
import formatCurrency from 'utils/formatCurrency';

const recurrenceStringMap = {
  [ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.MONTHLY]: 'mo',
  [ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.YEARLY]: 'yr',
};

const PriceOption = ({ originalPrice, currentPrice, recurrence, ...rest }) => {
  const recurrenceText = recurrenceStringMap[recurrence];
  return (
    <span {...rest}>{`${formatCurrency({ amountCents: currentPrice })}/${recurrenceText}`}
      {originalPrice !== currentPrice && (
        <>
          {' '}
          <span className="text-decoration-line-through">
            {`${formatCurrency({ amountCents: originalPrice })}/${recurrenceText}`}
          </span>
        </>
      )}
    </span>
  );
};

export default PriceOption;
