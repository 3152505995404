import ContentWrapper from 'components/global/ContentWrapper';
import Loadable from 'components/global/Loadable';
import { $listStatement, $filterStatement, $uiStatement, $detailStatement } from 'components/views/Statement/statement.signals';
import InfoCard from 'components/views/Statement/_components/InfoCard';
import InvoiceCountItem from 'components/views/Statement/_components/InvoiceCountItem';
import StatementAddress from 'components/views/Statement/_components/StatementAddress';
import Welcome from 'components/views/Statement/_components/Welcome';
import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useEffectAsync from 'utils/useEffectAsync';
import SignalTable from 'components/global/SignalTable';
import { statementTableFormat } from 'components/views/Statement/_components/statementTableFormat';
import InvoiceAgingItem from 'components/views/Statement/_components/InvoiceAgingItem';
import { fetchAndSetStatement, handleClickInvoiceCountItem, handleClickTabToggle, handleCustomerSync } from 'components/views/Statement/statements.helpers';
import SelectInput from 'components/global/Inputs/SelectInput';
import { useEffect } from 'react';
import { faArrowLeft, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import getTimeZoneAbbreviation from 'utils/getTimeZoneAbbreviation';

const Statement = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const tableData = statementTableFormat({ items: $listStatement.value.items, type: $uiStatement.value.activeTab });

  // Todo: Implement ability to have this just be one invoice in the case we want to only display one invoice in a modal.
  // const invoiceParam = useMemo(() => new URLSearchParams(location?.search).get('invoice_id'), [location]);

  useEffect(async () => {
    $uiStatement.update({
      pathName: location.pathname,
      pathParams: params,
      queryParams: new URLSearchParams(location.search),
      activeTab: 'invoice',
    });

    // Note: updating the $filterStatement will cause the useEffectAsync below to trigger.
    $filterStatement.update({
      sortKey: 'invoice_date',
      order: 'desc',
    });
  }, [params, location]);

  useEffectAsync(fetchAndSetStatement, [$filterStatement.value]);

  return (
    <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0 overflow-hidden my-lg-80 my-64">
      <Loadable template="component" signal={$listStatement}>
        <Container>
          <Row className="mx-0">
            <Nav.Link onClick={() => history.push('/customers')}>

              <p className="lead fw-500 text-primary text-decoration-underline"><FontAwesomeIcon icon={faArrowLeft} className="me-8" />Back to Customers</p>
            </Nav.Link>
          </Row>
          <Row id="Welcome" className="mb-40">
            <Col lg={9}>
              <Welcome />
            </Col>
            <Col lg={3}>
              <Loadable signal={$detailStatement} template="table" length={8}>
                <StatementAddress />
              </Loadable>
            </Col>
          </Row>
          <Row id="Invoice Summary" className="mb-24">
            <Col lg={12}>
              <Row>
                <Col>
                  <Loadable signal={$detailStatement} template="table" length={2}>
                    <InvoiceCountItem count={$detailStatement.value.invoiceCounts.open} type="Open" onClick={handleClickInvoiceCountItem} />
                  </Loadable>
                </Col>
                <Col>
                  <Loadable signal={$detailStatement} template="table" length={2}>
                    <InvoiceCountItem count={$detailStatement.value.invoiceCounts.overdue} type="Overdue" onClick={handleClickInvoiceCountItem} />
                  </Loadable>
                </Col>
                <Col>
                  <Loadable signal={$detailStatement} template="table" length={2}>
                    <InvoiceAgingItem amount={$detailStatement.value.aging['1-30']} type="1-30" />
                  </Loadable>
                </Col>
                <Col>
                  <Loadable signal={$detailStatement} template="table" length={2}>
                    <InvoiceAgingItem amount={$detailStatement.value.aging['31-60']} type="31-60" />
                  </Loadable>
                </Col>
                <Col>
                  <Loadable signal={$detailStatement} template="table" length={2}>
                    <InvoiceAgingItem amount={$detailStatement.value.aging['60 +']} type="60 +" />
                  </Loadable>
                </Col>
                <Col xs={6} lg={2}>
                  <div className="lead border-bottom border-gray-100">Status</div>
                  <SelectInput
                    name="status"
                    options={[
                      { label: 'All', value: 'All', disabled: false },
                      { label: 'Open', value: 'Open', disabled: false },
                      { label: 'Paid', value: 'Paid', disabled: false },
                      { label: 'Overdue', value: 'Overdue', disabled: false },
                    ]}
                    className="mb-40 fw-600"
                    signal={$filterStatement}
                  />
                </Col>
                <Col xs={6} lg={3}>
                  {!!$detailStatement.value.lastUpdated && (
                  <>
                    <Row className="mx-0 lead border-bottom border-gray-100">
                      <div className={`lead w-auto ps-0 ${!$detailStatement.value.lastUpdated || !!$uiStatement.value.isSyncing ? 'invisible' : ''}`}>Last Updated: </div>
                      <Button
                        variant="link"
                        className="p-0 m-0 border-0 text-decoration-none w-auto"
                        onClick={() => handleCustomerSync()}
                      >
                        <FontAwesomeIcon className={`text-primary ${$uiStatement.value.isSyncing ? 'rotating' : ''}`} icon={faArrowsRotate} />
                        <span className={`ms-8 fw-500 base ${!$uiStatement.value.isSyncing ? 'text-decoration-underline' : ''} text-primary`}>{$uiStatement.value.isSyncing ? 'Syncing...' : 'Refresh Data'}</span>
                      </Button>
                    </Row>
                    <h3 className={`lead mt-8 text-black ${!$detailStatement.value.lastUpdated || !!$uiStatement.value.isSyncing ? 'invisible' : ''}`}>{`${moment($detailStatement.value.lastUpdated).format('l @ LT')} ${getTimeZoneAbbreviation()}`}</h3>
                  </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="border-top border-gray-800 pt-24">
            <Col lg={9}>
              <SignalTable
                headers={tableData?.headers || []}
                rows={tableData?.rows || []}
                $filter={$filterStatement}
                $ui={$uiStatement}
              />
            </Col>
            <Col lg={3} className="mt-40 mt-lg-0">
              <InfoCard />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={handleClickTabToggle}
                style={{ padding: 0 }}
                variant="link"
                className="text-primary"
              >
                <u className="lead">
                  {$uiStatement.value.activeTab === 'invoice'
                    ? 'View Transaction History'
                    : 'View Invoice Summary'}
                </u>
              </Button>
            </Col>
          </Row>
        </Container>
      </Loadable>
    </ContentWrapper>
  );
};

export default Statement;
