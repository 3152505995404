import { accruClient } from 'api';

import history from 'utils/history';
import logEvent from 'utils/logEvent';

import $user from 'signals/User.signals';
import $login from 'components/views/Auth/Login/Login.helpers';
import { $global } from 'signals/Global.signals';
import { $onboarding } from 'components/views/Onboarding/Onboarding.helpers';
import { $signUpForm } from 'components/views/Auth/SignUp/Signup.helpers';
import { $acceptInviteDetail, fetchAndSetUserData } from 'components/views/Auth/_shared/Auth.helpers';

import { handleNotification } from 'components/global/Alert/Alert';
import { validateEmail } from 'utils/validateInput';
import { isOnboardingRequired, selectOrganization } from 'components/helpers/User.helpers';

export const INVITE_STEPS = {
  INVITE_INFO: 'inviteInfo',
  SIGN_IN: 'signIn',
};

export const fetchAndSetInviteData = async (location) => {
  try {
    $acceptInviteDetail.loadingStart();

    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const email = urlParams.get('email');
    const organizationId = urlParams.get('organization_id');
    const organizationInviteId = urlParams.get('organization_invite_id');

    const response = await accruClient.auth.getInviteData({
      code,
      email,
      organizationId,
      organizationInviteId,
    });

    $acceptInviteDetail.update({
      invited_by: response?.invited_by,
      organization_id: response?.organization_id,
      organization_name: response?.organization_name,
      target_account_exists: response?.target_account_exists,
      code,
      email,
      organization_invite_id: organizationInviteId,
      isAccepted: false,
    });
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
    history.replace('/');
  } finally {
    $acceptInviteDetail.loadingEnd();
  }
};

export const acceptInvite = async () => {
  try {
    $onboarding.loadingStart();
    $acceptInviteDetail.loadingStart();
    $global.update({
      isLoading: true,
      isLoadingMessage: 'Accepting Invite...',
    });

    await accruClient.auth.acceptInvite({
      code: $acceptInviteDetail.value.code,
      organizationId: $acceptInviteDetail.value.organization_id,
      organizationInviteId: $acceptInviteDetail.value.organization_invite_id,
    });

    $acceptInviteDetail.update({ isAccepted: true });

    await fetchAndSetUserData();

    logEvent({ name: 'signup.accept_invite' }); // TODO @justinffortier or @aaronkplatt: need to change this event name... this function can be called by an existing user

    selectOrganization($acceptInviteDetail.value.organization_id);

    if (isOnboardingRequired) {
      $onboarding.update({ step: 'personalInfo', invitedUser: true });
      history.push({
        pathname: '/onboarding',
        search: `?organization_id=${$acceptInviteDetail.value.organization_id}`,
      });
      return;
    }

    history.push({
      pathname: '/customers',
      search: `?organization_id=${$acceptInviteDetail.value.organization_id}`,
    });
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $onboarding.loadingEnd();
    $acceptInviteDetail.loadingEnd();
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
  }
};

export const handleInviteAccept = async () => {
  try {
    $acceptInviteDetail.loadingStart();

    const { email, target_account_exists } = $acceptInviteDetail.value;
    validateEmail(email);

    if (!$global.value.isSignedIn) {
      if (target_account_exists) {
        $login.update({ mode: 'signin', email });
      } else {
        $login.update({ mode: 'signup', email });
        $signUpForm.update({
          email: $acceptInviteDetail.value.email,
        });
      }

      $acceptInviteDetail.update({ step: 'signIn' });

      return;
    }

    if ($user.value.user.email !== email) throw new Error('Wrong account');

    await acceptInvite();
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $acceptInviteDetail.loadingEnd();
  }
};

export const handleInviteReject = () => {
  history.replace('/');
};
