import { Route, useLocation } from 'react-router-dom';
import NavBar from 'components/global/NavBar/NavBar';
import ContentWrapper from 'components/global/ContentWrapper';
import Redirect from 'components/global/Redirect';
import { $global } from 'signals/Global.signals';
import Loader from 'components/global/Loader';
import { useEffect } from 'react';
import $user from 'signals/User.signals';
import { Button } from 'react-bootstrap';
import { historyPush } from 'utils/history';
import isForbiddenOrganizationUser from 'utils/isForbiddenOrganizationUser';
import { findOrganizationUser, isImpersonating, isOnboardingRequired, selectOrganization } from 'components/helpers/User.helpers';
import firstAvailableOrganizationUser from 'utils/firstAvailableOrganizationUser';
import { reconnectServer } from 'api';
import Intercom from '@intercom/messenger-js-sdk';

const PrivateRoute = ({ component: Component, noNavbar, path, ...rest }) => {
  const { isSignedIn, isBackendConnDown, isLoading } = $global.value;
  const { currentOrganization, user } = $user.value;
  const location = useLocation();

  useEffect(() => {
    let timeout;

    if (isBackendConnDown) {
      const retryConnection = async () => {
        const isConnected = await reconnectServer();
        if (!isConnected) timeout = setTimeout(retryConnection, 5000);
      };
      retryConnection();
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isBackendConnDown]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const queryOrganizationId = urlParams.get('organization_id');
    const localStorageOrganizationId = localStorage.getItem('accru:organization_id');
    if (isSignedIn && user?.organizations) {
      const envSelectedOrganizationId = queryOrganizationId || localStorageOrganizationId;
      const envSelectedOrganizationUser = findOrganizationUser(envSelectedOrganizationId) || firstAvailableOrganizationUser();

      if (envSelectedOrganizationUser?.organization_id && !isImpersonating.value && currentOrganization.id !== envSelectedOrganizationUser.organization_id) {
        selectOrganization(envSelectedOrganizationUser.organization_id);
      }

      Intercom({
        app_id: 'gbvqvtrw',
        user_id: user?.id,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        created_at: Math.floor(new Date(user.created_at).getTime() / 1000),
      });
    }
  }, [isSignedIn, currentOrganization, user, location.search]);

  if (!isSignedIn && !isLoading) {
    return <Redirect to="/login" />;
  }

  if ($global.value?.isLoading) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
          <Loader message={$global.value?.isLoadingMessage ?? 'Loading...'} className="text-center" />
        </div>
      </div>
    );
  }

  if (isBackendConnDown) {
    return (
      <div>
        <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 flex-column">
          <h1>Oops, something went wrong!</h1>
          <Loader message="Reconnecting..." className="text-center" />
          <h1 className="mb-16">or</h1>
          <Button onClick={() => historyPush('/customers')}>Try Again</Button>
        </div>
      </div>
    );
  }

  if (isOnboardingRequired.value && path !== '/onboarding') return <Redirect to="/onboarding" />;
  if (!isOnboardingRequired.value && path === '/onboarding') return <Redirect to="/customers" />;
  if (path !== '/profile' && isForbiddenOrganizationUser()) return <Redirect to="/profile" />;

  return (
    <Route
      {...rest}
      render={(props) => (
        <ContentWrapper fluid className="min-vh-100 px-0">
          {!noNavbar && (
            <div className="pb-40">
              <NavBar />
            </div>
          )}
          <Component {...props} />
        </ContentWrapper>
      )}
    />
  );
};

export default PrivateRoute;
