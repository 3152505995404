import React from 'react';
import { Card, Button } from 'react-bootstrap';

const InfoCard = () => (
  <Card>
    <Card.Body className="px-0 px-md-16 pt-0 mb-16">
      <Card.Title className="mb-32" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
        Good to know
      </Card.Title>
      <Card.Subtitle className="mb-24" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
        Check your online bill daily to make sure the project is staying within budget
      </Card.Subtitle>
      <Card.Text className="mb-16">
        Checking your bill daily keeps you informed and increases the likelihood of your project staying within budget.
      </Card.Text>
      <Button variant="link" href="https://accru.co" target="_blank" style={{ padding: 0 }}>
        Click here to learn more useful tips!
      </Button>
    </Card.Body>
  </Card>
);

export default InfoCard;
