import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useSignal } from '@preact/signals-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY, ORGANIZATION_SUBSCRIPTION_PLAN_ITEM, ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL } from 'accru-client';

import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import PriceOption from 'components/views/SettingsSubscription/_components/PriceOption';

import { $settingsSubscriptionUI, handleCancelSubscription, handleSelectPlan, subscriptionBaseItemInfoMap } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';

/**
 * @component
 * @param {Object} props
 * @param {import('accru-client').OrganizationSubscriptionDefaultPricingOption} props.data
 * @returns {JSX.Element}
 */
const PlanCard = ({ data }) => {
  const { subscriptions, recurrence } = $settingsSubscriptionUI.value;

  const $isCancelPlanModalOpen = useSignal(false);
  const $isHovered = useSignal(false);

  const currentCardPlanPricing = data.base_item;

  const selectedCardData = subscriptionBaseItemInfoMap[currentCardPlanPricing.item_type];

  const currentItemSubscription = currentCardPlanPricing.current_item_subscription;
  const hasSomeActivePaidSubscription = subscriptions.items.filter(subscription => subscription.items.some(subscriptionItem => subscriptionItem.category === ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY.PLAN && subscriptionItem.item_type !== ORGANIZATION_SUBSCRIPTION_PLAN_ITEM.FREE_PLAN && !subscription.canceled_at)).length > 0;
  const isCurrentCardSubscriptionActive = !!currentItemSubscription;

  const priceSetup = useMemo(() => {
    const priceData = {};

    if (currentItemSubscription && !currentItemSubscription.canceled_at) {
      priceData.recurrence = currentItemSubscription.renew_interval;
      priceData.originalPrice = currentItemSubscription.price_total_amount || currentCardPlanPricing.original_unit_price;
      priceData.currentPrice = currentItemSubscription.price_total_amount;
    } else {
      priceData.recurrence = recurrence;
      priceData.originalPrice = currentCardPlanPricing.original_unit_price;
      priceData.currentPrice = currentCardPlanPricing.unit_price;
    }

    priceData.secondaryRecurrence = priceData.recurrence === ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.YEARLY ? ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.MONTHLY : ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.YEARLY;
    priceData.secondaryOriginalPrice = priceData.recurrence === ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.YEARLY ? priceData.originalPrice / 12n : priceData.originalPrice * 12n;
    priceData.secondaryCurrentPrice = priceData.recurrence === ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.YEARLY ? priceData.currentPrice / 12n : priceData.currentPrice * 12n;

    return priceData;
  }, [recurrence, currentItemSubscription, currentCardPlanPricing]);

  useEffect(() => () => {
    if ($isCancelPlanModalOpen.value) {
      $isCancelPlanModalOpen.value = false;
    }
  }, []);

  const Action = useMemo(() => {
    if (currentCardPlanPricing.current_item_pending_purchase_subscription?.id) {
      return (
        <Row className="mx-0 my-8">
          <Button
            onClick={() => handleSelectPlan(currentCardPlanPricing.item_type)}
            variant="primary"
            className="w-auto px-48"
          >
            <p className="mb-0 text-white">Complete Purchase</p>
          </Button>
        </Row>
      );
    }

    if (!!currentItemSubscription && !currentItemSubscription.canceled_at) {
      return (
        <Row className="mx-0 my-8">
          <Button onClick={() => { $isCancelPlanModalOpen.value = true; }} variant="danger" className="w-auto px-48">
            <p className="mb-0 text-white">Cancel Plan</p>
          </Button>
        </Row>
      );
    }

    if ((!currentItemSubscription || !!currentItemSubscription.canceled_at)) {
      const isDisabled = !currentCardPlanPricing.is_purchase_available || !!hasSomeActivePaidSubscription;
      return (
        <Row className="mx-0 my-8">
          <OverlayTrigger
            key="overlay-trigger"
            placement="bottom"
            show={isDisabled && $isHovered.value}
            overlay={(
              <Tooltip id="tooltip-bottom" className="d-block">
                {!currentCardPlanPricing.is_purchase_available ? 'This plan is currently not available for purchase.' : 'You have an active subscription. Please cancel it before purchasing a new plan.'}
              </Tooltip>
          )}
          >
            <span
              className="w-auto p-0 m-0"
              onMouseEnter={() => { $isHovered.value = true; }}
              onMouseLeave={() => { $isHovered.value = false; }}
            >
              <Button
                onClick={() => !!currentCardPlanPricing.is_purchase_available && handleSelectPlan(currentCardPlanPricing.item_type)}
                disabled={isDisabled}
                variant="dark"
                className="w-auto px-48"
              >
                <p className="mb-0">Choose Plan</p>
              </Button>
            </span>
          </OverlayTrigger>
        </Row>
      );
    }

    return <></>;
  });

  const Badge = useMemo(() => {
    if (!isCurrentCardSubscriptionActive && !hasSomeActivePaidSubscription && selectedCardData.isRecommended) {
      return (
        <Col xs={4} className="p-0 text-end">
          <span
            style={{
              paddingTop: 3,
              paddingBottom: 3,
              paddingRight: 12,
              paddingLeft: 12,
              borderRadius: 24,
              fontWeight: 800,
              fontFamily: 'Avenir',
              fontSize: 12,
              color: 'var(--bs-success-active)',
              background: 'var(--bs-success-hover)',
              border: '1px solid var(--bs-success)',
            }}
          >
            Recommended
          </span>
        </Col>
      );
    }

    if (isCurrentCardSubscriptionActive) {
      return (
        <Col xs={4} className="p-0 text-end">
          <p className="mb-0 text-primary fw-bold">
            <FontAwesomeIcon icon={faCheck} className="me-8" />
            {currentItemSubscription?.canceled_at ? `Active until ${moment(currentCardPlanPricing.current_item_active_until).format('l')}` : 'Active'}
          </p>
        </Col>
      );
    }
  });

  return (
    <>
      <Card className={`bg-${isCurrentCardSubscriptionActive ? 'info-50' : 'light'} border-primary-50 border p-24`}>
        <Row className="mx-0 mb-16">
          <Col xs={8} className="p-0 text-start">
            <h3 className="mb-0 fw-400">
              <span className="fw-bold">{selectedCardData.title}</span>
              {' - '}
              <PriceOption originalPrice={priceSetup.originalPrice} currentPrice={priceSetup.currentPrice} recurrence={priceSetup.recurrence} />
              {' '}
              (<PriceOption originalPrice={priceSetup.secondaryOriginalPrice} currentPrice={priceSetup.secondaryCurrentPrice} recurrence={priceSetup.secondaryRecurrence} />)
            </h3>
          </Col>

          {Badge}

        </Row>
        <Row>
          <h5 className="fw-300">{selectedCardData.description}</h5>
        </Row>

        {Action}

      </Card>
      <SimpleModal
        isShow={$isCancelPlanModalOpen.value}
        title="Cancel Plan"
        saveText="Keep Plan"
        cancelText="Cancel Plan"
        onClose={() => { $isCancelPlanModalOpen.value = false; }}
        onSave={() => { $isCancelPlanModalOpen.value = false; }}
        onCancel={() => {
          handleCancelSubscription(currentItemSubscription.id)
            .then(() => {
              $isCancelPlanModalOpen.value = false;
            });
        }}
      >
        <p className="text-dark">{`Are you sure to cancel your ${selectedCardData.title.toLowerCase()} plan? This action cannot be undone.`}</p>
      </SimpleModal>
    </>
  );
};

export default PlanCard;
