import Signal from 'signals/Signal';

const $login = Signal({
  email: '',
  password: '',
  submitting: false,
  rememberDevice: false,
  passwordVisible: false,
  mode: 'signin',
});

export default $login;
