import { useRef } from 'react';
import { Row, Col, Container, Card, Image, Button } from 'react-bootstrap';
import { $invoiceUpload, handleFileChange } from 'components/views/InvoiceAdd/InvoiceAdd.helpers';
import fileIcon from 'images/file.svg';
import fileSpreadsheetImg from 'images/file-spreadsheet.svg';
import trashImg from 'images/trash.svg';

const AddInvoiceUpload = ({ handleNext }) => {
  const { uploadedFiles } = $invoiceUpload.value;
  const inputRef = useRef(null);

  const handleAddFile = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-56">Upload your invoice list</h1>
        </Col>
      </Row>
      {/* TODO: Update copy */}
      {/* <Row>
        <Col>
          <h2 className="pb-56 fw-400">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
        </Col>
      </Row> */}
      <Container className="pb-48">
        <Card onClick={() => {}} style={{ maxWidth: 620 }} className="mb-24 px-80 py-64 justify-content-center mx-auto">
          <div className="p-64 text-center mb-32 dashed-border">
            <Row className="d-flex justify-content-center mb-16">
              <Col sm={2} className="p-0 mt-8">
                <Button
                  variant="transparent"
                  className="border-0 p-0 mx-auto"
                  htmlFor="fileUpload"
                  onClick={() => handleAddFile()}
                >
                  <Image src={fileSpreadsheetImg} className="p-0" height={56} />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="lead mb-0">
                  Select file to upload
                </p>
                <p className="base">
                  or drag and drop here
                </p>
              </Col>
            </Row>
          </div>
          {uploadedFiles.map((file) => (
            <Card>
              <Col className="d-flex align-items-center p-8 mt-8" style={{ border: '1px solid #F2F2F2', borderRadius: '15px' }}>
                <div className="bg-gray p-8" style={{ borderRadius: '10px', width: 45, height: 45 }}>
                  <Image src={fileIcon} height={24} />
                </div>
                <div className="d-flex flex-column ms-8">
                  <small>{file.fileName}</small>
                  <small className="mb-0 text-start">{file.fileSize}kb</small>
                </div>
                <div className="ms-auto px-24">
                  <Image src={trashImg} height={16} />
                </div>
              </Col>
            </Card>
          ))}
          {!!uploadedFiles.length && (
            <Button type="primary" className="w-100 py-8 mt-48" onClick={() => handleNext({ current: 'upload', next: 'preview' })}>
              IMPORT
            </Button>
          )}
          <input
            style={{ display: 'none' }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
          />
        </Card>
      </Container>
    </>
  );
};

export default AddInvoiceUpload;
