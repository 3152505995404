import { Container } from 'react-bootstrap';
import BreakpointHelper from 'components/global/BreakpointHelper';

const ContentWrapper = ({ children, className, fluid }) => (
  <div>
    <Container fluid={!!fluid} className={className}>
      <BreakpointHelper />
      {children}
    </Container>
  </div>
);

export default ContentWrapper;
