import { Button, Image } from 'react-bootstrap';
import camera from 'images/camera.svg';

const ProfilePicUpload = ({
  firstName,
  lastName,
  title,
}) => (
  <Button
    variant="gray-900"
    className="d-flex position-relative circle-80 justify-content-center align-items-center p-0 m-0"
  >
    <div className="fw-700">{title || firstName?.charAt(0)}{lastName?.charAt(0)}</div>
    <div
      className="rounded-circle position-absolute bg-gray-50 d-flex justify-content-center align-items-center custom-shadow"
      style={{
        height: '30px',
        width: '30px',
        bottom: '-4px',
        right: '-4px',
      }}
    >
      <Image src={camera} />
    </div>
  </Button>
);

export default ProfilePicUpload;
