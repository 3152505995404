import { useEffect } from 'react';
import { Form, Row, Col, Button, Nav, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import {
  partialEmailPasswordLoginHandler,
  partialGoogleLoginHandler,
  performIntuitCallbackHandoff,
} from 'components/views/Auth/_shared/Auth.helpers';
import DontHaveAnAccountLink from 'components/views/Auth/_components/DontHaveAnAccountLink';
import GoogleLogo from 'images/google.svg';
import $login from 'components/views/Auth/Login/Login.helpers';
import PasswordInput from 'components/global/Inputs/PasswordInput';
import SignUpForm from 'components/views/Auth/_components/SignUpForm';

const LoginForm = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('code') && location.search.includes('state')) {
      performIntuitCallbackHandoff(`${window.location.origin}${'/login'}${location.search}`);
    }
  }, [location.search]);

  if ($login.value.mode === 'signup') return <SignUpForm />;

  return (
    <Form onSubmit={() => partialEmailPasswordLoginHandler()}>
      <Form.Group>
        <Form.Label htmlFor="EmailAddress"><strong>Email address</strong></Form.Label>
        <UniversalInput
          placeholder="Enter email"
          className="mb-16"
          name="email"
          signal={$login}
        />
        <Form.Label htmlFor="Password"><strong>Password</strong></Form.Label>
        <Row
          className="m-0 mb-16"
        >
          <Col xs={12} className="p-0">
            <PasswordInput
              signal={$login}
              name="password"
              visabilityName="passwordVisible"
            />
          </Col>
        </Row>
        <LinkContainer to="/forgot-password">
          <Nav.Link className="mb-16 text-decoration-underline p-0 text-primary">
            <small className="fw-500">
              Having trouble signing in?
            </small>
          </Nav.Link>
        </LinkContainer>
        <Button
          variant="primary"
          type="submit"
          className="w-100 btn-lg mb-16"
          disabled={!$login.value.password || !$login.value.email}
        >
          Login
        </Button>
        <Row>
          <Col xs={4}>
            <hr />
          </Col>
          <Col xs={4} className="p-0 d-flex justify-content-center align-items-center">
            Or sign in with
          </Col>
          <Col xs={4}>
            <hr />
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center mt-16 mb-24 mx-0">
          {/* FUTURE FUNCTIONALITY */}
          {/* <Col xs={6} className="d-flex justify-content-around pt-0 ps-0">
            <Button
              variant="white"
              onClick={() => handleIntuitStartLogin()}
              className="w-100 btn-lg h-100 border-dark"
            >
              <Image
                src={IntuitLogo}
                height={16}
                alt="Intuit Logo"
              />
            </Button>
          </Col> */}
          <Col xs={6} className="d-flex justify-content-around">
            <Button
              variant="white"
              onClick={() => partialGoogleLoginHandler()}
              className="w-100 btn-lg h-100 border-dark"
            ><Image
              src={GoogleLogo}
              height={25}
              alt="Google Logo"
            />
            </Button>
          </Col>
        </Row>
        <DontHaveAnAccountLink />
      </Form.Group>
    </Form>

  );
};

export default LoginForm;
