/* eslint-disable no-nested-ternary */
import { ORGANIZATION_ACCT_PROVIDER_CONN_STATUS } from 'accru-client';
import QuickBooksButton from 'components/global/QuickBooksButton';
import { $accountingConnSettings, $accountingOAuth, $settingsAccounting, sync, toggleSettingsOpen } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
import ConnectionsSettings from 'components/views/SettingsAccounting/_components/ConnectionSettings';
import moment from 'moment';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { $syncing } from 'signals/Global.signals';

const AcctConnectionCard = ({ acct, isLoading, handleConnect }) => {
  const { connections } = $settingsAccounting.value;

  const acctConnection = connections.items.find(conn => conn.acct_provider === acct.provider);
  const isExpired = !!acctConnection && acctConnection.status === ORGANIZATION_ACCT_PROVIDER_CONN_STATUS.EXPIRED;
  const isConnected = !!acctConnection && !isExpired;
  const isConnecting = !!$accountingOAuth.value.provider;
  const isSyncing = !!$syncing.value.isSyncing;
  const isSettingsLoading = $accountingConnSettings.value.isLoading;
  const isEditing = !$accountingOAuth.value.provider && $accountingConnSettings.value?.connection?.acct_provider === acct.provider;

  return (
    <Container className="bg-gray-50 rounded m-0 p-0 mt-32 ms-8 px-32 py-24" key={acct.provider}>
      <Row>
        <Col>
          <Image src={acct.logo} alt={`${acct.name} logo`} height={75} />
        </Col>
      </Row>
      <Row className="mt-24">
        <Col>
          <h2 className="fw-700 text-dark">{isConnected ? `You are connected with ${acct.name}` : `${isExpired ? 'Reconnect' : 'Connect'} and Sync with your ${acct.name} account`}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="lead fw-350 text-dark mb-8">{isConnected ? acct.connectedText : acct.disconnectedText}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="lead fw-900 text-dark">{!!acctConnection?.synchronizations?.edges[0]?.node?.succeeded_at && `Last synced: ${moment(acctConnection?.synchronizations?.edges[0]?.node?.succeeded_at).format('L LT')}`}</p>
        </Col>
      </Row>

      {!isEditing && (
        <Row className="mt-40 row-cols-auto">
          {!isConnected ? (
            <Col>
              <QuickBooksButton onClick={() => handleConnect(acct.provider)} disabled={isConnecting || isLoading} />
              <Row className="mx-0">
                <small>{isConnecting ? 'CONNECTING' : (isExpired && 'RECONNECT NOW')}</small>
              </Row>
              {/* JUST IN CASE WE WANT OUR OLD FUNCITONALITY */}
              {/* <Button
                  variant="primary"
                  disabled={isConnecting || isLoading}
                  onClick={() => handleConnect(acct.provider)}
                >
                  {isConnecting ? 'CONNECTING' : (isExpired ? 'RECONNECT NOW' : 'CONNECT NOW')}
                </Button> */}
            </Col>
          ) : (
            <>
              <Col>
                <Button
                  variant="primary"
                  disabled={isLoading || isSyncing || isSettingsLoading}
                  onClick={() => sync(acct.provider)}
                  className="d-flex align-items-center"
                >
                  {isSyncing ? 'SYNCING' : 'SYNC NOW'}
                  {isSyncing && (<span className="spinner-grow spinner-grow-sm ms-8" role="status" aria-hidden="true" />)}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  disabled={isLoading || isSettingsLoading}
                  onClick={() => $settingsAccounting.update({ disconnectedProviderCode: acct.provider })}
                >
                  DISCONNECT
                </Button>
              </Col>
              <Col>
                <Button
                  variant="outline-primary"
                  disabled={isLoading || isSettingsLoading}
                  onClick={() => toggleSettingsOpen(acct.provider)}
                >
                  CHANGE SETTINGS
                </Button>
              </Col>
            </>
          )}
        </Row>
      )}

      {!!isEditing && (
        <ConnectionsSettings actionLabel="EXIT" actionCallback={(callback) => callback(false, true)} exitAfterSave />
      )}
    </Container>
  );
};

export default AcctConnectionCard;
