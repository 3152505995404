import ContentWrapper from 'components/global/ContentWrapper';
import PayType from './_components/PayType';

const Payment = () => (
  <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0 overflow-hidden">
    <PayType
      className="px-0 position-relative min-vh-100"
    />
  </ContentWrapper>
);

export default Payment;
