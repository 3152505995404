import { Card, Col, Row } from 'react-bootstrap';
import { $settingsSubscriptionPurchaseDetail, handlePurchaseTimeout } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import { useEffect, useState } from 'react';

const Countdown = () => {
  const { currentTransaction } = $settingsSubscriptionPurchaseDetail.value;

  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!currentTransaction) return null;
      const { due_date } = currentTransaction;
      if (!due_date) return null;
      const difference = new Date(due_date) - new Date();
      if (difference < 0) {
        handlePurchaseTimeout();
        return null;
      }
      if (difference < 1000 * 60 * 5) {
        return {
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        };
      }

      return null;
    };

    const interval = currentTransaction ? setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000) : null;

    return () => { if (interval) { clearInterval(interval); } };
  }, [currentTransaction]);

  if (!timeLeft) return <></>;

  return (
    <Card className="bg-danger-50 border-danger border p-24 mx-0 mt-24">
      <Row className="mx-0 mb-16">
        <Col xs={8} className="p-0 text-start">
          <h2 className="text-dark mb-0 fw-400">
            <span className="fw-bold">Hurry up!</span>
          </h2>
        </Col>
        <Col xs={4} className="d-flex align-items-center justify-content-end p-0 text-end">
          <h4>
            {timeLeft.minutes !== undefined && timeLeft.seconds !== undefined
              ? `${timeLeft.minutes}:${timeLeft.seconds < 10 ? '0' : ''}${timeLeft.seconds}`
              : 'Expired'}
          </h4>
        </Col>
      </Row>
      <Row>
        <h5 className="fw-300">Your purchase will expire soon. Please complete your purchase to avoid losing your selected plan.</h5>
      </Row>
    </Card>
  );
};

export default Countdown;
