import Signal from 'signals/Signal';
import { computed, effect } from '@preact/signals-react';

export const $customersUi = Signal({
  isMobileModalOpen: false,
  isSearchListOpen: false,
  openTab: 'details',
});
export const $customersFilter = Signal({
  search: '',
  selectedSorting: [{ field: 'balance', order: 'DESC' }],
  page: 1,
  perPage: 20,
  totalCount: 0,
  selectedId: null,
});

export const $customers = Signal({});
export const $customerDetail = Signal({
  balance: 0,
  bills: [],
});

export const $customerConnections = Signal({
  connected: false,
  syncContactInfo: false,
});

export const $customerStatement = Signal({
  isSidebarOpen: false,
  isSuccessModalOpen: false,
  selectedStatementContacts: [],
  isContactFormActive: false,
  contactFormName: '',
  contactFormEmail: '',
  contactFormPhoneNo: '',
});

export const $customersSearchCache = Signal({});
export const $searchKey = computed(() => $customersFilter.value.search.toLowerCase());
export const $isCacheAvailable = computed(() => !!$customersSearchCache.value[$searchKey.value]);
export const $customersAutocomplete = computed(() => $customersSearchCache.value?.[$searchKey.value]?.filter(({ name }) => {
  const searchTerm = $customersFilter.value.search.trim().toLowerCase();
  return name.toLowerCase().includes(searchTerm);
}) || []);

effect(() => {
  if (JSON.stringify($customerDetail.value) !== JSON.stringify($customerDetail.previousValue)) {
    const bills = $customerDetail.value?.invoice_summary?.data?.edges.map(({ node }) => node) || [];
    $customerDetail.update({ bills });
  }
}, [$customerDetail]);
