import { AuthLayout } from 'components/global/Layout';
import LoginForm from 'components/views/Auth/_components/LoginForm';

const Login = () => (
  <AuthLayout>
    <LoginForm />
  </AuthLayout>
);

export default Login;
