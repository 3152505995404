import $user from 'signals/User.signals';
import { historyPush } from 'utils/history';

export const firstAvailableOrganizationUser = () => {
  const availableOrganizationUser = $user.value.user?.organizations?.find((org) => org?.role === 'OWNER' || !!org.is_current_organization_user_seat_available);

  if (!availableOrganizationUser && $user.value?.user?.id) {
    historyPush('/profile');
  }

  return availableOrganizationUser;
};

export default firstAvailableOrganizationUser;
