import { Button, Col, Form, Row } from 'react-bootstrap';
import SelectInput from 'components/global/Inputs/SelectInput';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import $user from 'signals/User.signals';
import { numberOfPeopleArray, companyIndstryArray } from 'utils/selectArrays';
import { createOrganizationSubmit, $onboarding } from 'components/views/Onboarding/Onboarding.helpers';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';

const CompanyInfoForm = () => {
  const { companyName, companyIndustry, numberOfPeople, phoneNumber } = $onboarding.value;

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-40">Please tell us a little about yourself</h2>
      <Form.Label htmlFor="CompanyName"><strong>Company Name</strong></Form.Label>
      <UniversalInput
        value={companyName}
        className="mb-40"
        name="companyName"
        signal={$onboarding}
      />
      <Form.Label htmlFor="companyIndustry">
        <strong>Company Industry</strong>
      </Form.Label>
      <SelectInput
        value={companyIndustry}
        name="companyIndustry"
        options={companyIndstryArray}
        className="mb-40"
        signal={$onboarding}
      />
      <Row>
        <Col xs={12}>
          <Form.Label htmlFor="Phone Number"><strong>Phone Number</strong></Form.Label>
          <PhoneNumberInput
            value={phoneNumber}
            name="phoneNumber"
            signal={$onboarding}
            className="mb-40"
          />
          <div className="base text-dark">Used to verify your account and keep things secure.</div>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <Form.Label
            className="p-0 d-flex align-items-center h-100"
            htmlFor="Number of people in your company"
          ><strong>Number of people in your company</strong>
          </Form.Label>
        </Col>
        <Col xs={4}>
          <SelectInput
            value={numberOfPeople}
            name="numberOfPeople"
            options={numberOfPeopleArray}
            signal={$onboarding}
          />
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        onClick={() => createOrganizationSubmit($user.value.user.email)}
        className="w-100 btn-lg my-48"
        disabled={!companyName.length || !companyIndustry.length || numberOfPeople === 'Select' || !phoneNumber.length}
      >
        NEXT
      </Button>
    </Form.Group>
  );
};

export default CompanyInfoForm;
