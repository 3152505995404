import billCsvExampleImg from 'images/billCsvExample.svg';
import { $addBillManual, handleNext } from 'components/views/BillAdd/BillAdd.helpers';
import { Col, Container, Image, Row } from 'react-bootstrap';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

export const AddBillManualSelectModal = () => {
  const { infoModalOpen } = $addBillManual.value;
  return (
    <SimpleModal
      isShow={infoModalOpen}
      title="Required Details for upload"
      onClose={() => $addBillManual.update({ infoModalOpen: false })}
      onCancel={() => console.info('todo')}
      onSave={() => handleNext({ current: 'manualSelect', next: 'upload' })}
      saveText="UPLOAD .CSV FILE"
      cancelText="DOWNLOAD TEMPLATE"
    >
      <Container fluid>
        <Row className="mt-8 pb-48">
          <Col>
            <lead className="fw-400 text-dark">Don’t worry if your spreadsheet has other details, but please make sure it contains the following columns</lead>
          </Col>
        </Row>
        <Row>
          <Image src={billCsvExampleImg} />
        </Row>
      </Container>
    </SimpleModal>
  );
};

export default AddBillManualSelectModal;
