import { accruClient } from 'api';
import { historyPush } from 'utils/history';
import { handleNotification } from 'components/global/Alert/Alert';
import { $vendors } from 'components/views/Vendors/_helpers/Vendors.signals';
import Signal from 'signals/Signal';
import $user from 'signals/User.signals';
import logEvent from 'utils/logEvent';
import { effect } from '@preact/signals-react';
import { inviteVendor } from 'components/helpers/Connection.helpers';

export const $vendorsForm = Signal({
  name: '',
  email: '',
  phone_number: '',
});

export const $vendorAdd = Signal({
  currentScreen: 'method',
  backButtonHistory: [],
});

effect(() => {
  if ($vendorAdd.value.currentScreen) {
    logEvent(`add_vendor.${$vendorAdd.value.currentScreen}`);
  }
});

export const $vendorAddUpload = Signal({
  uploadedFiles: [],
});

export const handleNext = ({ current, next }) => {
  const { backButtonHistory } = $vendorAdd.value;
  const navigationHistory = [...backButtonHistory];
  navigationHistory.push(current);
  $vendorAdd.update({
    currentScreen: next,
    backButtonHistory: navigationHistory,
  });
};

export const handleBackButton = () => {
  const { backButtonHistory } = $vendorAdd.value;
  const navigationHistory = [...backButtonHistory];
  if (navigationHistory.length) {
    const current = navigationHistory.pop();
    $vendorAdd.update({
      currentScreen: current,
      backButtonHistory: navigationHistory,
    });
  } else {
    historyPush('/vendors');
  }
};

export const sendGenericInviteToAccru = async ({ organizationId, email }) => {
  try {
    await accruClient.organizations.sendGenericInviteToAccru({
      organizationId,
      email,
    });
  } catch (error) {
    handleNotification(error);
    throw error;
  }
};

export const handleAddVendor = async () => {
  try {
    $vendors.loadingStart();
    const formData = $vendorsForm?.value;

    const organizationId = $user?.value?.currentOrganization?.id;
    const { name, email, inviteToAccru, sendConnectionInvite } = formData;

    const response = await accruClient.vendors.create({
      organizationId,
      data: {
        name,
        email,
        is_active: true,
      },
    });

    if (response) {
      logEvent({ name: 'add_vendor_success' });
      handleNotification('Vendor created!', { variant: 'success' });

      const hasSideEffects = inviteToAccru || sendConnectionInvite;
      if (hasSideEffects) {
        const organizationVendorId = response.id;
        if (inviteToAccru) {
          try {
            await sendGenericInviteToAccru({ organizationId, email });
          } catch { handleNotification('Please try sending the invite to Accru again later.'); }
        }
        if (sendConnectionInvite) {
          try {
            await inviteVendor({ organizationVendorId });
          } catch { handleNotification('Please try sending the connection invite again later.'); }
        }
      }

      const prevVendors = $vendors.value?.items || [];
      $vendors.update({ items: [response, ...prevVendors] });
      historyPush('/vendors');
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $vendors.loadingEnd();
  }
};
