import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import $user from 'signals/User.signals';
import {
  cancelContactUpdate,
  handleVerifyContactFormChange,
  updateEmailFinish,
  updateEmailStart,
} from 'components/views/SettingsProfile/_helpers/SettingsProfile.helpers';
import { $settingsProfile, $settingsProfileUI } from 'components/views/SettingsProfile/_helpers/SettingsProfile.signals';

const ContactInfo = () => {
  const {
    email,
    contactVerificationCode,
  } = $settingsProfile.value;
  const { contactPage } = $settingsProfileUI.value;
  const { user } = $user.value;

  useEffect(() => {
    if (user) {
      $settingsProfile.update({
        email: user.email,
      });
    }
  }, []);

  return (
    <Row className="mx-0">
      <h1 className="fw-400 mb-0">Contact Info</h1>
      {contactPage === 'contact' && (
        <>
          <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
            <Form.Group>
              <h2 className="fw-700 mb-16">Contact Info</h2>
              <Form.Label htmlFor="email"><strong>Email address</strong></Form.Label>
              <Row className="mx-0">
                <Col className="p-0">
                  <UniversalInput
                    placeholder="Email address"
                    className="mb-16"
                    name="email"
                    signal={$settingsProfile}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <Row className="mx-0 px-0">
            <Col className="ps-0">
              <Button
                variant="outline-primary"
                onClick={() => cancelContactUpdate()}
                className="w-100 mb-16 mt-24"
                disabled={!email || email === user.email || $settingsProfile.value?.isLoading}
              >
                CANCEL
              </Button>
            </Col>
            <Col className="pe-0">
              <Button
                variant="primary"
                onClick={updateEmailStart}
                className="w-100 mb-16 mt-24"
                disabled={!email || email === user.email || $settingsProfile.value?.isLoading}
              >
                UPDATE
              </Button>
            </Col>
          </Row>
        </>
      )}
      {contactPage === 'validation' && (
        <>
          <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
            <Form.Group>
              <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
              <p className="base fw-400 text-dark text-center">
                For your security, we just need to make sure this is you. We sent you a 6 digit code to {email}
              </p>
              <Row>
                <div className="px-0 me-8 d-flex justify-content-around">
                  {contactVerificationCode.map((value, index) => (
                    <Form.Control
                      key={index}
                      className={`mb-40 h3 mx-8 text-center p-0 py-16 validation-form ${value === '' ? 'active' : ''}`}
                      type="text"
                      max="1"
                      value={value}
                      name="verificationCode"
                      onChange={(e) => handleVerifyContactFormChange(e, index, contactVerificationCode)}
                    />
                  ))}
                </div>
              </Row>
            </Form.Group>
          </Row>
          <Row className="mx-0 px-0">
            <Col className="ps-0">
              <Button
                variant="secondary"
                onClick={() => cancelContactUpdate()}
                className="w-100 mb-16 mt-24"
              >
                CANCEL
              </Button>
            </Col>
            <Col className="pe-0">
              <Button
                variant="primary"
                onClick={() => updateEmailFinish()}
                className="w-100 mb-16 mt-24"
                disabled={contactVerificationCode.includes('')}
              >
                CONFIRM
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Row>
  );
};

export default ContactInfo;
