import { computed } from '@preact/signals-react';

import { COUNTRY_ISO_3, CURRENCY, ORGANIZATION_SUBSCRIPTION_BASE_ITEM, ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY, ORGANIZATION_SUBSCRIPTION_ITEM_CLASSIFICATION, ORGANIZATION_SUBSCRIPTION_PLAN_ITEM, ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL, ORGANIZATION_SUBSCRIPTION_STATUS, TRANSACTION_STATUS } from 'accru-client';
import Signal from 'signals/Signal';
import $user from 'signals/User.signals';
import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import { validateState, validateZip } from 'utils/validation';
import { validateEmail, validateLength } from 'utils/validateInput';
import { fetchAndSetUserData } from 'components/views/Auth/_shared/Auth.helpers';

export const subscriptionItemCategoryMap = {
  [ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY.MODULE]: 'Module',
  [ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY.PLAN]: 'Plan',
};

export const subscriptionRecurrenceMap = {
  [ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.MONTHLY]: 'Monthly',
  [ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.YEARLY]: 'Annually',
};

export const subscriptionBaseItemInfoMap = {
  [ORGANIZATION_SUBSCRIPTION_BASE_ITEM.FREE_PLAN]: {
    title: 'Free',
    description: 'Get started with our no-cost plan, ideal for new and small businesses looking to streamline their invoicing processes without additional expenses.',
    isRecommended: false,
  },
  [ORGANIZATION_SUBSCRIPTION_BASE_ITEM.BASIC_PLAN]: {
    title: 'Basic',
    description: 'For growing businesses, our Pro Plan offers advanced features, including multi-user access, enhanced reporting, and priority support, all for a low monthly fee.',
    isRecommended: true,
  },
  [ORGANIZATION_SUBSCRIPTION_BASE_ITEM.PRO_PLAN]: {
    title: 'Pro',
    description: 'Tailored solutions for larger businesses needing custom features and personal account management.',
    isRecommended: false,
  },
  [ORGANIZATION_SUBSCRIPTION_BASE_ITEM.PROJECTS_MODULE]: {
    title: 'Projects Module',
    description: 'Add the Projects Module to your subscription to manage your projects and track time spent on each project.',
    isRecommended: true,
  },
};

export const $settingsSubscriptionUI = Signal({
  view: 'PLAN_LIST',
  isCompareModalOpen: false,

  /** @type {ORGANIZATION_SUBSCRIPTION_PLAN_ITEM} */
  selectedPlan: null,

  /** @type {ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL} */
  recurrence: ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL.MONTHLY,

  /** @type {AccruResponse<AccruClient['subscriptions']['get']>} */
  subscriptions: null,

  /** @type {AccruResponse<AccruClient['subscriptions']['getDefaultPricing']>} */
  pricing: null,

  /** @type {AccruResponse<AccruClient['subscriptions']['calculatePricing']>} */
  selectedPricing: null,
});

export const $settingsSubscriptionPurchaseDetail = Signal({
  /** @type {AccruResponse<AccruClient['subscriptions']['getPreTransactionData']>} */
  preTransactionData: null,

  /** @type {AccruResponse<AccruClient['subscriptions']['startPurchase']>} */
  currentTransaction: null,

  isPurchaseSessionLoading: false,
  isProcessingPayment: false,
});

export const $settingsSubscriptionBillingAddressForm = Signal({
  isOrgAddressSame: true,
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
});

export const $settingsSubscriptionIsLoading = computed(() => $settingsSubscriptionUI.value.isLoading || $settingsSubscriptionPurchaseDetail.value.isLoading || $settingsSubscriptionPurchaseDetail.value.isPurchaseSessionLoading || $settingsSubscriptionPurchaseDetail.value.isProcessingPayment);
export const $settingsSubscriptionActivePaidPlanSubscriptions = computed(() => $settingsSubscriptionUI.value.subscriptions?.items?.filter(subscription => subscription.items.some(subscriptionItem => subscriptionItem.category === ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY.PLAN && subscriptionItem.item_type !== ORGANIZATION_SUBSCRIPTION_PLAN_ITEM.FREE_PLAN)) || []);

export const $settingsSubscriptionSelectedRecurringPlanOptions = computed(
  () => (
    $settingsSubscriptionUI.value.recurrence && $settingsSubscriptionUI.value.pricing?.options?.length ?
      $settingsSubscriptionUI.value.pricing.options.find(
        (recurringOption) => recurringOption.renew_interval === $settingsSubscriptionUI.value.recurrence,
      )?.options?.filter(
        ({ base_item: { item_type, category } }) => category === ORGANIZATION_SUBSCRIPTION_ITEM_CATEGORY.PLAN &&
          item_type !== ORGANIZATION_SUBSCRIPTION_PLAN_ITEM.FREE_PLAN,
      ) || []
      : []),
);

export const $settingsSubscriptionIsBillingAddressRequired = computed(() => (!$user.value.currentOrganization?.data?.name || !$user.value.currentOrganization?.data?.email || !$user.value.currentOrganization?.data?.address_country_code_iso_3));

export const featuresArray = [
  {
    id: 1,
    label: 'Statements',
    free: true,
    basic: true,
    pro: true,
  },
  {
    id: 2,
    label: 'Send Reminders Manually',
    free: true,
    basic: true,
    pro: true,
  },
  {
    id: 3,
    label: 'Sync with QuickBooks',
    free: true,
    basic: true,
    pro: true,
  },
  {
    id: 4,
    label: 'Pay/Receive with Credit Card',
    free: true,
    basic: true,
    pro: true,
  },
  {
    id: 5,
    label: 'Up to 3 Users',
    free: true,
    basic: true,
    pro: true,
  },
  {
    id: 6,
    label: 'Payment Plans & APM',
    free: false,
    basic: true,
    pro: true,
  },
  {
    id: 7,
    label: 'Connections',
    free: false,
    basic: true,
    pro: true,
  },
  {
    id: 8,
    label: 'Detailed Sync Logs',
    free: false,
    basic: false,
    pro: true,
  },
  {
    id: 9,
    label: 'Automated/Custom Reminders',
    free: false,
    basic: false,
    pro: true,
  },
  {
    id: 10,
    label: 'Access to API (Coming Soon)',
    free: false,
    basic: false,
    pro: true,
  },
];

function validateAndParseTransactionBillingAddress() {
  const { firstName, lastName, city, state, address, zipCode } = $settingsSubscriptionBillingAddressForm.value;
  if (!firstName?.length || !lastName?.length) throw new Error('First Name and Last Name are required');

  if (!city?.length || !state?.length || !address?.length || !zipCode?.length) throw new Error('City, State, Address and Zip Code are required');

  const billingAddress = {
    billing_address_country_code_iso_3: COUNTRY_ISO_3.USA,
    billing_email: $user.value.currentOrganization.email,

    billing_first_name: firstName,
    billing_last_name: lastName,

    billing_address_city: city,
    billing_address_state: state,
    billing_address_line_1: address,
    billing_address_line_2: null,
    billing_address_number: null,
    billing_address_zip_code: zipCode,
    billing_phone_number: $user.value.currentOrganization.data.phone_number || null,
  };

  validateEmail(billingAddress.billing_email);

  validateLength(billingAddress.billing_first_name, 'First Name', 1, 30);
  validateLength(billingAddress.billing_last_name, 'Last Name', 1, 40);

  if (billingAddress.billing_address_country_code_iso_3 !== COUNTRY_ISO_3.USA) throw new Error('Invalid country code.');

  validateState(billingAddress.billing_address_state);

  validateLength(billingAddress.billing_address_city, 'City', 1, 30);
  validateLength(billingAddress.billing_address_line_1, 'Address', 1, 50);
  validateZip(billingAddress.billing_address_zip_code);

  if (billingAddress.billing_phone_number) validateLength(billingAddress.billing_phone_number, 'Phone Number', 10, 18);

  return billingAddress;
}

async function fetchAndSetSubscriptionPrePurchaseData({ organizationCouponId }) {
  const selectedPricing = await accruClient.subscriptions.calculatePricing({
    organizationId: $user.value.currentOrganization.id,
    data: {
      currency: CURRENCY.USD,
      renew_interval: $settingsSubscriptionUI.value.recurrence,
      selected_addon_items: [],
      organization_coupon_id: organizationCouponId,
      selected_base_item: $settingsSubscriptionUI.value.selectedPlan,
    },
  });

  const preTransactionData = await accruClient.subscriptions.getPreTransactionData({
    organizationId: $user.value.currentOrganization.id,
  });

  $settingsSubscriptionPurchaseDetail.update({
    preTransactionData,
  });

  $settingsSubscriptionUI.update({
    selectedPricing,
  });
}

export async function resetSubscriptionPurchase() {
  $settingsSubscriptionUI.reset();
  $settingsSubscriptionBillingAddressForm.reset();

  $settingsSubscriptionPurchaseDetail.reset();

  // eslint-disable-next-line no-use-before-define
  fetchAndSetOrganizationSubscriptions();
  fetchAndSetUserData();
}

async function fetchAndSetSubscriptionOpenPurchaseData({ subscriptionId }) {
  $settingsSubscriptionPurchaseDetail.update({ isPurchaseSessionLoading: true });

  try {
    if (!subscriptionId) throw new Error('Invalid subscription ID.');

    const subscription = await accruClient.subscriptions.getOne({
      organizationId: $user.value.currentOrganization.id,
      subscriptionId,
    });

    const baseItem = subscription.items.find(item => item.classification === ORGANIZATION_SUBSCRIPTION_ITEM_CLASSIFICATION.BASE)?.item_type;
    if (!baseItem) throw new Error('Unable to find the selected item.');

    const selectedPricing = await accruClient.subscriptions.calculatePricing({
      organizationId: $user.value.currentOrganization.id,
      data: {
        currency: CURRENCY.USD,
        renew_interval: subscription.renew_interval,
        selected_addon_items: subscription.items.filter(item => item.classification === ORGANIZATION_SUBSCRIPTION_ITEM_CLASSIFICATION.ADDON).map(addon => ({
          item_type: addon.item_type,
          quantity: addon.quantity,
        })),
        organization_coupon_id: subscription.organization_coupon_id,
        selected_base_item: baseItem,
      },
    });

    const preTransactionData = await accruClient.subscriptions.getPreTransactionData({
      organizationId: $user.value.currentOrganization.id,
    });

    $settingsSubscriptionBillingAddressForm.update({
      firstName: subscription.organization_payment_method.billing_first_name,
      lastName: subscription.organization_payment_method.billing_last_name,
      address: subscription.organization_payment_method.billing_address_line_1,
      city: subscription.organization_payment_method.billing_address_city,
      state: subscription.organization_payment_method.billing_address_state,
      zipCode: subscription.organization_payment_method.billing_address_zip_code,
    });

    const currentTransaction = subscription.status === ORGANIZATION_SUBSCRIPTION_STATUS.INITIALIZING && subscription.transactions.find(transaction => transaction.status === TRANSACTION_STATUS.STARTED);

    $settingsSubscriptionPurchaseDetail.update({
      preTransactionData,
      currentTransaction: currentTransaction || null,
    });

    $settingsSubscriptionUI.update({
      selectedPricing,
      selectedPlan: baseItem,
      recurrence: subscription.renew_interval,
    });
  } finally {
    $settingsSubscriptionPurchaseDetail.update({ isPurchaseSessionLoading: false });
  }
}

export async function fetchAndSetOrganizationSubscriptions({
  organizationCouponId,
} = {}) {
  try {
    $settingsSubscriptionUI.loadingStart();

    const pricing = await accruClient.subscriptions.getDefaultPricing({
      organizationId: $user.value.currentOrganization.id,
      organizationCouponId,
    });

    const subscriptions = await accruClient.subscriptions.get({
      organizationId: $user.value.currentOrganization.id,
      status: ORGANIZATION_SUBSCRIPTION_STATUS.ACTIVE,
    });

    $settingsSubscriptionUI.update({
      subscriptions,
      pricing,
    });

    if ($settingsSubscriptionUI.value.selectedPlan && $settingsSubscriptionUI.value.recurrence) {
      const { selectedPlan } = $settingsSubscriptionUI.value;
      const planList = $settingsSubscriptionSelectedRecurringPlanOptions.value;
      if (!pricing || !planList.length) return;

      const selectedPlanData = planList.find(({ base_item }) => base_item.item_type === selectedPlan);

      if (!selectedPlanData) {
        handleNotification('Invalid plan selected.');
        return;
      }

      $settingsSubscriptionPurchaseDetail.reset();
      $settingsSubscriptionPurchaseDetail.loadingStart();

      if (selectedPlanData.base_item.current_item_pending_purchase_subscription?.id) {
        await fetchAndSetSubscriptionOpenPurchaseData({ subscriptionId: selectedPlanData.base_item.current_item_pending_purchase_subscription.id });
      } else {
        await fetchAndSetSubscriptionPrePurchaseData({ organizationCouponId: pricing.organization_coupon_id });
      }
    } else {
      $settingsSubscriptionPurchaseDetail.update({
        preTransactionData: null,
      });

      $settingsSubscriptionUI.update({
        selectedPricing: null,
      });
    }

    $settingsSubscriptionUI.loadingEnd();
  } catch (error) {
    resetSubscriptionPurchase();
    throw error;
  }
}

async function cancelPurchase() {
  if ($settingsSubscriptionPurchaseDetail.value.currentTransaction) {
    try {
      $settingsSubscriptionUI.loadingStart();

      const purchaseSubscriptionId = $settingsSubscriptionPurchaseDetail.value.currentTransaction.organization_subscription_id;

      const subscriptionPurchase = await accruClient.subscriptions.getOne({
        organizationId: $user.value.currentOrganization.id,
        subscriptionId: purchaseSubscriptionId,
      });

      if (subscriptionPurchase.status === ORGANIZATION_SUBSCRIPTION_STATUS.INITIALIZING) {
        await accruClient.subscriptions.cancel({
          organizationId: $user.value.currentOrganization.id,
          organizationSubscriptionId: purchaseSubscriptionId,
        });
      }
    } catch (error) {
      handleNotification(error);
    }
  }
}

async function startPurchase() {
  const { selectedPlan, selectedPricing, recurrence } = $settingsSubscriptionUI.value;

  if (!selectedPlan) throw new Error('No plan selected.');
  if (!ORGANIZATION_SUBSCRIPTION_RENEW_INTERVAL[recurrence]) throw new Error('Invalid recurrence selected.');
  if (!selectedPricing?.price_total_amount) throw new Error('No price calculated.');

  let billingAddress = null;

  if (!$settingsSubscriptionBillingAddressForm.valueisOrgAddressSame || !!$settingsSubscriptionIsBillingAddressRequired.value) {
    billingAddress = validateAndParseTransactionBillingAddress();
  }

  const subscriptionTransaction = await accruClient.subscriptions.startPurchase({
    organizationId: $user.value.currentOrganization.id,
    data: {
      currency: CURRENCY.USD,
      renew_interval: recurrence,
      selected_base_item: selectedPlan,
      selected_addon_items: [],
      calculated_total_amount: selectedPricing.price_total_amount,
      organization_coupon_id: selectedPricing.organization_coupon_id,
      billing_address: billingAddress || undefined,
    },
  });

  $settingsSubscriptionPurchaseDetail.update({ currentTransaction: subscriptionTransaction });

  return subscriptionTransaction;
}

async function completePurchase() {
  const { currentTransaction } = $settingsSubscriptionPurchaseDetail.value;

  if (!currentTransaction) throw new Error('No transaction found.');

  try {
    await accruClient.subscriptions.completePurchase({
      organizationId: $user.value.currentOrganization.id,
      organizationSubscriptionId: currentTransaction.organization_subscription_id,
      organizationSubscriptionTransactionId: currentTransaction.id,
    });

    handleNotification('Subscription purchased successfully.', { variant: 'success' });

    resetSubscriptionPurchase();
  } catch (error) {
    await cancelPurchase();
    throw error;
  }
}

export async function handleSelectPlan(plan) {
  try {
    $settingsSubscriptionUI.loadingStart();

    const selectedPlan = plan || $settingsSubscriptionUI.value.selectedPlan;
    if (!selectedPlan) throw new Error('No plan selected.');

    $settingsSubscriptionUI.update({
      selectedPlan,
    });

    await fetchAndSetOrganizationSubscriptions({
      organizationCouponId: $settingsSubscriptionUI.value.pricing?.organization_coupon_id,
    });

    $settingsSubscriptionUI.update({
      view: 'PLAN_PURCHASE',
    });

    $settingsSubscriptionUI.loadingEnd();
  } catch (error) {
    handleNotification(error);
    resetSubscriptionPurchase();
  }
}

export async function handleRemoveCoupon() {
  if ($settingsSubscriptionIsLoading.value) return;
  fetchAndSetOrganizationSubscriptions({ organizationCouponId: null });
}

export async function handleCancelSubscription(organizationSubscriptionId) {
  try {
    if ($settingsSubscriptionIsLoading.value) return;
    $settingsSubscriptionUI.loadingStart();

    await accruClient.subscriptions.cancel({
      organizationId: $user.value.currentOrganization.id,
      organizationSubscriptionId,
    });

    handleNotification('Subscription canceled successfully.', { variant: 'success' });
  } catch (error) {
    handleNotification(error);
  } finally {
    resetSubscriptionPurchase();
  }
}

export async function handlePurchase({ createPaymentCallback }) {
  try {
    if (typeof createPaymentCallback !== 'function') throw new Error('Unable to process payment.');

    const { preTransactionData } = $settingsSubscriptionPurchaseDetail.value;

    let openTransaction = $settingsSubscriptionPurchaseDetail.value.currentTransaction;

    if ($settingsSubscriptionIsLoading.value) return;
    if (!preTransactionData) throw new Error('Invalid transaction setup data.');

    $settingsSubscriptionPurchaseDetail.update({ isProcessingPayment: true });

    if (!openTransaction) { openTransaction = await startPurchase(); }
    if (!openTransaction?.provider_transaction_code) throw new Error('Invalid transaction data.');

    let billingAddress = null;
    if (!$settingsSubscriptionBillingAddressForm.value.isOrgAddressSame || !!$settingsSubscriptionIsBillingAddressRequired.value) {
      billingAddress = validateAndParseTransactionBillingAddress();
    }

    await createPaymentCallback({
      sessionToken: openTransaction.provider_transaction_code,
      billingAddress: billingAddress || undefined,
    });

    try {
      await completePurchase();
    } catch (error) {
      await handleSelectPlan();
      throw error;
    }
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $settingsSubscriptionPurchaseDetail.update({ isProcessingPayment: false });
  }
}

export async function handlePurchaseTimeout() {
  const { currentTransaction } = $settingsSubscriptionPurchaseDetail.value;
  if (!currentTransaction) return;

  try {
    if ($settingsSubscriptionIsLoading.value) return;
    $settingsSubscriptionUI.loadingStart();

    await cancelPurchase();
  } catch (error) {
    handleNotification(error);
  } finally {
    handleNotification('Purchase timeout. Please try again.', { variant: 'danger' });
    handleSelectPlan();
  }
}

export async function handleCancelPurchase() {
  if ($settingsSubscriptionIsLoading.value) return;
  await cancelPurchase();
  resetSubscriptionPurchase();
}

export function handlePaymentGatewayLoadingFailure() {
  handleNotification('Fail to load payment gateway', { variant: 'danger' });
  resetSubscriptionPurchase();
}

export function handlePaymentGatewayLoadingSuccess() {
  $settingsSubscriptionPurchaseDetail.loadingEnd();
}
