const AlertExclamation = ({ color }) => (
  <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3159 24.0625C15.3159 22.8544 16.2953 21.875 17.5034 21.875C18.7115 21.875 19.6909 22.8544 19.6909 24.0625C19.6909 25.2706 18.7115 26.25 17.5034 26.25C16.2953 26.25 15.3159 25.2706 15.3159 24.0625Z" fill={color} />
    <path d="M15.5302 10.9266C15.4137 9.76125 16.3288 8.75 17.5 8.75C18.6713 8.75 19.5864 9.76125 19.4699 10.9266L18.7026 18.5992C18.6408 19.217 18.121 19.6875 17.5 19.6875C16.8791 19.6875 16.3592 19.217 16.2975 18.5992L15.5302 10.9266Z" fill={color} />
    <path d="M24.5022 16.5C24.5022 15.6716 25.1738 15 26.0022 15C26.8306 15 27.5022 15.6716 27.5022 16.5C27.5022 17.3284 26.8306 18 26.0022 18C25.1738 18 24.5022 17.3284 24.5022 16.5Z" fill={color} />
    <path d="M24.6491 7.49256C24.5692 6.69343 25.1968 6 25.9999 6C26.803 6 27.4305 6.69343 27.3506 7.49256L26.8245 12.7537C26.7821 13.1774 26.4257 13.5 25.9999 13.5C25.5741 13.5 25.2176 13.1774 25.1753 12.7537L24.6491 7.49256Z" fill={color} />
  </svg>
);

export default AlertExclamation;
