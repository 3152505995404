import { Button, Col, Container, Image, Nav, Row } from 'react-bootstrap';
import { resetAndExit } from 'components/views/Statement/Old/Statement.helpers';
import processingIcon from 'images/processingIcon.svg';

const billsArray = [
  {
    name: 'Bill',
    id: 1,
    amount: 5000,
  },
  {
    name: 'Bill',
    id: 2,
    amount: 20000,
  }];

const PaymentConfirmed = ({ className }) => (
  <Container className={className}>
    <div className="mb-32">
      <Row className="d-flex justify-content-center mt-40">
        <Image className="w-auto" src={processingIcon} alt="Accru smile icon" />
      </Row>
      <Row className="mb-16">
        <h2 className="text-dark fw-700 mt-32">You made your first payment!</h2>
      </Row>
      <Row className="mb-8">
        <h5 className="text-primary fw-700">Confirmation ID#####</h5>
      </Row>
      <Row>
        <h5 className="text-dark fw-400">We have just applied your payment to <strong>FYC Labs:</strong></h5>
      </Row>
      <div className="d-flex justify-content-center mb-8">
        {billsArray.map((obj, index) => (
          <Row key={index}>
            <h5 key={obj.id} className="text-dark fw-400 mx-8">
              {obj.name}
              {' '}
              #{obj.id}
            </h5>
          </Row>
        ))}
      </div>
      <Row className="mb-16">
        <Col sm={{ offset: 2, span: 8 }}>
          <Button variant="outline-primary" className=" pill">VIEW TRANSACTION DETAILS</Button>
        </Col>
      </Row>
      <Row>
        { /*
          This could be implemented as a button
          and potentially the stack should be reset so the user
          cannot come back to this page
          */ }
        <Nav.Link onClick={resetAndExit}>
          <p className="lead fw-500 text-primary text-decoration-underline">Back to Statement</p>
        </Nav.Link>
      </Row>
    </div>
  </Container>
);

export default PaymentConfirmed;
