const formatUsd = (inputValue) => {
  const numericValue = inputValue.replace(/[^0-9.]/g, '');
  const [integerPart, fractionalPart] = numericValue.split('.');

  const formattedIntegerPart = integerPart ? new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 1,
    useGrouping: true,
  }).format(Number(integerPart || 0)) : '';

  return `${formattedIntegerPart}${fractionalPart !== undefined ? `.${fractionalPart}` : ''}`;
};

export default formatUsd;
