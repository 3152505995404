import ProfilePicNav from 'components/global/Loadable/_components/ProfilePicNav';
import NameNav from 'components/global/Loadable/_components/NameNav';
import { Placeholder } from 'react-bootstrap';
import Loader from 'components/global/Loader';
import ProfilePic from 'components/global/Loadable/_components/ProfilePic';
import TableLoader from 'components/global/TableLoader';

const Loadable = ({ signal, animation, placeholderType, children, template, className, length }) => {
  if (!signal) {
    return new Error('SIGNAL REQUIRED FOR LOADABLE');
  }

  if (signal?.value?.isLoading) {
    return (
      <>
        {template === 'table' ? (
          <TableLoader length={length} />
        ) : (
          <Placeholder as={placeholderType || 'div'} animation={animation || 'glow'} className="mb-0">
            {/* Normal Loadable */}
            {!template && (
            <Placeholder xs={12} className={`${className} form-control-loadable`} />
            )}
            {/* Text Loadable */}
            {template === 'text' && (
            <Placeholder xs={12} as={placeholderType || 'p'} className={`${className} text-loadable`} />
            )}
            {/* Component Loadable */}
            {template === 'component' && (
            <Loader className={`d-flex align-items-center justify-content-center my-40 ${className}`} />
            )}

            {/* CUSTOM LOADABLES */}
            {template === 'name-nav' && (
            <NameNav />
            )}
            {template === 'profilePic-nav' && (
            <ProfilePicNav />
            )}
            {template === 'profilePic' && (
            <ProfilePic />
            )}
            {template === 'spinner' && (
            <div className="w-100 d-flex justify-content-center text-primary">
              <div className="spinner-border" />
            </div>
            )}
            {/* END CUSTOM LOADABLES */}
          </Placeholder>
        )}
      </>
    );
  }

  return (<>{children}</>);
};

export default Loadable;
