import { Button, Modal } from 'react-bootstrap';

const SimpleModal = ({
  title,
  Title = null,
  children,
  onSave = () => { },
  onCancel = () => { },
  onClose = () => { },
  isShow = false,
  disabled = false,
  saveText = 'Save',
  cancelText = 'Cancel',
  size = 'md',
  isHideSave = false,
  isHideCancel = false,
  buttonPlacement = 'end',
  className = '',
}) => (
  <Modal onHide={onClose} show={isShow} size={size} enforceFocus={false} fullscreen="sm-down">
    <Modal.Header closeButton className="bg-light">
      <Modal.Title className="text-primary fw-900">
        {Title && <Title />}
        {title}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className={className}>
      {children}
    </Modal.Body>
    {(!isHideSave || !isHideCancel) && (
      <Modal.Footer className={`d-flex justify-content-${buttonPlacement}`}>
        {!isHideSave && <Button variant="primary" disabled={disabled} onClick={onSave}>{saveText}</Button>}
        {!isHideCancel && <Button variant="outline-secondary" onClick={onCancel || onClose}>{cancelText}</Button>}
      </Modal.Footer>
    )}

  </Modal>
);

export default SimpleModal;
