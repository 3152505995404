import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import { validateEmail } from 'utils/validateInput';
import Signal from 'signals/Signal';
import logEvent from 'utils/logEvent';

export const $forgotPassword = Signal({
  email: '',
  submitting: false,
});

export const handleFormChange = (e) => $forgotPassword.update({ [e.target.name]: e.target.value });

export const handleForgotPasswordPartial = async () => {
  try {
    $forgotPassword.loadingStart();
    $forgotPassword.update({ submitting: true });

    const { email } = $forgotPassword.value;
    validateEmail(email);

    await accruClient.auth.startResetPassword({
      data: { email },
    });
    logEvent({ name: 'reset_password_submit' });
    $forgotPassword.reset();
    handleNotification('Reset link requested successfully!', { variant: 'success' });
  } catch (error) {
    handleNotification(error);
  } finally {
    $forgotPassword.loadingEnd();
    $forgotPassword.update({ submitting: false });
  }
};
