import UniversalInput from 'components/global/Inputs/UniversalInput';
import magnifyingImg from 'images/magnifying.svg';
import closeButtonImg from 'images/closeButton.svg';
import { handleClearVendorsSearch, setVendorsFromAutocomplete } from 'components/views/Vendors/_helpers/Vendors.helpers';
import { $vendorsFilter } from 'components/views/Vendors/_helpers/Vendors.signals';
import { Image, InputGroup } from 'react-bootstrap';

const VendorsSearch = () => (
  <InputGroup
    className="py-8"
    onKeyDown={(e) => { if (e.key === 'Enter') setVendorsFromAutocomplete(); }}
  >
    <InputGroup.Text
      className="ps-32 border-0"
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
      }}
    >
      <Image src={magnifyingImg} />
    </InputGroup.Text>
    <UniversalInput
      name="search"
      signal={$vendorsFilter}
      className="custom-searchbox"
      autoComplete="off"
    />
    <InputGroup.Text
      className="ps-32 border-0"
      style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
      }}
      onClick={handleClearVendorsSearch}
    >
      {$vendorsFilter.value.search && (<Image src={closeButtonImg} />)}
    </InputGroup.Text>
  </InputGroup>
);

export default VendorsSearch;
