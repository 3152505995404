import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import accruSad from 'images/accruSad.svg';
import { useCallback } from 'react';

const SubscriptionErrorFallback = ({ resetErrorBoundary }) => {
  const handleReset = useCallback(() => {
    if (typeof resetErrorBoundary === 'function') resetErrorBoundary();
    else window.location.reload();
  }, [resetErrorBoundary]);

  return (
    <Container>
      <Row>
        <Col className="d-flex flex-column align-items-center justify-content-center text-center">
          <h1 className="fw-500 mb-64">Oops, something went wrong!</h1>
          <Image src={accruSad} height={128} />
          <Button className="mt-64 w-50" type="button" onClick={() => handleReset()}>Try again</Button>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionErrorFallback;
