import { getInvoiceTableRows } from 'components/global/InvoicesTable/invoiceTableFormat.helpers';
import SignalTable from 'components/global/SignalTable';
import Pagination from 'components/global/Pagination';

const InvoicesTable = ({
  $ui,
  $filter,
  items,
  onRowClick,
  totalCount,
  currentPageItemsCount,
  currentPage,
  onCurrentPageChange,
}) => (
  <>
    <SignalTable
      $ui={$ui}
      $filter={$filter}
      rows={getInvoiceTableRows(items)}
      headers={$ui.value.tableHeaders}
      isSelectable
      onRowClick={onRowClick}
      canFilterColumns
    />
    <Pagination
      itemsPerPageAmount={20}
      paginationMaxButtonAmount={5}
      totalItemsCount={totalCount}
      currentPageItemsCount={currentPageItemsCount || 0}
      currentPage={currentPage}
      setCurrentPage={onCurrentPageChange}
    />
  </>
);

export default InvoicesTable;
