import Signal from 'signals/Signal';

export const $settingsProfile = Signal({
  profilePhoto: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  contactVerificationCode: ['', '', '', '', '', ''],
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
  passwordRequirements: {
    reqLength: false,
    number: false,
    uppercase: false,
    symbol: false,
  },
  verificationCode: ['', '', '', '', '', ''],
});

export const $settingsProfileUI = Signal({
  currentTab: 'basic-info',
  isMobileModalOpen: false,
  contactPage: 'contact',
  passwordPage: 'password',
  passwordVisible: false,
  newPasswordVisible: false,
  confirmPasswordVisible: false,
});
