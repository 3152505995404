import { useCallback } from 'react';
import moment from 'moment';
import { Image, Button, Card, Col, Container, Row } from 'react-bootstrap';
import Money from 'components/global/Money';
import personAndDogImg from 'images/personAndDog.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const BillSummary = ({ data }) => {
  const handleButton = useCallback(() => { });

  return (
    <Col md={12} lg={6} className="z-index-n1 mb-sm-48">
      <Container className="pb-0 mb-0 e-lg-0 position-relative bill-summary-container">
        <div
          className="w-auto d-none d-lg-block h-auto position-absolute translate-middle top-50 z-index-1"
          style={{ right: '-170px' }}
        >
          <Image src={personAndDogImg} />
        </div>
        <Card className="bg-gray-50 pt-40 pb-16 border w-100 h-auto">
          <div className="px-56 my-16 w-100">
            <Row>
              <Col xs={9}>
                <p className="lead fw-800 text-primary mb-8">Balance Due:</p>
              </Col>
              <Col xs={3} className="d-flex justify-content-end pe-8">
                <p className="fw-800 text-dark mb-8">id:{data?.number}</p>
              </Col>
            </Row>
            <Row>
              <h1 className="fw-400 text-dark p-0 ps-16">
                <Money amount={data?.latest_acct_provider_balance} />
              </h1>
            </Row>
            {data?.latest_acct_provider_is_overdue && (
              <Row>
                <small className="text-danger fw-500">
                  <FontAwesomeIcon icon={faXmark} className="text-danger me-8" />
                  Overdue {Math.abs(moment(data?.due_date).diff(moment(), 'days'))} days
                </small>
              </Row>
            )}
            <Row className="mt-32 mb-0">
              <Col sm={6} lg={6} className="pe-64 pe-lg-16 w-auto">
                <Row className="mb-8">
                  <small className="fw-800 text-dark">Bill Date</small>
                </Row>
                <h4 className="fw-400 text-dark">
                  {moment().format('l')}
                </h4>
              </Col>
              <Col sm={6} lg={6} className="pe-64 pe-lg-16 w-auto">
                <Row className="mb-8">
                  <small className="fw-800 text-dark">Due Date</small>
                </Row>
                <h4 className="fw-400 text-dark">
                  {moment(data?.due_date).format('l')}
                </h4>
              </Col>
            </Row>
            <Row className="mb-16">
              <Row className="mb-8">
                <small className="fw-800 text-dark">Contact</small>
              </Row>
              <h4 className="fw-400 text-dark">{data?.organization_vendor?.email}</h4>
            </Row>
            <Row className="w-auto mb-16 mt-40">
              <Button
                variant="primary"
                className="py-16"
                disabled
                onClick={() => handleButton()}
              >
                PAY THIS BILL
              </Button>
            </Row>
            {!!data?.organization_vendor?.balance && (
              <Row className="w-auto mb-48 mt-16">
                <Button
                  variant="outline-primary"
                  className="py-16 border border-2 border-primary text-dark"
                  disabled
                  onClick={() => handleButton()}
                >
                  PAY TOTAL BALANCE: <Money amount={data?.organization_vendor?.balance} />
                </Button>
              </Row>
            )}
          </div>
          {/* <Row className="mt-16 ms-32">
            <p className="fw-800 text-dark">
              <FontAwesomeIcon icon={faPaperclip} className="me-8" />
              View invoice (TODO)
            </p>
          </Row> */}
        </Card>
      </Container>
    </Col>
  );
};

export default BillSummary;
