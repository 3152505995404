import { Row, Col, Button } from 'react-bootstrap';
import ComingSoonModal from 'components/global/ComingSoonModal';
import { $settingsPayment } from 'components/views/SettingsPayment/SettingsPayment.helpers';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';

const SneakPeak = () => {
  const { comingSoonModalOpen } = $settingsPayment.value;
  return (
    <Row className="mx-0">
      <ComingSoonModal
        show={comingSoonModalOpen}
        handleClose={() => $settingsPayment.update({
          ...$settingsPayment.value,
          comingSoonModalOpen: false,
        })}
      />
      <Row className="mx-0">
        <Col className="px-40 mt-40">
          <h2 className="text-center text-primary fw-700">We&apos;re working on something really cool that will elevate your experience.</h2>
          <h5 className="text-center text-primary fw-400">While you wait, how about a sneak peek?</h5>
          <Button
            className="w-auto m-auto d-flex mt-24"
            variant="primary btn-sm px-24"
            onClick={() => $settingsPayment.update({
              ...$settingsPayment.value,
              comingSoonModalOpen: !comingSoonModalOpen,
            })}
          >
            <small>
              SNEAK PEAK
            </small>
          </Button>
        </Col>
        <ThemeImage name="settings_sneak_peek" className="my-40 w-100" />
      </Row>
    </Row>
  );
};

export default SneakPeak;
