/* eslint-disable prefer-arrow-callback */
import { memo } from 'react';
import makeId from 'utils/makeId';

const statusPillOptions = [
  {
    label: 'paid',
    title: 'Paid',
    variant: 'success',
  },
  {
    label: 'complete',
    title: 'Complete',
    variant: 'success',
  },
  {
    label: 'overdue',
    title: 'Overdue',
    variant: 'danger',
  },
  {
    label: 'open',
    title: 'Open',
    variant: 'warning',
  },
  {
    label: 'pending',
    title: 'Pending',
    variant: 'warning',
  },
  {
    label: 'partial',
    title: 'Partial',
    variant: 'primary',
  },
];

const StatusPills = memo(function StatusPills({ status, isOverdue, className }) {
  const match = statusPillOptions.find(option => option.label === (isOverdue ? 'overdue' : status.toLowerCase()));
  return (
    <>
      {match ? (
        <span data-cy="status-pill" key={makeId(15)} className={`d-flex justify-content-center align-items-center text-center fw-700 rounded-pill status-pill d-inline-block border border-1 border-${match.borderColor} bg-${match.bgColor} text-${match.textColor} ${className || ''}`}>
          {match.title}
        </span>
      ) : null}
    </>
  );
});

export const StatusBadge = memo(function StatusBadge({ status, isOverdue }) {
  const match = statusPillOptions.find(option => option.label === (isOverdue ? 'overdue' : status.toLowerCase()));
  return (
    <>
      {match ? (
        <span
          key={makeId(15)}
          style={{
            paddingTop: 3,
            paddingBottom: 3,
            paddingRight: 12,
            paddingLeft: 12,
            borderRadius: 24,
            fontWeight: 800,
            fontFamily: 'Avenir',
            fontSize: 12,
            color: `var(--bs-${match.variant}-active)`,
            background: `var(--bs-${match.variant}-hover)`,
            border: `1px solid var(--bs-${match.variant})`,
          }}
        >
          {match.title}
        </span>
      ) : null}
    </>
  );
});

export default StatusPills;
