import moment from 'moment/moment';
import { Button, Col, Container, Image, Row, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { setActiveForm } from 'components/views/Statement/Old/Statement.helpers';
import { $choosePaymentReturning, handleBillsClicked, $bills } from 'components/views/Payment/Payment.helpers';
import Money from 'components/global/Money';
import SecureFooter from 'components/views/Payment/_components/SecureFooter';
import accruLogoImg from 'images/accruSmile.svg';
import plusImg from 'images/plus.svg';
import visaLogoImg from 'images/visaLogo.svg';
import discoverImg from 'images/discoverLogo.svg';
import pen from 'images/pen.svg';

const savedPaymentMethods = [
  {
    type: 'card',
    cardType: 'visa',
    isDefault: true,
    mask: 2800,
    expirationDate: new Date('2025/05/01'),
  },
  {
    type: 'card',
    cardType: 'discover',
    isDefault: false,
    mask: 3200,
    expirationDate: new Date('2025/07/01'),
  },
];

const ChoosePayment = ({ className }) => {
  const { selectedPaymentType } = $choosePaymentReturning.value;
  return (
    <Container className={className}>
      <Row className="p-24 pb-8">
        <h5>Pay <strong>FYC Labs</strong></h5>
      </Row>
      <Row className="pb-8 border-2 border-gray-800 mb-8 border-bottom d-flex jusity-content-around">
        <Col sm={{ offset: 3, span: 6 }} xs={{ span: 10, offset: 1 }} className="p-0">
          <h1 className="fw-700 text-primary mb-0">
            <Money amount={25000} />
          </h1>
        </Col>
        <Col sm={1} xs={1} className="d-flex float-start p-0 align-items-center">
          <Image src={pen} />
        </Col>
      </Row>
      <Row className="d-flex jusity-content-around">
        <Col sm={{ offset: 3, span: 6 }} xs={{ span: 10, offset: 1 }} className="d-flex justify-content-center align-items-center">
          <p className="fw-500 mb-0 lead">Payment Applies to 2 bills</p>
        </Col>
        <Col sm={1} xs={1} className="d-flex align-items-center p-0">
          <Button
            variant="transparent"
            className="border-0 text-dark p-0"
            onClick={handleBillsClicked}
          >
            <FontAwesomeIcon icon={$bills.value.open ? faChevronUp : faChevronDown} />
          </Button>
        </Col>
      </Row>
      <div className="px-xl-80 px-lg-80 px-md-80 px-24">
        {$bills.value.open && (
          $bills.value.list.map((obj, index) => (
            <Row key={obj.id} deleteBill={$bills.value.delete} className={`mx-0 py-16 border ${index % 2 === 0 && 'bg-gray-50'}`}>
              <Col sm={3} xs={3} className="d-flex align-items-center justify-content-end">
                <h5 className="fw-700 m-0">
                  {obj.name}
                  {' '}
                  #{obj.id}
                </h5>
              </Col>
              <Col sm={6} xs={6} className="d-flex align-items-center ps-0">
                <h5 className="m-0">
                  <Money className="fw-700 text-primary" amount={obj.amount} />
                </h5>
              </Col>
              <Col sm={3} xs={3}>
                <Button
                  variant="eye"
                  className="border-0 text-dark ps-0 shadow-none"
                />
              </Col>
            </Row>
          ))
        )}
        <Button
          onClick={() => $choosePaymentReturning.update({ selectedPaymentType: 'accruPay' })}
          className="p-16 w-100 mt-40 mb-16 py-24 btn-card"
          variant="gray-200"
          style={{ borderRadius: 10 }}
        >
          <Row className="d-flex align-items-center">
            <Col sm={2} className="d-flex justify-content-end">
              <Form.Check
                type="radio"
                id="accruPay"
                checked={selectedPaymentType === 'accruPay'}
              />
            </Col>
            <Col sm={2} className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center text-center">
                <Image src={accruLogoImg} height={28} />
              </div>
            </Col>
            <Col sm={8} className="text-start text-primary ps-0">
              <h5 className="fw-700 mb-0">Set Up Accru Pay!</h5>
              <small>You can smaller payments over time!</small>
            </Col>
          </Row>
        </Button>
        <div className="mt-16">
          <h5 className="text-dark fw-700">Choose saved payment method</h5>
        </div>
        {savedPaymentMethods.map(saved => (
          <Button
            onClick={() => $choosePaymentReturning.update({ selectedPaymentType: `${saved.cardType}${saved.mask}` })}
            className="p-16 w-100 my-16 py-24 btn-card"
            variant="gray-200"
            style={{ borderRadius: 10 }}
          >
            <Row className="d-flex align-items-center">
              <Col sm={2} className="d-flex justify-content-end">
                <Form.Check
                  type="radio"
                  id="accruPay"
                  checked={selectedPaymentType === `${saved.cardType}${saved.mask}`}
                />
              </Col>
              <Col sm={2} className="d-flex justify-content-center align-items-center ps-0">
                <div className="border d-flex justify-content-center align-items-center text-center" style={{ width: '48px', height: '28px' }}>
                  {saved.cardType === 'visa' ? (
                    <Image src={visaLogoImg} />
                  ) : (
                    <Image src={discoverImg} />
                  )}
                </div>
              </Col>
              <Col sm={8} className="text-start ps-0">
                <h5 className="fw-700 text-dark mb-0">{`${saved.cardType.charAt(0).toUpperCase()}${saved.cardType.slice(1)} `}<span>&middot; &middot; &middot; &middot;</span> {saved.mask}</h5>
                <small className="text-dark">{moment(saved.expirationDate).format('MM/YYYY')}</small>
              </Col>
            </Row>
          </Button>
        ))}
        <Button
          onClick={() => setActiveForm('ChoosePayment')}
          className="p-16 w-100 my-16 py-24 btn-card bg-gray-50 border-0"
          variant="transparent"
          style={{ borderRadius: 10, backgroundColor: '#FAFAFA' }}
        >
          <Row>
            <Col xs={2} sm={12} className="d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center me-40">
                <Image src={plusImg} height={18} />
              </div>
              <h5 className="fw-700 text-dark d-flex align-items-center mb-0">Add new payment method</h5>
            </Col>
          </Row>
        </Button>
        <Row className="mb-48 mt-16">
          <Col>
            <div className="mx-56">
              <Button
                type="button"
                variant="primary"
                className="w-100"
                onClick={() => setActiveForm('Processing')}
              >
                <div className="base" style={{ margin: '4px' }}>PAY $25,000</div>
              </Button>
            </div>
          </Col>
        </Row>
        <SecureFooter />
      </div>
    </Container>
  );
};

export default ChoosePayment;
