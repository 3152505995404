/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import { $invoiceAddSelect } from 'components/views/InvoiceAdd/InvoiceAdd.helpers';
import editImg from 'images/edit.svg';
import uploadImg from 'images/upload.svg';
import InvoiceManualAddSelectModal from 'components/views/InvoiceAdd/_components/InvoiceManualAddSelectModal';

const InvoiceManualAddSelect = ({ handleNext }) => (
  <>
    <Row>
      <Col className="text-center">
        <h1 className="text-primary fw-700 pt-16 pb-24">Upload or add invoices manually</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h2 className="pb-56 fw-400">Select your preferred way of adding invoices</h2>
      </Col>
    </Row>
    <Container className="pb-48">
      <Card onClick={() => handleNext({ current: 'manualSelect', next: 'form' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col lg={2}>
              <Image width={54} src={editImg} />
            </Col>
            <Col lg={9} className="text-start text-dark">
              <h5 className="fw-700">Enter invoice details</h5>
              <p className="base mb-0">Upload or add invoices manually</p>
            </Col>
          </Row>
        </Container>
      </Card>
      <Card onClick={() => handleNext({ current: 'manualSelect', next: 'upload' })} style={{ maxWidth: 620 }} className="cursor-pointer py-32 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col sm={12} lg={2}>
              <Image width={54} src={uploadImg} />
            </Col>
            <Col sm={12} lg={9} className="text-start text-dark">
              <h5 className="fw-700">Upload invoice file</h5>
              <p className="base mb-0 ">Auto-scans any digital invoice</p>
              <small
                onClick={(e) => {
                  e.stopPropagation();
                  $invoiceAddSelect.update({
                    infoModalOpen: true,
                  });
                }}
                className="mb-0 text-primary fw-500"
              >
                <u>What details are required?</u>
              </small>
            </Col>
          </Row>
        </Container>
      </Card>
      <InvoiceManualAddSelectModal handleNext={handleNext} />
    </Container>
  </>
);

export default InvoiceManualAddSelect;
