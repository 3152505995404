import SidePanelBills from 'components/global/SidePanel/SidePanelBills';
import SidePanelContacts from 'components/global/SidePanel/SidePanelContacts';
import SidePanelDetails from 'components/global/SidePanel/SidePanelDetails';
import SidePanelProjects from 'components/global/SidePanel/SidePanelProjects';
import SidePanelConnections from 'components/global/SidePanel/SidePanelConnections';
import { $vendorsUI } from 'components/views/Vendors/_helpers/Vendors.signals';
import TabNav from 'components/global/TabNav/TabNav';

export const vendorTabs = [
  {
    title: 'Details',
    key: 'details',
    component: <SidePanelDetails type="vendor" className="rounded-no-1" />,
  },
  {
    title: 'Contacts',
    key: 'contacts',
    component: <SidePanelContacts type="vendor" />,
  },
  {
    title: 'Bills',
    key: 'bills',
    component: <SidePanelBills type="vendor" />,
  },
  {
    title: 'Projects',
    key: 'projects',
    component: <SidePanelProjects type="vendor" />,
  },
  {
    title: 'Connections',
    key: 'connections',
    component: <SidePanelConnections type="vendor" className="rounded-no-2" />,
  },
];

const VendorDetailTabs = () => (
  <TabNav
    tabs={vendorTabs.map(tab => ({ key: tab.key, title: tab.title }))}
    TabPaneComponents={vendorTabs.map(tab => tab.component)}
    onClick={(t) => $vendorsUI.update({ openTab: t.key })}
    activeTab={$vendorsUI.value.openTab}
    defaultActiveTab="details"
  />
);

export default VendorDetailTabs;
