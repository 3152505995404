import { Button, Nav, Navbar } from 'react-bootstrap';

const NavBarPublic = () => (
  <>
    <Navbar bg="light" expand="lg" className="py-8 d-lg-block d-xl-block d-none fixed-top vw-100 z-index-10 px-16">
      <div className="text-end">
        Want to view more? <Button href="/signup" size="sm" variant="outline-primary">Signup</Button> or <Button href="/login" size="sm" variant="primary">Login</Button>
      </div>
    </Navbar>
    {/* MD & SM */}
    <Navbar bg="secondary" expand="lg" className="p-0 d-lg-none d-xl-none py-8 fixed-top vw-100 z-index-10">
      <Navbar.Collapse>
        <Nav>
          <hr className="m-0 mt-8" />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
);

export default NavBarPublic;
