import { useEffect } from 'react';

const IntuitRedirect = () => {
  useEffect(() => {
    if (window.opener && window.opener.origin === window.location.origin) {
      window.opener.postMessage(window.location.href, window.location.origin);
      window.close();
    }
  }, []);

  return (
    <div>
      <p>Redirecting...</p>
    </div>
  );
};

export default IntuitRedirect;
