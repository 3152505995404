/* eslint-disable no-use-before-define */
export default function updateQueryParams(obj) {
  const searchParams = new URLSearchParams(obj).toString();
  window.history.pushState(null, '', `?${searchParams}`);
}

const parseValue = (value) => {
  // Try to parse as JSON
  try {
    return JSON.parse(value);
  } catch (e) {
    // If JSON.parse fails, it was not JSON. Continue.
  }

  // Try to convert to a number
  const numberValue = Number(value);
  if (!Number.isNaN(numberValue)) {
    return numberValue;
  }

  // Return the original string if all else fails
  return value;
};

export function updateSignalWithQueryParams($signal) {
  const searchParams = new URLSearchParams(window.location.search);
  const filterValues = {};

  searchParams.forEach((value, key) => {
    if (value !== 'undefined') {
      filterValues[key] = parseValue(value);
    }
  });

  // Set the signal with the initial filter values from query parameters
  $signal.update(filterValues);
}
