import Signal from 'signals/Signal';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import {
  $invoice,
  getInvoice,
  sendInvoiceEmail,
} from 'components/views/Invoices/Invoices.helpers';

export const $invoiceDetail = Signal({
  sending_email: false,
});

export const fetchAndSetInvoiceData = async ({ id }) => {
  try {
    $invoice.loadingStart();

    const response = await getInvoice({
      organizationId: $user.value?.currentOrganization?.id,
      organizationInvoiceId: id,
    });

    $invoice.update(response);
  } catch (error) {
    handleNotification(error);
  } finally {
    $invoice.loadingEnd();
  }
};

export const sendInvoiceDetailEmail = async ({ id }) => {
  $invoiceDetail.loadingStart();
  try {
    await sendInvoiceEmail({
      targets: [{
        organizationInvoiceId: id,
        sendToPrimaryContact: true,
        contactIds: [], // TODO: maybe add a option to select contacts
      }],
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $invoiceDetail.loadingEnd();
  }
};
