import { $global } from 'signals/Global.signals';
import { Image } from 'react-bootstrap';

const ThemeImage = ({ name, height, className, style = { borderRadius: '0px' }, alt }) => {
  const { brand } = $global.value;
  const imgSrc = `https://firebasestorage.googleapis.com/v0/b/accruco.appspot.com/o/${brand}%2F${name}?alt=media`;

  if (!brand || !name) {
    return <div />;
  }

  return (
    <Image
      src={imgSrc}
      alt={alt || 'info image'}
      height={height}
      className={className}
      style={style}
    />
  );
};

export default ThemeImage;
