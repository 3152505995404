import { Button, Row } from 'react-bootstrap';
import { $acceptInviteDetail, handleLogout } from 'components/views/Auth/_shared/Auth.helpers';
import history from 'utils/history';
import Loadable from 'components/global/Loadable';
import $user from 'signals/User.signals';

const WrongAccount = () => (
  <div className="text-center">
    <Loadable template="text" signal={$acceptInviteDetail}>
      <h3>This invite from  <strong>{$acceptInviteDetail.value.organization_name}</strong> is for another Accru account</h3>
    </Loadable>
    <Loadable template="text" signal={$acceptInviteDetail}>
      <p>You&apos;re logged in as {$user.value.user.email}, but this invite is for {$acceptInviteDetail.value.email}!</p>
    </Loadable>
    <Row className="d-flex align-items-center justify-content-center my-24 mx-0">
      <Button onClick={() => handleLogout()} variant="primary" type="button" className="w-100 h-100">
        Logout
      </Button>
    </Row>
    <Row className="d-flex align-items-center justify-content-center my-24 mx-0">
      <Button
        variant="outline-primary"
        type="submit"
        onClick={() => history.replace('/customers')}
        className="w-100 h-100"
      >
        Go to home
      </Button>
    </Row>
  </div>
);

export default WrongAccount;
