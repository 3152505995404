import { Button, Col, Form, Row, Image } from 'react-bootstrap';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import { handleEmailSubmitPartial, $signUpForm } from 'components/views/Auth/SignUp/Signup.helpers.js';
import {
  partialGoogleLoginHandler,
  performIntuitCallbackHandoff,
} from 'components/views/Auth/_shared/Auth.helpers';
import GoogleLogo from 'images/google.svg';
import ReturnToLoginRow from './ReturnToLoginRow';

const EmailSignupForm = ({ isEmbed }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('code') && location.search.includes('state')) {
      performIntuitCallbackHandoff(`${window.location.origin}${'/signup'}${location.search}`);
    }
  }, [location.search]);

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-24">Sign up</h2>
      <Form.Label htmlFor="EmailAddress"><strong>Email address</strong></Form.Label>
      <UniversalInput
        placeholder="Enter email"
        className="mb-24"
        name="email"
        signal={$signUpForm}
      />
      <Button
        variant="primary"
        type="submit"
        onClick={() => {
          handleEmailSubmitPartial($signUpForm.value.email);
        }}
        disabled={$signUpForm.value.email === '' || $signUpForm.value?.isLoading}
        className="w-100 btn-lg mb-16"
      >
        GET STARTED
      </Button>
      {!isEmbed && (
      <ReturnToLoginRow />
      )}
      {!isEmbed && (
      <Row>
        <Col xs={4}>
          <hr />
        </Col>
        <Col xs={4} className="d-flex justify-content-center align-items-center">
          Or sign up with
        </Col>
        <Col xs={4}>
          <hr />
        </Col>
      </Row>
      )}
      {!isEmbed && (
      <Row className="d-flex align-items-center justify-content-center mt-16 mb-24">
        {/* FUTURE FUNCTIONALITY */}
        {/* <Col xs={6} className="d-flex justify-content-around">
          <Button
            variant="white"
            type="submit"
            onClick={() => handleIntuitStartLogin()}
            className="w-100 btn-lg h-100 border-black"
          ><Image
            src={IntuitLogo}
            height={16}
            alt="Intuit Logo"
          />
          </Button>
        </Col> */}
        <Col xs={6} className="d-flex justify-content-around">
          <Button
            variant="white"
            type="submit"
            onClick={partialGoogleLoginHandler}
            className="w-100 btn-lg h-100 p-0 border-black"
          ><Image
            src={GoogleLogo}
            height={25}
            alt="Google Logo"
          />
          </Button>
        </Col>
      </Row>
      )}
    </Form.Group>
  );
};

export default EmailSignupForm;
