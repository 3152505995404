const { useEffect } = require('react');

const useCleanup = (signals) => {
  useEffect(() => () => {
    if (Array.isArray(signals)) {
      signals.forEach((signal) => signal.reset());
    } else {
      signals.reset?.();
    }
  }, []);
};

export default useCleanup;
