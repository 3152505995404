import Signal from './Signal';
/**
 * @template T
 * @typedef {Object} SignalOutput
 * @property {T} value - The value returned by the Signal function.
 * @property {function} loadingStart - Sets the isLoading property to true.
 * @property {function} loadingEnd - Sets the isLoading property to false.
 * @property {function} peek - Gets the value of the signal by prop.
 * @property {function} reset - Resets the value of the signal to the original value established at creation.
 * @property {function} update - Updates the value of the signal without needing to spread props.
 */

/**
 * @typedef {Object} Alert
 * @property {Array<object>} notificationArray - all notifications to be displayed
 */

/** @type {SignalOutput<Alert>} */
export const $alert = Signal({
  notificationArray: [],
});

/**
 * @typedef {Object} NavBar
 * @property {boolean} isOpen - Indicates whether the navigation bar is open.
 * @property {boolean} isComingSoonOpen - Indicates whether the 'coming soon' section is open.
 */

/** @type {SignalOutput<NavBar>} */
export const $navBar = Signal({
  isOpen: false,
  isComingSoonOpen: false,
});

/**
 * @typedef {Object} SearchableDropdown
 * @property {string} searchString - The search string for the dropdown.
 */

/** @type {SignalOutput<SearchableDropdown>} */
export const $searchableDropdown = Signal({
  searchString: '',
});

/**
 * @typedef {Object} WindowSize
 * @property {string|null} breakPoint - The breakpoint for the window size.
 * @property {number|null} height - The height of the window.
 */

/** @type {SignalOutput<{windowSize: WindowSize}>} */
export const $windowSize = Signal({
  windowSize: {
    breakPoint: null,
    height: null,
  },
});

/**
 * @typedef {Object} Global
 * @property {boolean} isLoading - Indicates whether the application is loading.
 * @property {string} isLoadingMessage - The loading message.
 * @property {boolean} isSignedIn - Indicates whether the user is signed in.
 * @property {boolean} isBackendConnDown - Indicates whether the backend connection is down.
 * @property {number} versionNumber - The version number of the application.
 * @property {number} prodVersion - The production version number.
 */

/** @type {SignalOutput<Global>} */
export const $global = Signal({
  isLoading: true,
  isLoadingMessage: 'Loading...',
  isSignedIn: false,
  isBackendConnDown: false,
  versionNumber: 1, // Updated through MVP.jsx
  prodVersion: 1, // This would need to be manually updated
}, true);

/**
 * @typedef {Object} Loader
 * @property {boolean} isLoading - Indicates whether a loading operation is in progress.
 * @property {string} isLoadingMessage - The loading message.
 */

/** @type {SignalOutput<Loader>} */
export const $loader = Signal({
  isLoading: false,
  isLoadingMessage: 'Loading...',
});

/**
 * @typedef {Object} Notification
 * @property {boolean} exiting - Indicates whether a notification is exiting.
 */

/** @type {SignalOutput<Notification>} */
export const $notification = Signal({
  exiting: false,
});

/**
 * @typedef {Object} CMSData
 * @property {boolean} loading - Indicates whether CMS data is being loaded.
 * @property {Object|null} data - The CMS data.
 */

/** @type {SignalOutput<CMSData>} */
export const $getCMSData = Signal({
  loading: true,
  data: null,
});

/**
 * @typedef {Object} Toastr
 * @property {Array<Object>} toasts - Array of toast notifications.
 */

/** @type {SignalOutput<Toastr>} */
export const $toastr = Signal({
  toasts: [],
});

/**
 * @typedef {Object} Syncing
 * @property {boolean} isSyncing - Indicates whether a sync operation is in progress.
 * @property {boolean} isSyncToastOpen - Indicates whether the sync toast is open.
 * @property {boolean} isFinished - Indicates whether the sync operation is finished.
 * @property {string} referredBy - The entity that referred the sync operation.
 * @property {Array<Object>} syncData - Array of data being synced.
 * @property {Array<Object>} providersInSync - Array of providers currently in sync.
 */

/** @type {SignalOutput<Syncing>} */
export const $syncing = Signal({
  isSyncing: false,
  isSyncToastOpen: false,
  isFinished: false,
  referredBy: '',
  syncData: [],
  providersInSync: [],
});

export const $authTimerId = Signal(null); // Timer for authentication session
export const $areYouStillHereTimerId = Signal(null); // Timer for authentication session
export const $areYouStillHereModalOpen = Signal(false); // Timer for authentication session
