import formatCurrency from 'utils/formatCurrency';

const Money = ({ amount, hide, className }) => {
  const text = hide ? '$ --.--' : formatCurrency({
    amountCents: amount,
  });

  return (
    <span className={className}>
      {text}
    </span>
  );
};
export default Money;
