import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { $global } from 'signals/Global.signals';
import img from 'images/404Background.svg';
import NavBar from 'components/global/NavBar';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';

const NotFound = () => {
  const history = useHistory();
  const { isSignedIn, isLoading } = $global.value;

  return (
    <div>
      <div
        className="d-flex p-0 justify-content-between flex-column 100vh bg-404"
        style={{ backgroundImage: `url(${img})` }}
      >
        {!isLoading && isSignedIn && (
          <div className="pb-40">
            <NavBar />
          </div>
        )}
        <div className="container d-flex h-100 justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-center justify-content-around">
            <h2 className="text-center">
              Hmmm... we couldn&apos;t find what you&apos;re looking for
            </h2>
            <Button onClick={() => history.push('/')} className="mt-24 mb-48 btn-lg" variant="primary">BACK TO HOME</Button>
            <ThemeImage name="hero_404" className="img-fluid" alt="404" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
