import { accruClient } from 'api';
import { historyPush } from 'utils/history';
import Signal from 'signals/Signal';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { $customers } from 'components/views/Customers/_helpers/Customers.signals';
import logEvent from 'utils/logEvent';
import { effect } from '@preact/signals-react';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';

export const $customersForm = Signal({
  name: '',
  email: '',
  phone_number: '',
});

export const $addCustomerUpload = Signal({
  uploadedFiles: [],
});

export const $addCustomer = Signal({
  currentScreen: 'method',
  backButtonHistory: [],
});

effect(() => {
  if ($addCustomer.value.currentScreen) {
    logEvent(`add_customer.${$addCustomer.value.currentScreen}`);
  }
});

export const handleFileChange = (event) => {
  const { uploadedFiles } = $addCustomerUpload.value;

  const fileObj = event.target.files && event.target.files[0];
  if (!fileObj) {
    return;
  }
  const tempArray = uploadedFiles;
  tempArray.push({ fileName: fileObj.name, fileSize: fileObj.size });
  $addCustomerUpload.update({
    uploadedFiles: tempArray,
  });
};

export const handleAddCustomer = async () => {
  try {
    $customersForm.loadingStart();

    const { name, email } = $customersForm?.value;
    const newCustomer = await accruClient.customers.create({
      organizationId: $user.value.currentOrganization.id,
      data: {
        name,
        email,
        phone_number: $customersForm?.value.phone_number ? formatPhoneNumForApi($customersForm.value.phone_number) : null,
        is_active: true,
      },
    });

    if (newCustomer) {
      logEvent({ name: 'add_customer_success' });
      handleNotification('Customer created!', { variant: 'success' });
      const prevCustomers = $customers.value?.items || [];
      $customers.update({ items: [newCustomer, ...prevCustomers] });
      historyPush('/customers');
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $customersForm.loadingEnd();
  }
};
