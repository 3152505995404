import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import { faEye, faEyeSlash, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import { performCompleteSignup, $signUpForm, $passwordForm, validatePassword } from 'components/views/Auth/SignUp/Signup.helpers.js';
import { $acceptInviteDetail } from 'components/views/Auth/_shared/Auth.helpers';
import ReturnToLoginRow from './ReturnToLoginRow';

const PasswordForm = ({ isEmbed }) => {
  const isInvitedUser = !!$acceptInviteDetail.value.organization_invite_id;

  const { passwordVisible, passwordRequirements } = $passwordForm.value;
  const { password, confirmPassword } = $signUpForm.value;

  useEffect(() => {
    if (password) {
      validatePassword(password);
    }
  }, [password]);

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-40">Create password</h2>
      <Form.Label htmlFor="EmailAddress"><strong>Password</strong></Form.Label>
      <UniversalInput
        placeholder="Enter New Password"
        value={password}
        type={passwordVisible ? 'text' : 'password'}
        className="mb-16"
        name="password"
        signal={$signUpForm}
      />
      <Form.Label htmlFor="Password"><strong>Confirm New Password</strong></Form.Label>
      <Row className="m-0">
        <Col xs={11} className="p-0">
          <UniversalInput
            placeholder="Confirm Password"
            value={confirmPassword}
            type={passwordVisible ? 'text' : 'password'}
            className="mb-16"
            name="confirmPassword"
            signal={$signUpForm}
          />
        </Col>
        <Col xs={1} className="mb-16 p-0 border-bottom border-gray border-1 d-flex justify-content-center align-items-center">
          <Button
            role="button"
            tabIndex="0"
            onClick={() => $passwordForm.update({ passwordVisible: !passwordVisible })}
            variant="eye"
            className="text-primary rounded-0 border-dark d-flex justify-content-center align-items-center"
          >
            <FontAwesomeIcon className="d-flex justify-content-center align-items-center" icon={passwordVisible ? faEye : faEyeSlash} />
          </Button>
        </Col>
      </Row>
      <Form.Label>Password must:</Form.Label>
      <div className="text-dark">
        <div className="ms-16">
          <FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.reqLength ? '#41696C' : '#CDCDCD'} />
          Be 12-36 characters in length
        </div>
        <div className="ms-16">
          <FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.number ? '#41696C' : '#CDCDCD'} />
          Contain at least one number
        </div>
        <div className="ms-16">
          <FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.uppercase ? '#41696C' : '#CDCDCD'} />
          Contain at least one uppercase letter
        </div>
        <div className="ms-16">
          <FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.symbol ? '#41696C' : '#CDCDCD'} />
          Contain at least one special character
        </div>
      </div>
      <Button
        variant="primary"
        type="submit"
        onClick={() => performCompleteSignup(isEmbed)}
        className="w-100 btn-lg mb-16 mt-24"
        disabled={confirmPassword === '' || password === ''}
      >
        SUBMIT
      </Button>
      {!isInvitedUser && !isEmbed && (
        <ReturnToLoginRow />
      )}
    </Form.Group>
  );
};

export default PasswordForm;
