import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpLong, faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { Image } from 'react-bootstrap';
import sort from 'images/sort-alt.svg';

const SortingArrow = ({ field, signal, defaultField = 'created_at' }) => {
  const { selectedSorting, sorting } = signal.value;
  const { field: selectedField, order } = selectedSorting ? selectedSorting[0] : sorting[0];

  return (
    <>
      {(selectedField === defaultField && field !== defaultField) && <Image src={sort} />}
      {(order === 'ASC' && field === selectedField) && <FontAwesomeIcon icon={faArrowUpLong} />}
      {(order === 'DESC' && field === selectedField) && <FontAwesomeIcon icon={faArrowDownLong} />}
    </>
  );
};
export default SortingArrow;
