import Signal from 'signals/Signal';

export const $filterStatement = Signal({
  status: 'Open',
  sortKey: 'invoice_date',
  sortDirection: 'desc',
});

export const $listStatement = Signal({ items: [], page: 1, count: 0 });

export const $uiStatement = Signal({
  isTableLoading: false,
  activeTab: 'invoice',
  isSyncing: false,
});

export const $detailStatement = Signal({
  totalBalance: 0,
  lastUpdated: null,
  customer: {
    name: '',
  },
  vendor: {
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    email: '',
    phone: '',
    logo: '',
  },
  invoiceCounts: {
    open: 0,
    overdue: 0,
  },
  aging: {
    '1-31': 0,
    '31-60': 0,
    '90 +': 0,
  },
});
