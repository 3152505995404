import { Navbar, Nav, Row, Col, Dropdown, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import ComingSoonModal from 'components/global/ComingSoonModal';
import Loadable from 'components/global/Loadable';
import { ReactComponent as Store } from 'images/store.svg';
import { ReactComponent as StoreActive } from 'images/store-active.svg';
import { ReactComponent as Folders } from 'images/folders.svg';
import { ReactComponent as FoldersActive } from 'images/folders-active.svg';
import { ReactComponent as CreditCard } from 'images/credit-card.svg';
import { ReactComponent as CreditCardActive } from 'images/credit-card-active.svg';
import { ReactComponent as FileInvoiceDollar } from 'images/file-invoice-dollar.svg';
import { ReactComponent as FileInvoiceDollarActive } from 'images/file-invoice-dollar-active.svg';
import { ReactComponent as NavActive } from 'images/nav-active-polygon.svg';
import { ReactComponent as Users } from 'images/users.svg';
import { ReactComponent as UsersActive } from 'images/users-active.svg';

import $user from 'signals/User.signals';
import { $navBar } from 'signals/Global.signals';
import { handleLogout } from 'components/views/Auth/_shared/Auth.helpers';
import RoundedImage from 'components/global/RoundedImage';
import { handleSelectOrganizationModalOpen } from 'components/views/SelectOrganization/SelectOrganization.helpers';
import { handleSelectAdminOrganizationModalOpen } from 'components/views/SelectAdminOrganization/SelectAdminOrganization.helpers';
import SelectOrganizationModal from 'components/views/SelectOrganization/SelectOrganization';
import SelectAdminOrganizationModal from 'components/views/SelectAdminOrganization';
import NoAccessTooltip from 'components/global/NoAccessTooltip/NoAccessTooltip';
import { isImpersonating, selectFirstAvailableOrganizationAndRedirect } from 'components/helpers/User.helpers';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';
import isForbiddenOrganizationUser from 'utils/isForbiddenOrganizationUser';

const NavBar = () => {
  const { pathname } = useLocation();
  const { isOpen, isComingSoonOpen } = $navBar.value;

  return (
    <div>
      <ComingSoonModal show={isComingSoonOpen} handleClose={() => $navBar.update({ isComingSoonOpen: !isComingSoonOpen })} />
      <SelectOrganizationModal />
      {!!$user.value.user.is_admin && (<SelectAdminOrganizationModal />)}
      {/* LG & XL */}
      <Navbar bg="light" expand="lg" className="py-8 d-lg-block d-xl-block d-none position-fixed vw-100 z-index-10">
        <Row className="w-100">
          <Col lg={3} className="my-auto d-flex justify-content-center pe-0">
            <LinkContainer to="/customers">
              <Navbar.Brand>
                <ThemeImage name="navbar" />
              </Navbar.Brand>
            </LinkContainer>
          </Col>
          <Col lg={5} xl={6}>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0" />
            <Navbar.Collapse>
              <Nav className="flex-grow-1 justify-content-evenly">
                <NoAccessTooltip>
                  <Nav.Item className="my-auto">
                    <LinkContainer to="/customers">
                      <Nav.Link className={isForbiddenOrganizationUser() ? 'disabled opacity-50' : ''}>
                        {pathname === '/customers' ? (
                          <UsersActive className="svg-primary d-flex mx-auto" alt="customers" height="19px" />
                        ) : (
                          <Users className="svg-dark d-flex mx-auto" alt="customers" height="19px" />
                        )}
                        <h5 className={`${pathname === '/customers' ? 'text-primary' : 'text-dark'} mb-0 mt-8`}>Customers</h5>
                        {pathname === '/customers' ? (
                          <NavActive alt="active" className="d-flex mx-auto svg-primary" height="10px" />
                        ) : (
                          <div style={{ height: '10px' }} />
                        )}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </NoAccessTooltip>
                <NoAccessTooltip>
                  <Nav.Item className="my-auto">
                    <LinkContainer to="/vendors">
                      <Nav.Link className={isForbiddenOrganizationUser() ? 'disabled opacity-50' : ''}>
                        {pathname === '/vendors' ? (
                          <StoreActive className="svg-primary d-flex mx-auto" alt="vendors" height="19px" />
                        ) : (
                          <Store className="svg-dark d-flex mx-auto" alt="vendors" height="19px" />
                        )}
                        <h5 className={`${pathname === '/vendors' ? 'text-primary' : 'text-dark'} mb-0 mt-8`}>Vendors</h5>
                        {pathname === '/vendors' ? (
                          <NavActive alt="active" className="d-flex mx-auto svg-primary" height="10px" />
                        ) : (
                          <div style={{ height: '10px' }} />
                        )}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </NoAccessTooltip>
                <NoAccessTooltip>
                  <Nav.Item className="my-auto">
                    <LinkContainer to="/invoices">
                      <Nav.Link className={isForbiddenOrganizationUser() ? 'disabled opacity-50' : ''}>
                        {pathname === '/invoices' ? (
                          <FileInvoiceDollarActive className="svg-primary d-flex mx-auto" alt="invoices" height="19px" />
                        ) : (
                          <FileInvoiceDollar className="svg-dark d-flex mx-auto" alt="invoices" height="19px" />
                        )}
                        <h5 className={`${pathname === '/invoices' ? 'text-primary' : 'text-dark'} mb-0 mt-8`}>Invoices</h5>
                        {pathname === '/invoices' ? (
                          <NavActive alt="active" className="d-flex mx-auto svg-primary" height="10px" />
                        ) : (
                          <div style={{ height: '10px' }} />
                        )}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </NoAccessTooltip>
                <NoAccessTooltip>
                  <Nav.Item className="my-auto">
                    <LinkContainer to="/bills">
                      <Nav.Link className={isForbiddenOrganizationUser() ? 'disabled opacity-50' : ''}>
                        {pathname === '/bills' ? (
                          <CreditCardActive className="svg-primary d-flex mx-auto" alt="bills" height="19px" />
                        ) : (
                          <CreditCard className="svg-dark d-flex mx-auto" alt="bills" height="19px" />
                        )}
                        <h5 className={`${pathname === '/bills' ? 'text-primary' : 'text-dark'} mb-0 mt-8`}>Bills</h5>
                        {pathname === '/bills' ? (
                          <NavActive alt="active" className="d-flex mx-auto svg-primary" height="10px" />
                        ) : (
                          <div style={{ height: '10px' }} />
                        )}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </NoAccessTooltip>
                <Nav.Item className={isForbiddenOrganizationUser() ? 'disabled opacity-50 my-auto' : 'my-auto'}>
                  <Nav.Link onClick={() => $navBar.update({ isComingSoonOpen: !isComingSoonOpen })}>
                    {pathname === '/projects' ? (
                      <FoldersActive className="svg-primary d-flex mx-auto" alt="projects" height="19px" />
                    ) : (
                      <Folders className="svg-dark d-flex mx-auto" alt="projects" height="19px" />
                    )}
                    <h5 className={`${pathname === '/projects' ? 'text-primary' : 'text-dark'} mb-0 mt-8`}>Projects</h5>
                    {pathname === '/projects' ? (
                      <NavActive alt="active" className="d-flex mx-auto svg-primary" height="10px" />
                    ) : (
                      <div style={{ height: '10px' }} />
                    )}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Col>
          <Col lg={{ span: 3, offset: 1 }} xl={{ span: 2, offset: 1 }} className="border-start border-primary d-flex align-items-center">
            <Dropdown className="custom-navbar-dropdown">
              <Button
                variant="eye"
                onClick={() => $navBar.update({ isOpen: !isOpen })}
                className="p-0 m-0 h-100"
              >
                <Dropdown.Toggle variant="eye" className="custom-dropdown-toggle p-0 m-0 h-100">
                  <Row>
                    <Col sm={12} className="h-100 d-flex align-items-center justify-content-around">
                      {$user.value?.user?.profile_picture_file?.public_url ? (
                        <Loadable signal={$user} template="profilePic-nav">
                          <RoundedImage
                            src={$user.value?.user?.profile_picture_file?.public_url}
                            alt="Profile Photo"
                            height="45px"
                            width="45px"
                          />
                        </Loadable>
                      ) : (
                        <ThemeImage name="nav_user" height={33} alt="user icon" />
                      )}
                      <div className="d-row my-auto ps-8">
                        <Loadable signal={$user} template="name-nav">
                          <p className="lead d-flex align-items-center m-0">{$user.value?.user?.first_name || ''} {$user.value?.user?.last_name || ''}</p>
                          <p className="lead d-flex align-items-center m-0 text-secondary text-wrap text-start">
                            {$user.value?.currentOrganization.name || ''}
                          </p>
                          {!!isImpersonating.value && (
                          <p className="base fw-500 d-flex align-items-center m-0 text-danger text-underline text-wrap text-start">
                            Currently impersonating
                          </p>
                          )}
                        </Loadable>
                      </div>
                      <FontAwesomeIcon className="px-8 text-secondary" icon={isOpen ? faChevronUp : faChevronDown} />
                    </Col>
                  </Row>
                </Dropdown.Toggle>
              </Button>
              <Dropdown.Menu className="px-8">
                <LinkContainer to="/profile">
                  <Dropdown.Item className="rounded text-center h5">Profile</Dropdown.Item>
                </LinkContainer>
                <NoAccessTooltip>
                  <LinkContainer to="/settings">
                    <Dropdown.Item className="rounded text-center h5">Settings</Dropdown.Item>
                  </LinkContainer>
                </NoAccessTooltip>
                <Dropdown.Item className="rounded text-center h5" onClick={handleSelectOrganizationModalOpen}>Switch Organization</Dropdown.Item>
                {!!$user.value.user.is_admin && (
                  <>
                    {isImpersonating.value ?
                      (<Dropdown.Item className="rounded text-center text-danger h5" onClick={() => selectFirstAvailableOrganizationAndRedirect()}>Stop Impersonating</Dropdown.Item>)
                      :
                      (<Dropdown.Item className="rounded text-center text-danger h5" onClick={handleSelectAdminOrganizationModalOpen}>Impersonate Organization</Dropdown.Item>)}
                  </>
                )}
                <Dropdown.Item className="rounded text-center h5" onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Navbar>
      {/* MD & SM */}
      <Navbar bg="light" expand="lg" className="p-0 d-lg-none d-xl-none py-8 position-fixed vw-100 z-index-10" collapseOnSelect>
        <Row className="w-100 m-0">
          <Col xs={8} className="my-auto d-flex justify-content-start pe-0">
            <LinkContainer to="/customers">
              <Navbar.Brand><ThemeImage name="navbar" /></Navbar.Brand>
            </LinkContainer>
          </Col>
          <Col xs={4} className="my-auto d-flex justify-content-end">
            <Navbar.Toggle className="border-0 my-auto d-flex justify-content-end" />
          </Col>
        </Row>
        <Navbar.Collapse>
          <Nav>
            <hr className="m-0 mt-8" />
            <NoAccessTooltip>
              <Nav.Item className="my-auto" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.collapse">
                <LinkContainer to="/customers">
                  <Nav.Link className="d-flex justify-content-center">
                    {pathname === '/customers' ? (
                      <UsersActive alt="customers" height="19px" />
                    ) : (
                      <Users alt="customers" height="19px" />
                    )}
                    <h5 className={`${pathname === '/customers' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Customers</h5>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </NoAccessTooltip>
            <NoAccessTooltip>
              <Nav.Item className="my-auto">
                <LinkContainer to="/vendors">
                  <Nav.Link className="d-flex justify-content-center">
                    {pathname === '/vendors' ? (
                      <StoreActive alt="vendors" height="19px" />
                    ) : (
                      <Store alt="vendors" height="19px" />
                    )}
                    <h5 className={`${pathname === '/vendors' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Vendors</h5>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </NoAccessTooltip>
            <NoAccessTooltip>
              <Nav.Item className="my-auto">
                <LinkContainer to="/invoices">
                  <Nav.Link className="d-flex justify-content-center">
                    {pathname === '/invoices' ? (
                      <FileInvoiceDollarActive alt="invoices" height="19px" />
                    ) : (
                      <FileInvoiceDollar alt="invoices" height="19px" />
                    )}
                    <h5 className={`${pathname === '/invoices' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Invoices</h5>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </NoAccessTooltip>
            <NoAccessTooltip>
              <Nav.Item className="my-auto">
                <LinkContainer to="/bills">
                  <Nav.Link className="d-flex justify-content-center">
                    {pathname === '/bills' ? (
                      <CreditCardActive alt="bills" height="19px" />
                    ) : (
                      <CreditCard alt="bills" height="19px" />
                    )}
                    <h5 className={`${pathname === '/bills' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Bills</h5>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </NoAccessTooltip>
            <NoAccessTooltip>
              <Nav.Item className="my-auto">
                <Nav.Link className="d-flex justify-content-center" onClick={() => $navBar.update({ isComingSoonOpen: !isComingSoonOpen })}>
                  {pathname === '/projects' ? (
                    <FoldersActive alt="projects" height="19px" />
                  ) : (
                    <Folders alt="projects" height="19px" />
                  )}
                  <h5 className={`${pathname === '/projects' ? 'text-primary fw-500' : ''} mb-0 ms-8`}>Projects</h5>
                </Nav.Link>
              </Nav.Item>
            </NoAccessTooltip>
            <hr className="m-0 mt-8" />
            <Nav.Item className="my-auto">
              <LinkContainer to="/profile">
                <Nav.Link className="d-flex justify-content-center">
                  <h5 className="mb-0">Profile</h5>
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <NoAccessTooltip>
              <Nav.Item className="my-auto">
                <LinkContainer to="/settings">
                  <Nav.Link className={`d-flex justify-content-center ${isForbiddenOrganizationUser() ? 'disabled opacity-50' : ''}`}>
                    <h5 className="mb-0">Settings</h5>
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </NoAccessTooltip>
            <Nav.Item className="my-auto">
              <Nav.Link onClick={handleSelectOrganizationModalOpen} className="d-flex justify-content-center">
                <h5 className="mb-0">Switch Company</h5>
              </Nav.Link>
            </Nav.Item>
            {!!$user.value.user.is_admin && (
            <>
              {isImpersonating.value ?
                (
                  <Nav.Item className="my-auto">
                    <Nav.Link onClick={() => selectFirstAvailableOrganizationAndRedirect()} className="d-flex justify-content-center">
                      <h5 className="mb-0 text-danger">Stop Impersonating</h5>
                    </Nav.Link>
                  </Nav.Item>
                )
                :
                (
                  <Nav.Item className="my-auto">
                    <Nav.Link onClick={handleSelectAdminOrganizationModalOpen} className="d-flex justify-content-center">
                      <h5 className="mb-0 text-danger">Impersonate Organization</h5>
                    </Nav.Link>
                  </Nav.Item>
                )}
            </>
            )}
            <Nav.Item className="my-auto">
              <Nav.Link onClick={handleLogout} className="d-flex justify-content-center">
                <h5 className="mb-0">Logout</h5>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
