import Signal from 'signals/Signal';
import { effect, computed } from '@preact/signals-react';

export const $vendorsUI = Signal({
  isMobileModalOpen: false,
  isSearchListOpen: false,
  openTab: 'details',
  isConnectionModalOpen: false,
});
export const $vendorsFilter = Signal({
  search: '',
  selectedSorting: [{ field: 'balance', order: 'DESC' }],
  page: 1,
  perPage: 20,
  totalCount: 0,
});
export const $vendors = Signal({ items: [] });
export const $selectedVendor = Signal({});

export const $vendorsSearchCache = Signal({});
export const $searchKey = computed(() => $vendorsFilter.value.search.toLowerCase());
export const $isCacheAvailable = computed(() => !!$vendorsSearchCache.value[$searchKey.value]);
export const $vendorsAutocomplete = computed(() => $vendorsSearchCache.value?.[$searchKey.value]?.filter(({ name }) => {
  const searchTerm = $vendorsFilter.value.search.trim().toLowerCase();
  return name.toLowerCase().includes(searchTerm);
}) || []);

effect(() => {
  if (JSON.stringify($selectedVendor.value) !== JSON.stringify($selectedVendor.previousValue)) {
    const bills = $selectedVendor.value?.bill_summary?.data?.edges.map(({ node }) => node) || [];
    $selectedVendor.update({ bills });
  }
}, [$selectedVendor]);
