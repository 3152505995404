import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { $toastr } from 'signals/Global.signals';
import NotificationToast from './NotificationToast';
import SyncToast from './SyncToast';

const collapseToast = (id) => {
  const currentToasts = [...$toastr.value.toasts];
  const targetIdx = currentToasts.findIndex(t => t.id === id);
  currentToasts[targetIdx].expanded = !currentToasts[targetIdx].expanded;
  $toastr.update({
    toasts: currentToasts,
  });
};

const dismissToast = (id) => {
  const currentToasts = [...$toastr.value.toasts];
  const newToasts = currentToasts.filter(t => t.id !== id);
  $toastr.update({
    toasts: newToasts,
  });
};

function Toastr() {
  return (
    <>
      <ToastContainer position="bottom-end" className="p-8 mb-16 position-fixed z-index-10">
        {$toastr.value.toasts?.map(t => (
          <Toast key={t.id} className="border-0">
            {t.type === 'sync' ? (
              <SyncToast
                id={t.id}
                expanded={t.expanded}
                collapseToast={collapseToast}
                dismissToast={dismissToast}
              />
            ) : (
              <NotificationToast
                message={t.message}
                expanded={t.expanded}
              />
            )}
          </Toast>
        ))}
      </ToastContainer>
    </>
  );
}

export default Toastr;
