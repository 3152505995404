import Loader from 'components/global/Loader/Loader';
import { $loader } from 'signals/Global.signals';

const ComponentLoader = ({ className }) => (
  <div className={`vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1 ${className || ''}`}>
    <Loader message={$loader.value?.isLoadingMessage ?? 'Loading...'} className="text-center" />
  </div>
);

export default ComponentLoader;
