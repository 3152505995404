import { Button, Col, Form, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import {
  handleOnboardingChooseAccountChange,
  performOnboardingAccountTypeSubmission,
  $onboarding,
} from 'components/views/Onboarding/Onboarding.helpers';
import signUpOption1Img from 'images/signUpOption1.svg';
import signUpOption2Img from 'images/signUpOption2.svg';

const AccountTypeForm = () => (
  <Form.Group>
    <h2 className="fw-700 text-center mb-40">Let us get to know you</h2>
    <Button
      variant="transparent"
      className={`w-100 p-0 ${$onboarding.value.accountType === 'smallBusiness' && 'active'}`}
      onClick={() => handleOnboardingChooseAccountChange('smallBusiness')}
    >
      <Row className="m-0">
        <Col xs={3} className="p-0 d-none d-xl-block">
          <Image
            src={signUpOption1Img}
            alt="Small Business Logo"
            className="h-100"
            style={{ borderRadius: '25px' }}
          />
        </Col>
        <Col xs={{ span: 7, offset: 2 }} className="my-auto p-0 text-start">
          <div className="base fw-800 text-dark">Small Business</div>
          <div className="small fw-800 text-dark">
            I will be managing invoices, statements, reminders, bills, and vendors for <strong>my company</strong>
            .
          </div>
        </Col>
      </Row>
    </Button>
    <OverlayTrigger
      key="overlay-trigger"
      placement="bottom"
      overlay={(
        <Tooltip id="tooltip-bottom" className="d-block">
          Coming Soon!
        </Tooltip>
      )}
    >
      <Button
        variant="transparent"
        className={`w-100 p-0 mt-24 ${$onboarding.value.accountType === 'accountingFirm' && 'active'}`}
        // onClick={() => handleOnboardingChooseAccountChange('accountingFirm')}
        style={{ opacity: 0.5 }}
      >
        <Row className="m-0">
          <Col xs={3} className="p-0 d-none d-xl-block">
            <Image
              src={signUpOption2Img}
              alt="Accounting Firm Logo"
              style={{ borderRadius: '25px' }}
            />
          </Col>
          <Col xs={{ span: 7, offset: 2 }} className="py-16 px-0 text-start">
            <div className="base fw-800 text-dark">Accounting firm</div>
            <div className="small fw-800 text-dark">
              I will be managing invoices, statements, reminders, bills, and vendors for{' '}
              <strong>multiple companies</strong>.
            </div>
          </Col>
        </Row>
      </Button>
    </OverlayTrigger>
    <Button
      variant="primary"
      type="submit"
      onClick={performOnboardingAccountTypeSubmission}
      className="w-100 btn-lg my-48"
      disabled={!$onboarding.value.accountType}
    >
      NEXT
    </Button>
  </Form.Group>
);

export default AccountTypeForm;
