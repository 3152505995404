import { useMemo } from 'react';

import { $settingsSubscriptionSelectedRecurringPlanOptions, $settingsSubscriptionUI } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import PlanCard from 'components/views/SettingsSubscription/_components/PlanCard';
import PlanCardFreePlan from 'components/views/SettingsSubscription/_components/PlanCardFreePlan';

const PlanList = () => {
  const { pricing, recurrence, subscriptions } = $settingsSubscriptionUI.value;
  const selectRecurringIntervalSubscriptionPlanList = $settingsSubscriptionSelectedRecurringPlanOptions.value;

  const Options = useMemo(() => {
    if (!selectRecurringIntervalSubscriptionPlanList) return [<></>];

    return selectRecurringIntervalSubscriptionPlanList.map((subscriptionPlan) => (
      <>
        <br />
        <PlanCard data={subscriptionPlan} />
      </>
    ));
  }, [selectRecurringIntervalSubscriptionPlanList]);

  if (!pricing?.options?.length || !recurrence || !subscriptions) return <></>;

  return (
    <>
      <PlanCardFreePlan />
      {Options}
    </>
  );
};

export default PlanList;
