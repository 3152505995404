import { useEffect, useCallback } from 'react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';
import ContentWrapper from 'components/global/ContentWrapper';
import ComponentLoader from 'components/global/ComponentLoader';
import noBills from 'images/noInvoices.svg';
import { fetchAndSetBillDetail } from 'components/views/Bills/Bills.helpers';
import { $bill } from 'components/views/Bills/Bills.signals';
import BillHero from 'components/views/Bills/_components/BillHero';
import BillSummary from 'components/views/Bills/_components/BillSummary';

const BillDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const handleBackToBills = useCallback(() => {
    if (history?.location?.state?.from) {
      return history.goBack();
    }
    history.replace('/bills');
  }, [history]);

  const bill = $bill?.value;
  const callbackFetchAndSetBill = useCallback(async () => {
    await fetchAndSetBillDetail(id);
  });

  useEffect(() => {
    callbackFetchAndSetBill();
  }, [id]);

  if ($bill.value?.isLoading) return <ComponentLoader />;

  if (!$bill.value?.id) {
    return (
      <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0">
        <Container fluid className="bg-gray-50 p-0 min-vh-100">
          <Row>
            <Col className="mt-80 col-md-6">
              <Image src={noBills} height={640} />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper className="min-vh-100 w-100 p-lx-auto px-8 p-0 overflow-hidden mb-56">
      <Container className="pt-lg-32 pt-16">
        <Button variant="transparent border-0 shadow-none" className="d-flex align-items-center justify-content-start position-relative p-32 ps-0" onClick={() => handleBackToBills()}>
          <FontAwesomeIcon icon={faArrowLeft} className="text-primary" />
          <p className="text-primary fw-400 ms-8 mb-0">Back to bills</p>
        </Button>
        <Row className="position-relative">
          <BillHero data={bill} />
          <BillSummary data={bill} />
        </Row>
        {/* <AboutYourBill /> */}
      </Container>
    </ContentWrapper>
  );
};

export default BillDetail;
