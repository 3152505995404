import Loadable from 'components/global/Loadable';
import Money from 'components/global/Money';
import { Card, Col, Row } from 'react-bootstrap';
import { $syncing } from 'signals/Global.signals';

const Totals = ({
  open,
  overdue,
  $signal,
}) => (
  <Row className="my-lg-48">
    <Col md={6} className="my-16 my-md-0">
      <Card className="p-16 border border-primary">
        <div className="text-primary fw-600">Open:</div>
        <h1>
          <Loadable signal={$signal} template="table" length={1}>
            <Money amount={open} hide={$syncing.value.isSyncing} />
          </Loadable>
        </h1>
      </Card>
    </Col>
    <Col md={6} className="mb-16 mb-md-0">
      <Card className="p-16 border border-primary">
        <div className="text-primary fw-600">Overdue:</div>
        <h1>
          <Loadable signal={$signal} template="table" length={1}>
            <Money amount={overdue} hide={$syncing.value.isSyncing} />
          </Loadable>
        </h1>
      </Card>
    </Col>
  </Row>
);

export default Totals;
