import Toast from 'react-bootstrap/Toast';

const NotificationToast = ({ message, expanded }) => (
  <div className={!expanded && 'd-none'}>
    <Toast.Body>
      <div className="d-flex my-3 gap-8" style={{ marginTop: '0.5rem' }}>
        <span className="text-dark pb-16">{message}</span>
      </div>
    </Toast.Body>
  </div>
);

export default NotificationToast;
