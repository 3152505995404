import TabNav from 'components/global/TabNav/TabNav';
import { $settingsPayment } from 'components/views/SettingsPayment/SettingsPayment.helpers';
import SneakPeak from './_components/SneakPeak';

const paymentTabs = [
  {
    key: 'receiving-methods',
    title: 'Receiving Methods',
    component: <SneakPeak />,
  },
  {
    key: 'payment-methods',
    title: 'Payment Methods',
    component: <SneakPeak />,
  },
  {
    key: 'auto-pay',
    title: 'Auto Pay',
    component: <SneakPeak />,
  },
  {
    key: 'accru-pay',
    title: 'Accru Pay',
    component: <SneakPeak />,
  },
];

const SettingsPayment = () => {
  const { currentTab } = $settingsPayment.value;
  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Payment Settings</h1>
      <TabNav
        tabs={paymentTabs.map(tab => ({ key: tab.key, title: tab.title }))}
        TabPaneComponents={paymentTabs.map(tab => tab.component)}
        onClick={(t) => $settingsPayment.update({ currentTab: t.key })}
        activeTab={currentTab}
        defaultActiveTab="info"
        template="underline"
      />
    </div>
  );
};

export default SettingsPayment;
