import { Col, Row, Container } from 'react-bootstrap';
import { historyPush } from 'utils/history';

const DontHaveAnAccountLink = () => (
  <Row className="d-flex align-items-center justify-content-center">
    <Col className="d-flex justify-content-center align-items-center mb-16">
      <Container className="text-center p-0 fw-400">
        Don&apos;t have an account?
        {' '}
        <button
          onClick={() => historyPush('/signup')}
          type="button"
          className="btn btn-link text-primary"
        >
          Sign Up
        </button>

      </Container>
    </Col>
  </Row>
);

export default DontHaveAnAccountLink;
