import { useEffect, useRef } from 'react';

const useOAuthCallback = ({ signal, onSuccess = () => null, onFailure = () => { } }) => {
  if (!signal.value || typeof signal.update !== 'function' || typeof signal.reset !== 'function') throw new Error('Invalid Signal!');

  const oauthWindowRef = useRef(null);

  const dispatchOAuth = (url, provider, safariWindowRef) => {
    if (!url && !signal.value.oAuthUrl) return;
    oauthWindowRef.current = safariWindowRef
      // eslint-disable-next-line no-param-reassign
      ? safariWindowRef.location = url || (signal.value.oAuthUrl, '_blank')
      : window.open(url || signal.value.oAuthUrl, '_blank');
    signal.update({
      provider,
      oAuthUrl: url || signal.value.oAuthUrl,
      oAuthCallbackUrl: null,
    });
  };

  const resetOAuth = () => {
    oauthWindowRef.current?.close();
    signal.reset();
  };

  useEffect(() => {
    let intervalId;

    if (!!signal.value.provider && !!signal.value.oAuthUrl) {
      const handleOAuthCallback = (event) => {
        if (event.source === oauthWindowRef.current) {
          clearInterval(intervalId);
          const oAuthCallbackUrl = new URL(event.data);
          oauthWindowRef.current.close();

          const error = oAuthCallbackUrl.searchParams.get('error');
          if (error) {
            signal.reset();
            onFailure();
          } else {
            signal.update({ oAuthCallbackUrl });
            onSuccess({
              provider: signal.value.provider,
              oAuthUrl: signal.value.oAuthUrl,
              oAuthCallbackUrl,
            });
          }
        }
      };

      const checkOAuthWindowClosed = () => {
        if (oauthWindowRef.current && oauthWindowRef.current.closed && !signal.value.oAuthCallbackUrl) {
          clearInterval(intervalId);
          signal.reset();
          onFailure();
        }
      };

      window.addEventListener('message', handleOAuthCallback);
      intervalId = setInterval(checkOAuthWindowClosed, 1000);

      return () => {
        window.removeEventListener('message', handleOAuthCallback);
        clearInterval(intervalId);
      };
    }
  }, [signal.value]);

  return { dispatchOAuth, resetOAuth };
};

export default useOAuthCallback;
