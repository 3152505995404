import { Image, Container, Row, Col } from 'react-bootstrap';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { $intuit } from 'signals/Authentication.signals';
import InvoicePreview from 'components/views/Invoices/_components/InvoicePreview';
import { $invoiceAdd, $invoiceForm, $invoiceManualForm } from 'components/views/InvoiceAdd/InvoiceAdd.helpers';
import arrowLeft from 'images/arrowLeft.svg';
import closeButton from 'images/closeButton.svg';
import triangleBg from 'images/yellowtrianglebackground.svg';
import squareBg from 'images/bluesquarebackground.svg';
import InvoiceMethodScreen from './_components/InvoiceMethodScreen';
import InvoiceManualAddSelect from './_components/InvoiceManualAddSelect';
import InvoiceManualForm from './_components/InvoiceManualForm';
import AddInvoiceUpload from './_components/InvoiceUpload';
import InvoiceSuccessfulSend from './_components/InvoiceSuccessfulSend';

const InvoiceAdd = () => {
  const { currentScreen, backButtonHistory } = $invoiceAdd.value;
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const name = urlParams.get('name');
  const uid = urlParams.get('uid');

  const handleBackButton = useCallback(() => {
    const navigationHistory = [...backButtonHistory];
    if (navigationHistory.length) {
      const current = navigationHistory.pop();
      $invoiceAdd.update({
        currentScreen: current,
        backButtonHistory: navigationHistory,
      });
    } else {
      history.push('/invoices');
    }
  }, [history, backButtonHistory]);

  const handleNext = useCallback(({ current, next }) => {
    const navigationHistory = [...backButtonHistory];
    navigationHistory.push(current);
    $invoiceAdd.update({
      currentScreen: next,
      backButtonHistory: navigationHistory,
    });
  }, [backButtonHistory]);

  useEffect(() => {
    $invoiceAdd.update({
      currentScreen: $intuit?.value?.redirectUrl ? 'sync' : 'method',
    });
    if (name && uid) {
      $invoiceManualForm.reset();
      $invoiceAdd.reset();
      handleNext({ current: 'method', next: 'form' });
      $invoiceManualForm.update({
        customerSearchTerm: name,
      });
      $invoiceForm.update({
        organization_customer_id: uid,
      });
    }

    return () => $invoiceAdd.update({ backButtonHistory: [] });
  }, []);

  return (
    <Container
      fluid
      className="min-vh-100 px-0 w-100 text-center position-relative"
      style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}
    >
      <Image className="position-absolute z-index-0 top-0 end-0" src={triangleBg} />
      <Image className="position-absolute bottom-0 start-0 z-index-0" src={squareBg} />
      <div className="d-flex min-vh-100 p-auto justify-content-center">
        <Container style={{ maxWidth: '1200px' }} className="z-index-1 pt-80 pt-lg-152">
          <Row>
            <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
              <Image
                src={arrowLeft}
                className="cursor-pointer"
                onClick={() => handleBackButton()}
              />
              <Image
                src={closeButton}
                className="cursor-pointer z-index-1"
                onClick={() => history.push('/invoices')}
              />
            </Col>
          </Row>
          {{
            method: <InvoiceMethodScreen handleNext={handleNext} />,
            manualSelect: <InvoiceManualAddSelect handleNext={handleNext} />,
            form: <InvoiceManualForm handleNext={handleNext} />,
            upload: <AddInvoiceUpload handleNext={handleNext} />,
            preview: <InvoicePreview handleNext={handleNext} />,
            successfulSend: <InvoiceSuccessfulSend handleNext={handleNext} />,
          }[currentScreen]}
        </Container>
      </div>
    </Container>
  );
};

export default InvoiceAdd;
