import { accruClient } from 'api';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { $settingsLogs, $settingsLogsUI } from './SettingsLogs.signals';

export const filterSync = (type) => {
  const { originalLogs } = $settingsLogs.value;
  const tempArray = [];
  originalLogs.forEach((obj) => {
    if (obj.status_description === type) {
      tempArray.push(obj);
    }
  });
  $settingsLogs.update({
    logs: type === 'NONE' ? originalLogs : tempArray,
  });
};

export const sortFlip = () => {
  const { logs } = $settingsLogs.value;

  $settingsLogs.update({
    logs: logs.slice().reverse(),
  });
};

export const fetchAndSetLogData = async () => {
  try {
    $user.loadingStart();
    $settingsLogs.loadingStart();

    const { perPage, page } = $settingsLogsUI.value;
    const response = await accruClient.accountingProviders.getSync({
      organizationId: $user.value.currentOrganization.id,
      skip: (page - 1) * perPage,
      take: perPage,
    });

    if (response) {
      $settingsLogs.update({
        logs: response.items,
        originalLogs: response.items,
        totalCount: response.totalCount,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $user.loadingEnd();
    $settingsLogs.loadingEnd();
  }
};
