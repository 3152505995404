import { handleNotification } from 'components/global/Alert/Alert';
import { acceptConnection, getAllConnections, inviteCustomer, inviteVendor, rejectConnection } from 'components/helpers/Connection.helpers';
import Signal from 'signals/Signal';
import $user from 'signals/User.signals';

export const $settingsConnections = Signal({
  connections: [],

  status: null,
});

const connectionObjMap = ({ currentSide }) => {
  const otherSide = currentSide === 'vendor' ? 'customer' : 'vendor';
  return {
    currentSide: {
      conn_status: `${currentSide}_conn_status`,
      conn_status_at: `${currentSide}_conn_status_at`,
      organization_id: `${currentSide}_organization_id`,
      organization_name: `${currentSide}_organization_name`,
      organization_email: `${currentSide}_organization_email`,
      target_id: `${currentSide}_target_${otherSide}_id`,
      target_name: `${currentSide}_target_${otherSide}_name`,
      target_email: `${currentSide}_target_${otherSide}_email`,
    },
    otherSide: {
      conn_status: `${otherSide}_conn_status`,
      conn_status_at: `${otherSide}_conn_status_at`,
      organization_id: `${otherSide}_organization_id`,
      organization_name: `${otherSide}_organization_name`,
      organization_email: `${otherSide}_organization_email`,
      target_id: `${otherSide}_target_${currentSide}_id`,
      target_name: `${otherSide}_target_${currentSide}_name`,
      target_email: `${otherSide}_target_${currentSide}_email`,
    },
  };
};

const processConnection = ({ connection }) => {
  const organization = $user.value.currentOrganization;

  const isVendorSide = !!organization.id && !!organization.email && (connection.vendor_organization_id === organization.id || connection.customer_target_vendor_email === organization.email);
  const isCustomerSide = !!organization.id && !!organization.email && (connection.customer_organization_id === organization.id || connection.vendor_target_customer_email === organization.email);

  // eslint-disable-next-line no-nested-ternary
  const currentSide = isVendorSide ? 'vendor' : isCustomerSide ? 'customer' : null;
  const keyMapping = connectionObjMap({ currentSide });

  return {
    ...connection,

    is_valid: connection.is_valid && !!currentSide,

    _connectionKeyMapping: keyMapping,

    _currentSide: currentSide,
    _otherSide: currentSide === 'vendor' ? 'customer' : 'vendor',

    _currentSideData: {
      ...Object.entries(keyMapping.currentSide).reduce((acc, [key, tKey]) => { acc[key] = connection[tKey]; return acc; }, {}),
      name: connection[keyMapping.currentSide.organization_name] || connection[keyMapping.otherSide.target_name],
    },
    _otherSideData: {
      ...Object.entries(keyMapping.otherSide).reduce((acc, [key, tKey]) => { acc[key] = connection[tKey]; return acc; }, {}),
      name: connection[keyMapping.otherSide.organization_name] || connection[keyMapping.currentSide.target_name],
    },
  };
};

export const fetchAndSetConnectionsData = async () => {
  try {
    $user.loadingStart();
    $settingsConnections.loadingStart();

    const data = await getAllConnections({
      status: $settingsConnections.value.status,
    });

    $settingsConnections.update({
      ...data,
      connections: data.edges.map(edge => processConnection({ connection: edge.node })),
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $user.loadingEnd();
    $settingsConnections.loadingEnd();
  }
};

export const updateSingleSignalConnection = ({ connection }) => {
  if (!connection || !$settingsConnections.value.connections) return;

  const data = processConnection({ connection });
  const updatedConnections = [...$settingsConnections.value.connections];
  const connIndex = updatedConnections.findIndex((conn) => conn.id === data.id);

  if (connIndex === -1) updatedConnections.push({ connection: data });

  updatedConnections[connIndex] = data;

  $settingsConnections.update({ connections: updatedConnections });
};

export const handleConnAccept = async ({ organizationConnectionId }) => {
  try {
    $settingsConnections.loadingStart();
    const data = await acceptConnection({ organizationConnectionId });
    updateSingleSignalConnection({ connection: data });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsConnections.loadingEnd();
  }
};

export const handleConnReject = async ({ organizationConnectionId }) => {
  try {
    $settingsConnections.loadingStart();
    const data = await rejectConnection({ organizationConnectionId });
    updateSingleSignalConnection({ connection: data });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsConnections.loadingEnd();
  }
};

export const handleInvite = async ({ type, targetId }) => {
  try {
    $settingsConnections.loadingStart();
    if (type === 'vendor') {
      const data = await inviteVendor({ organizationVendorId: targetId });
      updateSingleSignalConnection({ connection: data });
    } else {
      const data = await inviteCustomer({ organizationCustomerId: targetId });
      updateSingleSignalConnection({ connection: data });
    }

    handleNotification('Invitation sent successfully', { variant: 'success' });
  } catch (error) {
    handleNotification(error);
  } finally {
    $settingsConnections.loadingEnd();
  }
};
