import StatusString from 'components/global/SidePanel/_components/StatusString';
import { Card, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Money from 'components/global/Money';
import { $sidePanelBillsDetails, fetchAndSetSidePanelBills, handleClickBillRow } from 'components/global/SidePanel/SidePanel.helpers';
import { $syncing } from 'signals/Global.signals';
import Loader from 'components/global/Loader';
import { useEffect } from 'react';

const SidePanelBills = ({ type }) => {
  const { bills, isLoading } = $sidePanelBillsDetails.value;

  useEffect(() => {
    fetchAndSetSidePanelBills({ type });
  }, []);

  if (isLoading || !$sidePanelBillsDetails.value.bills) {
    return <Loader className="d-flex align-items-center justify-content-center my-40" />;
  }

  return (
    <Card>
      <Row className="d-none d-md-flex mb-8 ps-56 py-32 bg-gray-50 d-flex justify-content-between base fw-800">
        <Col><div>{type === 'vendor' ? 'Bill Date' : 'Invoice Date'}</div></Col>
        <Col><div>Due Date</div></Col>
        <Col><div>Number</div></Col>
        <Col><div>Status</div></Col>
        <Col><div>Amount</div></Col>
      </Row>
      {$syncing.value.isSyncing ? (
        <div>
          <div className="h-100 mt-40 w-100 d-flex justify-content-center align-items-center flex-grow-1">
            <Loader message="Please wait for the current sync to finish." className="text-center" />
          </div>
        </div>
      ) : (
        <>
          {(bills ?? []).map((bill, i) => (
            <div key={bill.id}>
              <Row
                onClick={() => handleClickBillRow({ bill, type })}
                className={`d-none d-lg-flex d-flex justify-content-between base py-32 ps-56 ${i % 2 !== 0 && 'bg-gray-50'} cursor-pointer row-hover-effect`}
              >
                <Col>{moment(type === 'vendor' ? bill.bill_date : bill.invoice_date).format('l')}</Col>
                <Col>{moment(bill.due_date).format('l')}</Col>
                <Col>{bill.number}</Col>
                <Col><StatusString bill={bill} /></Col>
                <Col><Money amount={bill.total_amount} /></Col>
              </Row>
              <Row className={`d-lg-none base py-32 px-16 ${i % 2 !== 0 && 'bg-gray-50'}`}>
                <Col>
                  <h3 className="mb-0"><Money amount={bill.total_amount} /></h3>
                  <StatusString bill={bill} />
                </Col>
                <Col>
                  <div className="d-flex justify-content-between"><small>Invoice: </small>{moment(type === 'vendor' ? bill.bill_date : bill.invoice_date).format('l')}</div>
                  <div className="d-flex justify-content-between"><small>Due Date: </small>{moment(bill.due_date).format('l')}</div>
                </Col>
              </Row>
            </div>
          ))}
        </>
      )}
      {!bills?.length && (
        <>
          <Row className="base py-32 text-center justify-content-center text-dark">
            {type === 'vendor' ? 'No Bills found' : 'No Invoices Found'}
          </Row>
        </>
      )}
    </Card>
  );
};

export default SidePanelBills;
