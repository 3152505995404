import { Col, Form, Row } from 'react-bootstrap';
import { statusOptions } from 'utils/selectArrays';
import DateRangePicker from 'components/global/Inputs/DateRangePicker/DateRangePicker';
import { $invoicesCustomerOptions, $invoicesFilter } from 'components/views/Invoices/Invoices.signals';
import SimpleSelect from 'components/global/Inputs/SimpleSelect/SimpleSelect';
import { useEffect } from 'react';
import { handleNotification } from 'components/global/Alert/Alert';

const InvoiceFilters = ({ organizationCustomerId }) => {
  useEffect(() => {
    $invoicesFilter.update({ organizationCustomerId });
  }, [organizationCustomerId]);

  return (
    <Row>
      <Col>
        <Form.Group>
          <Form.Label>Customer</Form.Label>
          <SimpleSelect
            value={$invoicesFilter.value.organizationCustomerId}
            options={$invoicesCustomerOptions.value.options}
            onChange={(selectedOption) => {
              if (!selectedOption?.value) {
                $invoicesFilter.update({ organizationCustomerId: null });
              } else {
                $invoicesFilter.update({ organizationCustomerId: selectedOption.value });
              }
            }}
            isClearable
            isLoading={$invoicesCustomerOptions.value.isLoading}
            isDisabled={$invoicesCustomerOptions.value.isLoading}
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Label>Status</Form.Label>
        <SimpleSelect
          value={$invoicesFilter.value.status}
          options={statusOptions}
          onChange={(selectedOption) => {
            if (!selectedOption?.value) {
              return $invoicesFilter.update({ status: null });
            }
            $invoicesFilter.update({ status: selectedOption.value });
          }}
        />
      </Col>
      <Col className="pt-16 pt-md-0">
        <Form>
          <Form.Group>
            <Form.Label>Start Date - End Date</Form.Label>
            <DateRangePicker
              onChange={({ value, name }) => {
                if ((name === 'endDate' && value && $invoicesFilter.value.startDate && value < $invoicesFilter.value.startDate) ||
                  (name === 'startDate' && value && $invoicesFilter.value.endDate && value > $invoicesFilter.value.endDate)) {
                  handleNotification('Start Date must be before end date');
                  return;
                }

                $invoicesFilter.update({ [name]: value });
              }}
              variant="form-control-border"
              startName="startDate"
              endName="endDate"
              startValue={$invoicesFilter.value.startDate}
              endValue={$invoicesFilter.value.endDate}
            />
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
};

export default InvoiceFilters;
