import { Card, Row, Col, Image, Placeholder } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Money from 'components/global/Money';
import seeBalanceIcon from 'images/seeBalanceIcon.svg';
import folderImg from 'images/folder.svg';
import creditCardImg from 'images/creditCard.svg';
import { $syncing } from 'signals/Global.signals';

const CustomerCard = ({ item, active, onSelect, handleClickInvoices, handleClickProjects }) => {
  const history = useHistory();
  return (
    <Card
      onClick={() => onSelect(item)}
      className={`border bg-none rounded-4 w-100 p-8 py-md-24 mb-16 mb-md-24 cursor-pointer ${active ? 'border-2 border-info' : 'row-hover-effect'}`}
    >
      <Row className="align-items-center m-0">
        <Col xs={2} sm={2} className="d-flex align-items-center p-0">
          <div className="rounded-circle border border-primary position-relative d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '45px', width: '45px' }}>
            <h2 className="fw-700 mb-0 text-primary p-0">{item?.name?.charAt(0).toUpperCase()}</h2>
          </div>
        </Col>
        <Col xs={10} sm={4} className="pe-16 ps-0">
          <h3 className="ms-8 ms-md-0 mb-0">{item.name}</h3>
          <small>{item.primary_contact_name}</small>
        </Col>
        <Col xs={7} sm={3} className="ps-24 p-md-0 mt-8 p-0">
          <div className="small fw-800">Open Balance</div>
          {$syncing.value.isSyncing ? (
            <Placeholder as="div" animation="glow" className="mb-0">
              <Placeholder xs={12} as="p" className="text-loadable" />
            </Placeholder>
          ) : (
            <div className="lead fw-800 text-primary"><Money amount={(item.latest_acct_provider_balance !== null ? item.latest_acct_provider_balance : item.invoice_summary?.balance)} /></div>
          )}
        </Col>
        <Col xs={5} sm={3} className="mt-8 p-0 ps-16">
          <div className="d-flex justify-content-between align-items-center">
            {$syncing.value.isSyncing ? (
              <Image height={26} src={seeBalanceIcon} className="opacity-50" />
            ) : (
              <Image height={26} src={seeBalanceIcon} onClick={() => history.push(`/customers/statement/${item.id}`, { from: history.location })} />
            )}
            <Image
              height={22}
              src={folderImg}
              onClick={(event) => {
                event.stopPropagation();
                handleClickProjects(item);
              }}
            />
            <Image
              height={25}
              src={creditCardImg}
              onClick={(event) => {
                event.stopPropagation();
                handleClickInvoices(item);
              }}
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CustomerCard;
