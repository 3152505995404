import { versionArray } from 'components/views/MVP/MVP.helpers';
import { Button, Form } from 'react-bootstrap';
import { $global } from 'signals/Global.signals';

/*

  HOW TO USE
  ----------
  We use this component ONLY on QA so we can look at upcoming features that we are starting to work on early for QA.
    - You can update the prod version inside of $global signal when we pass mvp
    - You can use isProdVersion() to see if it aligns with PROD version
    - You can use isProdVersion(2) WITH a custom number will only show it for teh version number passed in

*/

function handleHideMVPToggle() {
  window.localStorage.setItem('hideMVPToggle', 1);
  window.location.reload();
}

export const MVP = () => (
  <div className={`version-nav row px-16 ${window.localStorage.getItem('hideMVPToggle') === '1' ? 'd-none' : ''}`}>
    You are currently using Version #
    <Form.Select
      className="form-select bg-white w-auto py-0 bg-none border-0"
      onChange={(e) => {
        localStorage.setItem('versionNumber', e.target.value);
        $global.update({
          versionNumber: e.target.value,
        });
      }}
      name="versionNumber"
      id="versionNumber"
      value={$global.valueversionNumber}
    >
      {versionArray && versionArray.map((obj, idx) => (
        <option key={`versionNumber-${idx}`} value={obj.value} disabled={obj.disabled}>{obj.label}</option>
      ))}
    </Form.Select>
    <div className="pull-left p-0">
      <Button size="xs" variant="link" className="p-0" onClick={handleHideMVPToggle}>Hide</Button>
    </div>
  </div>
);

export default MVP;
