import { useHistory } from 'react-router-dom';
import { Button, Card, Col, Container, Image, Row, Tab, Tabs } from 'react-bootstrap';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { $activeForm } from 'components/views/Statement/Old/Statement.helpers';
import paymentBackgroundImg from 'images/paymentBackground.svg';
import littleThingsStandingImg from 'images/littleThingsStanding.svg';
import CardInfo from './forms/CardInfo';
import ChoosePayment from './forms/ChoosePayment';
import ChoosePaymentReturning from './forms/ChoosePaymentReturning';
import BankInfo from './forms/BankInfo';
import CheckSentInfo from './forms/CheckSentInfo';
import Processing from './forms/Processing';
import PaymentConfirmed from './forms/PaymentConfirmed';
import AutopayContainer from './autopay/AutopayContainer';

const PayType = ({ className }) => {
  const history = useHistory();
  const resetTabsAndExit = () => {
    if (history?.location?.state?.from) {
      return history.goBack();
    }
    history.push('/statement');
  };

  const { value: currentScreen } = $activeForm;

  return (
    <Container fluid className={className} style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)', backgroundImage: `url(${paymentBackgroundImg})`, backgroundSize: 'cover' }}>
      <Button
        variant="outline-primary"
        className="position-relative mt-80 ms-80 border-0"
        onClick={() => (resetTabsAndExit())}
      >
        {currentScreen === 'Processing' || currentScreen === 'PaymentConfirmed' ? (
          ''
        ) : (
          <>
            <FontAwesomeIcon icon={faArrowLeft} className="me-16" />
            {`Back to ${history?.location?.state?.from?.pathname?.replace('/', '') || 'Statements'}`}
          </>
        )}
      </Button>
      {currentScreen === 'PaymentConfirmed' && (
        <Image src={littleThingsStandingImg} className="position-absolute d-lg-flex justify-content-center align-items-center w-100 object-fit-cover pt-80 d-md-none d-sm-none d-xs-none " />
      )}
      <Container className="p-0 position-relative mt-40">
        <Row className="h-100 d-flex align-items-center">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
            <Card className={`border-0 mb-40 mx-lg-16 mx-md-0 mx-sm-0 ${currentScreen === 'Autopay' && 'bg-transparent'}`}>
              <Tabs activeKey={currentScreen} className="border-0 d-none">
                <Tab eventKey="ChoosePayment" title="ChoosePayment">
                  <ChoosePayment
                    className="text-center"
                  />
                </Tab>
                <Tab eventKey="ChoosePaymentReturning" title="ChoosePaymentReturning">
                  <ChoosePaymentReturning
                    className="text-center"
                  />
                </Tab>
                <Tab eventKey="CardInfo" title="CardInfo">
                  <CardInfo className="text-center" />
                </Tab>
                <Tab eventKey="Processing" title="Processing">
                  {currentScreen === 'Processing' && (
                    <Processing className="text-center" />
                  )}
                </Tab>
                <Tab eventKey="PaymentConfirmed" title="PaymentConfirmed">
                  <PaymentConfirmed className="text-center" />
                </Tab>
                <Tab eventKey="BankInfo" title="BankInfo">
                  <BankInfo className="text-center" />
                </Tab>
                <Tab eventKey="CheckSentInfo" title="CheckSentInfo">
                  <CheckSentInfo className="text-center" />
                </Tab>
                <Tab eventKey="Autopay" title="Autopay" className="bg-transparent">
                  <AutopayContainer className="text-center" />
                </Tab>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default PayType;
