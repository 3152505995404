import Loadable from 'components/global/Loadable';
import Money from 'components/global/Money';
import { $detailStatement } from 'components/views/Statement/statement.signals';

const Welcome = () => (
  <>
    <h4 className="text-primary mb-0">Hello {$detailStatement.value.customer.name},</h4>
    <h1 className="mb-24">Welcome to your <span className="fw-700">Statement!</span></h1>
    <p className="lead fw-400 mb-24"><span className="fw-700">{$detailStatement.value.vendor.name}</span> uses <a href="https://accru.co" target="_blank" className="text-black" rel="noreferrer">Accru</a> to make it easy for you to track bills and make payments.<br /> We hope you enjoy. <a href="https://accru.co" target="_blank" className="text-primary" rel="noreferrer">Learn more about Accru</a></p>
    <h2>Total Balance</h2>
    <div className="w-25">
      <Loadable signal={$detailStatement} template="table" length={1}>
        <h1><Money amount={$detailStatement.value.totalBalance} /></h1>
      </Loadable>
    </div>
  </>
);

export default Welcome;
