import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import isForbiddenOrganizationUser from 'utils/isForbiddenOrganizationUser';

const NoAccessTooltip = ({ children }) => {
  const [showPopover, setShowPopover] = useState(false);

  const handleMouseEnter = () => setShowPopover(true);
  const handleMouseLeave = () => setShowPopover(false);
  if (isForbiddenOrganizationUser()) {
    return (
      <OverlayTrigger
        key="overlay-trigger"
        placement="bottom"
        trigger={['hover', 'focus']}
        show={showPopover}
        onToggle={(nextShow) => setShowPopover(nextShow)}
        overlay={(
          <Popover
            id="popover-basic"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Popover.Header as="h4"><strong>Upgrade to Access</strong></Popover.Header>
            <Popover.Body as="p" className="m-0">
              Unlock this feature by upgrading your account.
              <br />
              <Link to="/settings/subscription">Click to learn more</Link>
            </Popover.Body>
          </Popover>
      )}
      >
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {children}
        </div>
      </OverlayTrigger>
    );
  }
  return (
    children
  );
};

export default NoAccessTooltip;
