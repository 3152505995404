import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $user from 'signals/User.signals';

const SelectAdminOrganizationCard = ({ organization, onSelect, isSelected, ...rest }) => (
  <Card
    onClick={() => {
      onSelect(organization);
    }}
    className={`border bg-gray-50 rounded-4 py-16 px-32 cursor-pointer mb-16 ${isSelected ? 'border-1 border-info' : 'row-hover-effect'}`}
    {...rest}
  >
    <Row className="align-items-center m-0">
      <Col className="p-0">
        <h5 className="text-dark-900 fw-700">{organization.name}</h5>
      </Col>
    </Row>
    <Row className="align-items-center m-0">
      {isSelected ? (
        <Col className="p-0 align-items-center">
          <Form.Check
            checked
            readOnly
            inline
            className="m-0 p-0 border-0"
          />
          <span className="m-0 p-0 base fw-500 text-dark ms-8">{$user.value.user?.organizations?.find(orgUser => orgUser.organization_id === organization.id) ? 'Currently signed in' : 'Currently Impersonating'}</span>
        </Col>
      ) : (
        <Col className="p-0">
          <Button
            variant="link"
            className="p-0 m-0 border-0 text-decoration-none"
          >
            <span className="fw-500 base text-decoration-underline text-primary">Start impersonation</span> <FontAwesomeIcon className="text-primary" icon={faArrowRight} />
          </Button>
        </Col>
      )}
    </Row>
  </Card>
);

export default SelectAdminOrganizationCard;
