import Money from 'components/global/Money';
import { $selectedVendor, $vendorsUI } from 'components/views/Vendors/_helpers/Vendors.signals';
import VendorDetailTabs from 'components/views/Vendors/_components/VendorDetailTabs';
import { Col, Container, Row } from 'react-bootstrap';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

export const VendorDetailModal = () => (
  <SimpleModal
    isHideSave
    isHideCancel
    isShow={$vendorsUI.value.isMobileModalOpen}
    onClose={() => $vendorsUI.update({ isMobileModalOpen: false })}
    size="lg"
    title={$selectedVendor.value?.name}
  >
    {$selectedVendor.value && (
    <Container>
      <Row className="py-16 my-16 bg-gray-50" style={{ borderRadius: '15px' }}>
        <Col className="d-flex justify-content-between align-items-center px-24">
          <div>
            <p className="lead fw-800 mb-0">Overdue</p>
            <h3><Money amount={$selectedVendor.value?.node?.bill_summary.overdue_amount} /></h3>
          </div>
          {/* <div>
                      <Button variant="primary" onClick={() => history.push('/payment', { from: history.location })} className="px-32">PAY BILLS</Button>
                    </div> */}
        </Col>
      </Row>
      <Row className="d-flex align-items-center">
        <Col className="bg-white px-0">
          <VendorDetailTabs />
        </Col>
      </Row>
    </Container>
    )}
  </SimpleModal>
);

export default VendorDetailModal;
