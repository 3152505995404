/* eslint-disable no-unreachable */
import { isImpersonating } from 'components/helpers/User.helpers';
import $user from 'signals/User.signals';

const isForbiddenOrganizationUser = () => {
  const { currentOrganization } = $user.value;

  return currentOrganization?.id && !currentOrganization?.organizationUser?.is_current_organization_user_seat_available && !isImpersonating.value;
};

export default isForbiddenOrganizationUser;
