import { Button, Col, Form, Row } from 'react-bootstrap';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import {
  performOnboardingPersonalInfoSubmission,
  $onboarding,
} from 'components/views/Onboarding/Onboarding.helpers';

const PersonalInfoForm = () => {
  const { firstName, lastName } = $onboarding.value;

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-40">Please tell us a little about yourself</h2>
      <Row>
        <Col xs={6}>
          <Form.Label htmlFor="FirstName"><strong>First Name</strong></Form.Label>
          <UniversalInput
            value={firstName}
            name="firstName"
            className="mb-40"
            signal={$onboarding}
          />
        </Col>
        <Col xs={6}>
          <Form.Label htmlFor="Last Name"><strong>Last Name</strong></Form.Label>
          <UniversalInput
            value={lastName}
            name="lastName"
            className="mb-0"
            signal={$onboarding}
          />
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        onClick={() => performOnboardingPersonalInfoSubmission()}
        className="w-100 btn-lg mb-48"
        disabled={!firstName?.length || !lastName?.length}
      >
        NEXT
      </Button>
    </Form.Group>
  );
};

export default PersonalInfoForm;
