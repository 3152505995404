import { Row, Col, Form, Button } from 'react-bootstrap';

const AutopayACH = ({ handleNext }) => (
  <>
    <Row>
      <Col>
        <h2 className="mx-24 mb-56 fw-700 text-dark">Bank Info</h2>
      </Col>
    </Row>
    <div className="d-flex flex-column">
      <Form className="mb-0 px-32">
        <Form.Group className="form-group mb-24 mt-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="routingNumber">
            <p className="lead fw-800 mb-0">Routing number</p>
          </Form.Label>
          {/* TODO */}
          <Form.Control
            type="text"
            id="routingNumber"
            placeholder="TODO"
            name="routingNumber"
            className="mt-8 mb-32"
          />
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="accountNumber"><p className="lead fw-800 mb-0">Account number</p></Form.Label>
          {/* TODO */}
          <Form.Control
            type="text"
            id="accountNumber"
            placeholder="####-####-####-####"
            name="accountNumber"
            className="mt-8"
          />
        </Form.Group>
      </Form>
      <Row className="d-flex justify-content-start align-content-center mt-56 mb-32 px-32">
        <Col className="d-flex justify-content-start align-items-center">
          <Button
            variant="primary"
            className="flex-grow-1 px-40 px-16 py-8"
            onClick={() => handleNext({ current: 'ach', next: 'schedule' })}
          >
            <small className="text-primary me-8">
              NEXT
            </small>
          </Button>
        </Col>
      </Row>
    </div>
  </>
);

export default AutopayACH;
