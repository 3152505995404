import { $global } from 'signals/Global.signals';

export const checkAndSetVersionNumber = () => {
  const versionNumber = JSON.parse(localStorage.getItem('versionNumber'));
  if (versionNumber === null) {
    localStorage.setItem('versionNumber', 1);
  } else if (versionNumber !== $global.value.versionNumber) {
    localStorage.setItem('versionNumber', versionNumber);
    $global.update({
      versionNumber,
    });
  }
};

export const isProdVersion = (customVersionNumber) => {
  if (!customVersionNumber && $global.value.versionNumber <= $global.value.prodVersion) {
    return true;
  }
  if (customVersionNumber && $global.value.versionNumber === customVersionNumber) {
    return true;
  }
  return false;
};

export const versionArray = [
  { value: 1, label: '1 (MVP)', disabled: false },
  { value: 2, label: '2', disabled: false },
];
