import Loadable from 'components/global/Loadable';
import { handleSelectSearchItem } from 'components/views/Vendors/_helpers/Vendors.helpers';
import { $vendorsAutocomplete, $vendorsSearchCache, $vendorsUI } from 'components/views/Vendors/_helpers/Vendors.signals';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';

const VendorsAutoComplete = () => (
  <>
    {$vendorsUI.value.isSearchListOpen && (
      <Card className="border shadow py-16 position-absolute w-100" style={{ zIndex: 1000 }}>
        <Loadable signal={$vendorsSearchCache} template="spinner">
          <ListGroup as="ul">
            {$vendorsAutocomplete.value.map(({ name: vendorName, id }, idx) => (
              <ListGroupItem
                as="a"
                action
                key={idx.toString()}
                onClick={() => handleSelectSearchItem({ id })}
                className="border-0 selectable"
              >
                {vendorName}
              </ListGroupItem>
            ))}
            {!$vendorsAutocomplete.value.length && (
              <ListGroupItem className="border-0 text-dark fst-italic py-0">
                No results
              </ListGroupItem>
            )}
          </ListGroup>
        </Loadable>
      </Card>
    )}
  </>
);

export default VendorsAutoComplete;
