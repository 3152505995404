import Signal from 'signals/Signal';

export const $authToken = Signal('');

export const $createPassword = Signal({
  password: '',
  confirmPassword: '',
  submitting: false,
  currentForm: 'password',
  firstName: '',
  lastName: '',
  phoneNumber: '',
});

export const $intuit = Signal({
  redirectUrl: '',
});

export const $passwordResetForm = Signal({
  email: '',
});

export const $signUpEmbed = Signal({
  email: '',
  password: '',
  confirmPassword: '',
  verificationCode: ['', '', '', '', '', ''],
});

export const $signUpEmbedFunctionality = Signal({
  currentPage: 'emailForm',
});
