import { Container, Image, Row } from 'react-bootstrap';
import loginLogo from 'images/loginLogo.svg';
import SignUpForm from 'components/views/Auth/_components/SignUpForm';

const SignUpEmbed = () => (
  <Container fluid className="min-vh-100 px-0 w-100 overflow-hidden">
    <Row className="m-24">
      <Image src={loginLogo} alt="Two People Reading" className="mx-auto my-24" height="100vh" />
      <SignUpForm />
    </Row>

  </Container>
);

export default SignUpEmbed;
