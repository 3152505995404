import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import history from 'utils/history';
import containsLetter from 'utils/containsLetter';
import containsNumber from 'utils/containsNumber';
import Signal from 'signals/Signal';
import logEvent from 'utils/logEvent';

export const $resetPassword = Signal({
  password: '',
  confirmPassword: '',
  submitting: false,
  passwordVisible: false,
});

export const handleFormChange = (e) => $resetPassword.update({ [e.target.name]: e.target.value });

const MIN_PASSWORD_LENGTH = 12;
const MAX_PASSWORD_LENGTH = 32;

// verificationCode is the token that's on the callback URL
export const handleResetPasswordPartial = async (email, verificationCode, userId) => {
  try {
    $resetPassword.loadingStart();
    const { password, confirmPassword } = $resetPassword.value;
    if (!containsLetter(password)) {
      return handleNotification('Missing at least 1 alphabet character');
    }

    if (!containsNumber(password)) {
      return handleNotification('Missing at least 1 numeric character');
    }

    if (password < MIN_PASSWORD_LENGTH) {
      return handleNotification(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
    }

    if (password > MAX_PASSWORD_LENGTH) {
      return handleNotification(`Password must be at least ${MIN_PASSWORD_LENGTH} characters long`);
    }

    if (password !== confirmPassword) {
      return handleNotification('Confirmation value does not match password.');
    }

    $resetPassword.update({ submitting: true });

    await accruClient.auth.finishResetPassword({
      userPasswordResetFinishData2: {
        email,
        new_password: password,
        verification_code: verificationCode,
        user_id: userId,
      },
    });
    history.replace('/login');
    $resetPassword.reset();
    logEvent({ name: 'reset_password_success' });
    handleNotification('Your password has been successfully reset. Please log back in', { variant: 'success' });
  } catch (error) {
    handleNotification(error);
  } finally {
    $resetPassword.update({ submitting: false });
    $resetPassword.loadingEnd();
  }
};
