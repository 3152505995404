import $user from 'signals/User.signals';
import { analytics, firestore } from './firebase';

if (process.env.NODE_ENV === 'development') {
  firestore.useEmulator('localhost', 8080);
}

// ALL TRIGGERED EVENTS SHOULD BE LISTED HERE
/*
  onboarding_complete
  first_sent_invoice
  first_sync
*/

const logEvent = ({
  name, metadata, isTrigger = false,
}) => {
  if (process.env.NODE_ENV !== 'development') {
    try {
      if (isTrigger && $user.value?.user.email) {
        firestore.collection(name).doc($user.value?.user.email).set({
          name,
          metadata,
          user: $user.value || {},
        });
      }

      analytics.logEvent(name, metadata);
    } catch (e) {
      console.info(`Cannot log event ${name}: ${e}`);
    }
  }
};

export const setAnalyticsUserProperty = (property) => {
  try {
    if (process.env.NODE_ENV !== 'development') {
      analytics.setUserProperties(property);
    }
  } catch (e) {
    console.info(`Cannot set analytics user property ${JSON.stringify(property)}: ${e}`);
  }
};

export default logEvent;
