import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import { AuthLayout } from 'components/global/Layout';
import { $acceptInviteDetail } from 'components/views/Auth/_shared/Auth.helpers.js';
import LoginForm from 'components/views/Auth/_components/LoginForm';
import WrongAccount from 'components/views/Auth/AcceptInvite/_components/WrongAccount';
import { $global } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import Loader from 'components/global/Loader';
import InviteAccepted from 'components/views/Auth/AcceptInvite/_components/InviteAccepted';
import ViewInvitation from './_components/ViewInvitation';
import { fetchAndSetInviteData, INVITE_STEPS } from './AcceptInvite.helpers';

const AcceptInviteWrapper = ({ children }) => (
  <AuthLayout>
    {children}
  </AuthLayout>
);

const AcceptInvite = () => {
  const location = useLocation();

  const { isSignedIn } = $global.value;

  useEffect(() => {
    fetchAndSetInviteData(location);
  }, []);

  if ($acceptInviteDetail.value.isLoading) {
    return (
      <AcceptInviteWrapper>
        <Loader className="d-flex align-items-center justify-content-center my-40" />
      </AcceptInviteWrapper>
    );
  }

  if (isSignedIn) {
    if ($acceptInviteDetail.value.isAccepted) {
      return (
        <AcceptInviteWrapper>
          <InviteAccepted />
        </AcceptInviteWrapper>
      );
    }

    if ($user.value.user?.email !== $acceptInviteDetail.value?.email) {
      return (
        <AcceptInviteWrapper>
          <WrongAccount />
        </AcceptInviteWrapper>
      );
    }

    if ($user.value.user?.email === $acceptInviteDetail.value?.email) {
      return (
        <AcceptInviteWrapper>
          <ViewInvitation />
        </AcceptInviteWrapper>
      );
    }
  }

  return (
    <AcceptInviteWrapper>
      <Tabs activeKey={$acceptInviteDetail.value.step} className="border-0 d-none">
        <Tab eventKey={INVITE_STEPS.INVITE_INFO} title="Invite Info">
          <ViewInvitation />
        </Tab>
        <Tab eventKey={INVITE_STEPS.SIGN_IN} title="Create Password">
          <LoginForm />
        </Tab>
      </Tabs>
    </AcceptInviteWrapper>
  );
};

export default AcceptInvite;
