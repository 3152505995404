import { accruClient } from 'api';
import { handleNotification } from 'components/global/Alert/Alert';
import { $global } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import Signal from 'signals/Signal';
import { fetchAndSetUserData } from 'components/views/Auth/_shared/Auth.helpers';
import logEvent from 'utils/logEvent';
import { effect } from '@preact/signals-react';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';
import { isOnboardingOrganizationRequired, isOnboardingUserRequired } from 'components/helpers/User.helpers';

export const ONBOARDING_STEPS = {
  ACCOUNT_TYPE: 'accountType',
  PERSONAL_INFO: 'personalInfo',
  COMPANY_INFO: 'companyInfo',
  INVITATIONS: 'invitations',
  COMPLETE: 'complete',
};

export const $onboarding = Signal({
  accountType: null,
  companyIndustry: '',
  companyName: '',
  firstName: '',
  lastName: '',
  numberOfPeople: 'Select',
  phoneNumber: '',
  formattedPhoneNumber: '',
  organizationId: '',
  step: ONBOARDING_STEPS.ACCOUNT_TYPE,
  invitedUser: false,
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
});

effect(() => logEvent(`onboarding_${$onboarding.value.step}`));

export const $invitations = Signal({
  members: [
    {
      email: '',
      error: null,
      role: 'Select',
      succeeded: false,
    },
  ],
});

export const $setUpPersonalInfo = Signal({
  firstName: '',
  lastName: '',
  phoneNumber: '',
});

export const handleOnboardingChooseAccountChange = (accountType) => $onboarding.update({ accountType });
export const handleOnboardingFormChange = (e) => $onboarding.update({ [e.target.name]: e.target.value });

export const fetchOnboardingOrganizationInvites = async () => {
  try {
    $invitations.loadingStart();

    const response = await accruClient.organizations.getCollaboratorInvites({
      organizationId: $user.value.currentOrganization?.id,
    });

    return response.items;
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $invitations.loadingEnd();
  }
};

export const initOnboarding = async () => {
  try {
    $onboarding.loadingStart();

    const { step } = $onboarding.value;
    const { currentOrganization } = $user.value;

    if (isOnboardingUserRequired.value && !isOnboardingOrganizationRequired.value) {
      $onboarding.update({ step: ONBOARDING_STEPS.PERSONAL_INFO });
    } else if (!isOnboardingOrganizationRequired.value) {
      $onboarding.update({ step: ONBOARDING_STEPS.COMPLETE });
    }

    if (step !== ONBOARDING_STEPS.COMPLETE &&
      !!currentOrganization.id &&
      !currentOrganization.subscriptionLevel) {
      $onboarding.update({ step: ONBOARDING_STEPS.COMPLETE });
    }
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $onboarding.loadingEnd();
  }
};

export const performOnboardingAccountTypeSubmission = (e) => {
  e.preventDefault();
  return $onboarding.update({ step: ONBOARDING_STEPS.PERSONAL_INFO });
};

export const performOnboardingPersonalInfoSubmission = async () => {
  if ($onboarding.value.invitedUser || $user.value.currentOrganization?.id) {
    try {
      $user.loadingStart();
      $global.update({
        isLoading: true,
        isLoadingMessage: 'Updating User...',
      });
      const payload = $onboarding.value;
      const response = await accruClient.users.update({
        data: {
          first_name: payload.firstName,
          language: 'en-US',
          last_name: payload.lastName,
          timezone: payload.timezone,
        },
      });

      $onboarding.update({
        step: ONBOARDING_STEPS.COMPLETE,
      });

      $user.update({
        user: {
          ...$user.value.user,
          ...response,
        },
      });

      $global.update({
        isLoading: false,
        isLoadingMessage: 'Loading...',
      });
    } catch (error) {
      handleNotification(error);
    } finally {
      $user.loadingEnd();
    }
  } else {
    $onboarding.update({ step: ONBOARDING_STEPS.COMPANY_INFO });
  }
};

export const createOrganizationSubmit = async (email) => {
  try {
    $global.update({
      isLoading: true,
      isLoadingMessage: 'Creating Organization...',
    });

    const payload = $onboarding.value;

    if (payload.companyName.length === 0) {
      throw new Error('Company Name cannot be blank.');
    }

    if (payload.companyIndustry.length === 0) {
      throw new Error('Company Industry cannot be blank.');
    }

    if (payload.numberOfPeople === 'Select') {
      throw new Error('Please select the number of people.');
    }
    if (!formatPhoneNumber($onboarding.value.phoneNumber)) {
      throw new Error('Please enter a valid phone number.');
    }
    await accruClient.users.update({
      data: {
        first_name: payload.firstName,
        last_name: payload.lastName,
        timezone: payload.timezone,
        language: 'en-US',
      },
    });

    const response = await accruClient.organizations.create({
      data: {
        email,
        name: payload.companyName,
        phone_number: formatPhoneNumForApi(payload.phoneNumber),
        primary_contact_name: `${payload.firstName} ${payload.lastName}`,
        business_industry: payload.companyIndustry,
        business_number_of_employees: Number(payload.numberOfPeople),
      },
    });

    if (response) {
      logEvent({ name: 'create_organization_success' });
      handleNotification('Congratulations! Your organization has been created.', { variant: 'success' });
      await fetchAndSetUserData();
      $onboarding.update({
        organizationId: response.id,
        step: ONBOARDING_STEPS.COMPLETE,
      });
    }
  } catch (error) {
    $onboarding.update({ step: ONBOARDING_STEPS.PERSONAL_INFO });
    handleNotification(error);
  } finally {
    $global.update({
      isLoading: false,
      isLoadingMessage: 'Loading...',
    });
  }
};
