/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import cycleImg from 'images/cycle.svg';
import userIcon from 'images/user-icon.svg';
import { isProdVersion } from 'components/views/MVP/MVP.helpers';
import { useHistory } from 'react-router-dom';
import { $syncing } from 'signals/Global.signals';

const AddCustomerMethod = ({ handleNext }) => {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-48 pb-lg-104">How would you like to add your customer(s)?</h1>
        </Col>
      </Row>
      <Container className="pb-48">
        <Card
          onClick={() => {
            $syncing.update({ referredBy: 'customers' });
            history.push('/sync');
          }}
          style={{ maxWidth: 620 }}
          className="cursor-pointer py-32 mb-24 justify-content-left mx-auto"
        >
          <Container>
            <Row className="d-flex justify-content-left align-items-center px-16">
              <Col lg={2} className="mb-8 mb-lg-0">
                <Image width={40} src={cycleImg} />
              </Col>
              <Col lg={9} className="text-start text-dark">
                <h5 className="fw-700">Sync with your Accounting Software</h5>
                <p className="base mb-0">Connect your accounting software&apos;s customer list with Accru</p>
              </Col>
            </Row>
          </Container>
        </Card>
        <Card
          onClick={isProdVersion(2) ? () => handleNext({ current: 'method', next: 'manual' }) : null}
          style={{ maxWidth: 620 }}
          className={`py-32 mb-24 justify-content-left mx-auto ${isProdVersion() ? 'opacity-50' : 'cursor-pointer '}`}
        >
          <Container>
            <Row className="d-flex justify-content-left align-items-center px-16">
              <Col lg={2} className="mb-8 mb-lg-0">
                <Image width={40} src={userIcon} />
              </Col>
              <Col lg={9} className="text-start text-dark">
                <h5 className="fw-700">Add one customer</h5>
                <p className="base mb-0">Manually add a customer into our system</p>
                <p>{isProdVersion() && 'Coming soon!'}</p>
              </Col>
            </Row>
          </Container>
        </Card>
      </Container>
    </>
  );
};

export default AddCustomerMethod;
