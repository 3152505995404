import { Button, Form, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthLayout } from 'components/global/Layout';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import DontHaveAnAccountLink from 'components/views/Auth/_components/DontHaveAnAccountLink';
import { handleForgotPasswordPartial, $forgotPassword } from './ForgotPassword.helpers';

const ForgotPassword = () => (
  <AuthLayout>
    <Form.Group>
      <h2 className="fw-700 text-center mb-32">Password Reset</h2>
      <Form.Label htmlFor="EmailAddress"><strong>Email address</strong></Form.Label>
      <UniversalInput
        className="mb-40"
        aria-describedby="emailHelp"
        placeholder="Enter email address"
        name="email"
        signal={$forgotPassword}
      />
      <Button variant="primary" type="submit" onClick={handleForgotPasswordPartial} className="w-100 btn-lg mb-16" disabled={$forgotPassword.value.submitting}>SEND PASSWORD RESET</Button>
      <LinkContainer to="/login">
        <Nav.Link className="mb-32 text-decoration-underline p-0 text-primary text-center"><p className="mb-0 fw-bold">Back to Login</p></Nav.Link>
      </LinkContainer>
      <DontHaveAnAccountLink />
    </Form.Group>
  </AuthLayout>
);

export default ForgotPassword;
