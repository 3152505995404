export const formatPhoneNumber = (value) => {
  const cleanedNumber = (`${value}`).replace(/\D/g, '');
  const matchedNumber = cleanedNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (matchedNumber) {
    const USCountryCode = (matchedNumber[1] ? '+1 ' : '');
    return [USCountryCode, '(', matchedNumber[2], ') ', matchedNumber[3], '-', matchedNumber[4]].join('');
  }
  return null;
};

export default formatPhoneNumber;
