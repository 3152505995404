import { Card, Form, Row, Col, Container, Button } from 'react-bootstrap';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import { $customersForm, handleAddCustomer } from 'components/views/CustomerAdd/CustomerAdd.helpers';
import useCleanup from 'hooks/useCleanup';

const AddCustomerManual = () => {
  useCleanup($customersForm);
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-56">Add a Customer</h1>
        </Col>
      </Row>
      {/* TODO: Update copy */}
      {/* <Row>
      <Col>
        <h2 className="pb-56">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
      </Col>
    </Row> */}

      <Container>
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 py-56 justify-content-left mx-auto text-start">
              <Form>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Customer/Company name</Form.Label>
                  <UniversalInput
                    name="name"
                    signal={$customersForm}
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Email</Form.Label>
                  <UniversalInput
                    name="email"
                    signal={$customersForm}
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Phone number (optional)</Form.Label>
                  <PhoneNumberInput
                    name="phone_number"
                    signal={$customersForm}
                    className="mb-16"
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={handleAddCustomer}
                  disabled={!$customersForm.value.email.length || !$customersForm.value.name.length}
                >
                  SAVE CUSTOMER
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCustomerManual;
