import { Col, Container, Image, Row } from 'react-bootstrap';
import smileIcon from 'images/smileIcon.svg';
import { $accountingConnSettings, $accountingOAuth, availableAcctIntegrations, isAcctProviderCodeValid } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
import ConnectionsSettings from 'components/views/SettingsAccounting/_components/ConnectionSettings';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

const ConnectionModal = (
  actionLabel,
  actionCallback,
) => {
  const { provider, connection } = $accountingOAuth.value;
  const { updateData } = $accountingConnSettings.value;

  const providerInfo = !!provider && availableAcctIntegrations.find(acct => acct.provider === provider);
  const isConnected = connection?.id && updateData?.id && connection.id === updateData.id;

  return (
    <SimpleModal
      isShow={isAcctProviderCodeValid(provider) && !!providerInfo}
      isHideSave
    >
      <Container fluid className="my-40">
        <Row className="mt-24">
          <Col className="d-flex justify-content-center align-items-center">
            <div className="mx-24">
              <Image width={76} src={smileIcon} />
            </div>
            <div className="mx-24">
              {isConnected ? (
                <div className="spinner-grow" role="status" />
              ) : (
                <div className="spinner-border" role="status" />
              )}
            </div>
            <div className="mx-24">
              <Image width={76} src={providerInfo.logo} />
            </div>
          </Col>
        </Row>
        <Row className="mt-24">
          <h2 className="fw-700">{connection?.id ? 'Success!' : `Connecting to your ${providerInfo.name} account`}</h2>
        </Row>
        <Row className="mt-16">
          <h4 className="fw-400 text-dark">{connection?.id ? `${providerInfo.name} has been successfully connected.\nComplete the settings to synchronize.` : 'Please stay on your accounting browser to finish connecting your account software to accru.'}</h4>
        </Row>
        {isConnected && (
          <ConnectionsSettings actionLabel={actionLabel} actionCallback={actionCallback} exitAfterSave />
        )}
      </Container>
    </SimpleModal>
  );
};

export default ConnectionModal;
