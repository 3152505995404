export const companyIndstryArray = [
  { label: 'Select', value: 'Select', disabled: false },
  { label: 'Information Technology', value: 'Information Technology', disabled: false },
  { label: 'Healthcare and Pharmaceuticals', value: 'Healthcare and Pharmaceuticals', disabled: false },
  { label: 'Financial Services', value: 'Financial Services', disabled: false },
  { label: 'Manufacturing', value: 'Manufacturing', disabled: false },
  { label: 'Retail and E-commerce', value: 'Retail and E-commerce', disabled: false },
  { label: 'Automotive', value: 'Automotive', disabled: false },
  { label: 'Energy and Utilities', value: 'Energy and Utilities', disabled: false },
  { label: 'Telecommunications', value: 'Telecommunications', disabled: false },
  { label: 'Agriculture and Agribusiness', value: 'Agriculture and Agribusiness', disabled: false },
  { label: 'Real Estate and Construction', value: 'Real Estate and Construction', disabled: false },
  { label: 'Food and Beverage', value: 'Food and Beverage', disabled: false },
  { label: 'Media and Entertainment', value: 'Media and Entertainment', disabled: false },
  { label: 'Transportation and Logistics', value: 'Transportation and Logistics', disabled: false },
  { label: 'Aerospace and Defense', value: 'Aerospace and Defense', disabled: false },
  { label: 'Hospitality and Tourism', value: 'Hospitality and Tourism', disabled: false },
  { label: 'Education and E-learning', value: 'Education and E-learning', disabled: false },
  { label: 'Environmental Services', value: 'Environmental Services', disabled: false },
  { label: 'Fashion and Apparel', value: 'Fashion and Apparel', disabled: false },
  { label: 'Professional Services', value: 'Professional Services', disabled: false },
  { label: 'Consumer Electronics', value: 'Consumer Electronics', disabled: false },
  { label: 'Gaming and Leisure', value: 'Gaming and Leisure', disabled: false },
  { label: 'Nonprofit and Social Services', value: 'Nonprofit and Social Services', disabled: false },
  { label: 'Biotechnology', value: 'Biotechnology', disabled: false },
  { label: 'Insurance', value: 'Insurance', disabled: false },
  { label: 'Software Development', value: 'Software Development', disabled: false },
  { label: 'Research and Development', value: 'Research and Development', disabled: false },
  { label: 'Sports and Fitness', value: 'Sports and Fitness', disabled: false },
  { label: 'Government and Public Administration', value: 'Government and Public Administration', disabled: false },
  { label: 'Renewable Energy', value: 'Renewable Energy', disabled: false },
  { label: 'Wellness and Health Services', value: 'Wellness and Health Services', disabled: false },
];

export const numberOfPeopleArray = [
  { label: 'Select', value: 'Select', disabled: false },
  { label: '1-10', value: 1, disabled: false },
  { label: '10-50', value: 10, disabled: false },
  { label: '50-100', value: 50, disabled: false },
  { label: '100-200', value: 100, disabled: false },
  { label: '200+', value: 200, disabled: false },
];

export const updateRoleArray = [
  {
    value: 'Select',
    label: 'Select',
    disabled: false,
  },
  {
    value: 'OWNER',
    label: 'Owner',
    disabled: false,
  },
  {
    value: 'ADMIN',
    label: 'Admin',
    disabled: false,
  },
  {
    value: 'EDITOR',
    label: 'Editor (Coming soon!)',
    disabled: true,
  },
  {
    value: 'VIEWER',
    label: 'View Only (Coming soon!)',
    disabled: true,
  },
];

export const createRoleArray = [
  {
    value: 'Select',
    label: 'Select',
    disabled: false,
  },
  {
    value: 'ADMIN',
    label: 'Admin',
    disabled: false,
  },
  {
    value: 'EDITOR',
    label: 'Editor (Coming soon!)',
    disabled: true,
  },
  {
    value: 'VIEWER',
    label: 'View Only (Coming soon!)',
    disabled: true,
  },
];

export const timeZoneArray = () => {
  const timezones = [{
    value: 'Select',
    label: 'Select',
    disabled: false,
  }, {
    value: 'UTC',
    label: 'UTC',
    disabled: false,
  }, {
    value: 'GMT',
    label: 'GMT',
    disabled: false,
  }];
  new Intl.Locale('pt-BR')?.timeZones?.forEach(zone => timezones.push({
    value: zone,
    label: zone,
    disabled: false,
  }));
  new Intl.Locale('en-US')?.timeZones?.forEach(zone => timezones.push({
    value: zone,
    label: zone,
    disabled: false,
  }));
  return timezones;
};

export const repeatArray = [
  {
    value: 'daily',
    label: 'Daily',
    disabled: false,
  },
  {
    value: 'weekly',
    label: 'Weekly',
    disabled: false,
  },
  {
    value: 'monthly',
    label: 'Monthly',
    disabled: false,
  },
];

export const weekValues = [
  { label: 'Su', value: 0, disabled: false },
  { label: 'M', value: 1, disabled: false },
  { label: 'T', value: 2, disabled: false },
  { label: 'W', value: 3, disabled: false },
  { label: 'Th', value: 4, disabled: false },
  { label: 'F', value: 5, disabled: false },
  { label: 'Sa', value: 6, disabled: false },
];

export const timeArray = [
  { value: 0, label: '12:00 AM', disabled: false },
  { value: 1, label: '1:00 AM', disabled: false },
  { value: 2, label: '2:00 AM', disabled: false },
  { value: 3, label: '3:00 AM', disabled: false },
  { value: 4, label: '4:00 AM', disabled: false },
  { value: 5, label: '5:00 AM', disabled: false },
  { value: 6, label: '6:00 AM', disabled: false },
  { value: 7, label: '7:00 AM', disabled: false },
  { value: 8, label: '8:00 AM', disabled: false },
  { value: 9, label: '9:00 AM', disabled: false },
  { value: 10, label: '10:00 AM', disabled: false },
  { value: 11, label: '11:00 AM', disabled: false },
  { value: 12, label: '12:00 PM', disabled: false },
  { value: 13, label: '1:00 PM', disabled: false },
  { value: 14, label: '2:00 PM', disabled: false },
  { value: 15, label: '3:00 PM', disabled: false },
  { value: 16, label: '4:00 PM', disabled: false },
  { value: 17, label: '5:00 PM', disabled: false },
  { value: 18, label: '6:00 PM', disabled: false },
  { value: 19, label: '7:00 PM', disabled: false },
  { value: 20, label: '8:00 PM', disabled: false },
  { value: 21, label: '9:00 PM', disabled: false },
  { value: 22, label: '10:00 PM', disabled: false },
  { value: 23, label: '11:00 PM', disabled: false },
];

export const invoiceBulkActionsOptions = [
  { value: 'Send All', label: 'Send All', disabled: false },
  { value: 'Export CSV', label: 'Export CSV', disabled: false },
  // { value: 'Remind All', label: 'Remind All', disabled: true },
  // { value: 'Archive', label: 'Archive', disabled: true },
];

export const billsBulkActionsOptions = [
  { value: 'Export CSV', label: 'Export CSV', disabled: false },
  // { value: 'Archive', label: 'Archive', disabled: true },
];

export const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'overdue', label: 'Overdue' },
  { value: 'open', label: 'Open' },
  { value: 'paid', label: 'Paid' },
];

export const sendRemindersArray = [
  {
    value: true,
    label: 'Yes',
    disabled: false,
  },
  {
    value: false,
    label: 'No',
    disabled: false,
  },
];

export default {
  companyIndstryArray,
  numberOfPeopleArray,
  updateRoleArray,
  createRoleArray,
  timeZoneArray,
  repeatArray,
  weekValues,
  timeArray,
  invoiceBulkActionsOptions,
  billsBulkActionsOptions,
  statusOptions,
  sendRemindersArray,
};
