/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useCallback } from 'react';
import { $searchableDropdown } from 'signals/Global.signals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import CustomForm from './CustomForm';
import CustomMenu from './CustomMenu';

const SearchableDropdown = ({
  selected,
  options,
  onSelect,
  onChangeText,
  value,
  createNewItem,
  createNewType,
  onClear,
  onEnter,
  variant = 'form-control', // || form-control-border
}) => {
  const { searchString } = $searchableDropdown.value;
  const getValue = useCallback((e) => {
    const result = options.find(o => o.value.toLowerCase() === e.toLowerCase() || o.name.toLowerCase() === e.toLowerCase());
    $searchableDropdown.update({
      searchString: e,
    });
    if (typeof onChangeText === 'function') onChangeText(e);
    onSelect(result || '');
  }, [options, onSelect]);

  return (
    <>
      <Dropdown
        className="w-100 position-relative"
        onSelect={(e) => getValue(e)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            if (typeof onEnter === 'function') onEnter();
          }
        }}
      >
        <Dropdown.Toggle as={CustomForm} id="custom-dropdown-basic">
          <Form.Control
            type="text"
            value={selected?.name || value || searchString}
            className={variant}
            onChange={(e) => {
              e.preventDefault();
              getValue(e.target.value);
            }}
            placeholder="Start Typing..."
          />
        </Dropdown.Toggle>
        {(onClear && selected) && (
          <div
            className="position-absolute cursor-pointer searchable-clear-btn"
            onClick={() => {
              onClear();
              $searchableDropdown.update({ searchString: '' });
            }}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
        )}
        <Dropdown.Menu as={CustomMenu} style={{ minWidth: '100%' }}>
          {options?.filter((o) => o.name?.toLowerCase().includes(searchString?.toLowerCase())).map(option => (
            <Dropdown.Item key={option.value ?? option} eventKey={option.value ?? option} className="base fw-500 text-primary dropdown-alternate-shading">{option.name ?? option}</Dropdown.Item>
          ))}
          {!options.length && (
            <>
              <Dropdown.Item disabled className="base text-dark dropdown-alternate-shading"><i>No options found</i></Dropdown.Item>
              {createNewItem && createNewType && (
                <div className="d-flex justify-content-end mt-8 me-8">
                  <Button variant="outline-primary" onClick={createNewItem}>Add New {createNewType}</Button>
                </div>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SearchableDropdown;
