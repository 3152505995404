import { Card, Form, Row, Col, Container, Button } from 'react-bootstrap';
import { $vendorsForm, handleAddVendor } from 'components/views/VendorAdd/VendorAdd.helpers';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import useCleanup from 'hooks/useCleanup';

const AddVendorManual = () => {
  useCleanup($vendorsForm);
  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-56">Add a Vendor</h1>
        </Col>
      </Row>
      {/* TODO: Update copy */}
      {/* <Row>
      <Col>
        <h2 className="pb-56">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
      </Col>
    </Row> */}
      <Container>
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 py-56 justify-content-left mx-auto text-start">
              <Form>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Vendor name</Form.Label>
                  <UniversalInput
                    signal={$vendorsForm}
                    name="name"
                    placeholder="Vendor Name"
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Email</Form.Label>
                  <UniversalInput
                    signal={$vendorsForm}
                    name="email"
                    placeholder="Vendor Email"
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-16">
                  <Form.Check
                    className="py-8 pe-8 d-inline"
                    type="checkbox"
                    checked={$vendorsForm.value.inviteToAccru}
                    onChange={() => $vendorsForm.update({
                      inviteToAccru: !$vendorsForm.value.inviteToAccru,
                    })}
                  />
                  <small className="fw-400 text-dark base d-inline">Invite to Accru</small>
                </Form.Group>
                <Form.Group className="form-group text-start mb-48">
                  <Form.Check
                    className="py-8 pe-8 d-inline"
                    type="checkbox"
                    checked={$vendorsForm.value.sendConnectionInvite}
                    onChange={() => $vendorsForm.update({
                      sendConnectionInvite: !$vendorsForm.value.sendConnectionInvite,
                    })}
                  />
                  <small className="fw-400 text-dark base d-inline">Connect with vendor</small>
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={handleAddVendor}
                  disabled={!$vendorsForm.value.email.length || !$vendorsForm.value.name.length}
                >
                  SAVE VENDOR
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddVendorManual;
