import $selectOrganization from 'components/views/SelectOrganization/SelectOrganization.signals';
import { selectOrganizationAndRedirect } from 'components/helpers/User.helpers';

export const handleSelectOrganizationModalOpen = () => {
  $selectOrganization.update({
    isModalOpen: true,
  });
};

export const handleSelectOrganizationModalClose = () => {
  $selectOrganization.reset();
};

export const handleSelectOrganizationSearchClear = () => {
  $selectOrganization.reset();
};

export const handleSelectOrganizationListClick = (organizationId) => {
  selectOrganizationAndRedirect(organizationId);
  handleSelectOrganizationModalClose();
};
