import { Form } from 'react-bootstrap';

const DateRangePicker = ({
  startName,
  endName,
  startValue,
  endValue,
  onChange,
}) => {
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    if (newStartDate?.length < 11) {
      onChange({
        name: startName,
        value: newStartDate,
      });
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (newEndDate?.length < 11) {
      onChange({
        name: endName,
        value: newEndDate,
      });
    }
  };

  return (
    <div className="d-flex p-0 border border-primary rounded">
      <Form.Control
        name={startName}
        className="w-100 border-0 rounded not-form-control"
        type="date"
        placeholder="Start Date"
        value={startValue}
        onChange={handleStartDateChange}
        style={{ paddingTop: '6px', paddingBottom: '5px' }}
      />
      <Form.Control
        name={endName}
        className="w-100 border-0 rounded not-form-control"
        type="date"
        placeholder="End Date"
        value={endValue}
        onChange={handleEndDateChange}
        style={{ paddingTop: '6px', paddingBottom: '5px' }}
      />
    </div>
  );
};

export default DateRangePicker;
