/* eslint-disable no-continue */
export const csvToJSON = (csv) => {
  const lines = csv.split('\n');
  const result = [];
  const headers = lines[0].split(',');

  for (let i = 1; i < lines.length; i++) {
    const obj = {};

    if (lines[i] === undefined || lines[i].trim() === '') {
      continue;
    }

    const words = lines[i].split(',');
    for (let j = 0; j < words.length; j++) {
      obj[headers[j].trim()] = words[j]?.replace('\r', '');
    }

    result.push(obj);
  }
  return result;
};

export default csvToJSON;
