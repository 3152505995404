import { $settingsReminder } from 'components/views/SettingsReminders/SettingsReminders.helpers';
import { $settingsProfile } from 'components/views/SettingsProfile/_helpers/SettingsProfile.signals';
import { $settingsPayment } from 'components/views/SettingsPayment/SettingsPayment.helpers';
import { $settingsOrganization } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.signals';
import { $settingsLogs } from 'components/views/SettingsLogs/_helpers/SettingsLogs.signals';
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

export const SettingsModal = ({ settingTabs, signal }) => {
  const history = useHistory();
  const { currentTab, isMobileModalOpen } = signal?.value;
  const selectedTabContents = settingTabs.find(category => category.tab === currentTab);

  return (
    <SimpleModal
      isShow={isMobileModalOpen && selectedTabContents}
      title="Settings"
      onClose={() => {
        signal.update({
          ...signal.value,
          isMobileModalOpen: false,
        });
      }}
      isHideCancel
      isHideSave
    >
      <Container>
        <Row className="d-flex align-items-center">
          <Col className="bg-white">
            <Tab.Container
              activeKey={currentTab}
              onSelect={(t) => {
                signal.update({
                  ...signal.value,
                  currentTab: t,
                });
                $settingsLogs.reset();
                $settingsProfile.reset();
                $settingsReminder.reset();
                $settingsPayment.reset();
                $settingsOrganization.reset();
                history.push(`/settings/${t}`);
              }}
            >
              <h3 className="fw-400 mb-0 text-center text-primary">{selectedTabContents?.title}</h3>
              <Tab.Content>
                <Tab.Pane
                  key={selectedTabContents?.tab}
                  eventKey={selectedTabContents?.tab}
                  title={selectedTabContents?.tab}
                  className="ms-0"
                >
                  {selectedTabContents?.component}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </SimpleModal>
  );
};

export default SettingsModal;
