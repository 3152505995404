/* eslint-disable react/no-unescaped-entities */

import ContentWrapper from 'components/global/ContentWrapper';

/* eslint-disable react/button-has-type */
const themeColors = {
  primary: 'var(--bs-primary)',
  secondary: 'var(--bs-secondary)',
  info: 'var(--bs-info)',
  danger: 'var(--bs-danger)',
  warning: 'var(--bs-warning)',
  success: 'var(--bs-success)',
  dark: 'var(--bs-dark)',
  gray: 'var(--bs-gray)',
  light: 'var(--bs-light)',
  'gray-50': 'var(--bs-gray-50)',
  'gray-100': 'var(--bs-gray-100)',
  'gray-200': 'var(--bs-gray-200)',
  'gray-300': 'var(--bs-gray-300)',
  'gray-400': 'var(--bs-gray-400)',
  'gray-500': 'var(--bs-gray-500)',
  'gray-600': 'var(--bs-gray-600)',
  'gray-700': 'var(--bs-gray-700)',
  'gray-800': 'var(--bs-gray-800)',
  'gray-900': 'var(--bs-gray-900)',
};

const StyleGuide = () => (
  <ContentWrapper>
    <div className="container mt-64">
      <h1 className="mb-24">Style Guide</h1>

      <h2 className="mb-16">Theme Colors</h2>
      <div className="row">
        {Object.entries(themeColors).map(([name, color], idx) => (
          <div className="col-md-3 mb-16" key={name}>
            <div className="p-16" style={{ backgroundColor: color, color: idx > 6 ? 'var(--bs-dark)' : 'var(--bs-light)', borderRadius: '4px' }}>
              <strong>{name}</strong>
              <div>{color}</div>
            </div>
          </div>
        ))}
      </div>

      <h2 className="mb-16">Bootstrap Components</h2>

      <h3 className="mb-16">Buttons</h3>
      <div className="mb-16">
        <button className="btn btn-primary me-2">Primary</button>
        <button className="btn btn-secondary me-2">Secondary</button>
        <button className="btn btn-success me-2">Success</button>
        <button className="btn btn-danger me-2">Danger</button>
        <button className="btn btn-warning me-2">Warning</button>
        <button className="btn btn-info me-2">Info</button>
        <button className="btn btn-light me-2">Light</button>
        <button className="btn btn-dark me-2">Dark</button>
      </div>

      <h3 className="mb-16">Alerts</h3>
      <div className="mb-16">
        <div className="alert alert-primary">This is a primary alert</div>
        <div className="alert alert-secondary">This is a secondary alert</div>
        <div className="alert alert-success">This is a success alert</div>
        <div className="alert alert-danger">This is a danger alert</div>
        <div className="alert alert-warning">This is a warning alert</div>
        <div className="alert alert-info">This is an info alert</div>
        <div className="alert alert-light">This is a light alert</div>
        <div className="alert alert-dark">This is a dark alert</div>
      </div>

      <h3 className="mb-16">Badges</h3>
      <div className="mb-16">
        <span className="badge bg-primary me-2">Primary</span>
        <span className="badge bg-secondary me-2">Secondary</span>
        <span className="badge bg-success me-2">Success</span>
        <span className="badge bg-danger me-2">Danger</span>
        <span className="badge bg-warning me-2">Warning</span>
        <span className="badge bg-info me-2">Info</span>
        <span className="badge bg-light me-2">Light</span>
        <span className="badge bg-dark me-2">Dark</span>
      </div>

      <h3 className="mb-16">Forms</h3>
      <div className="mb-16">
        <form>
          <div className="mb-16">
            <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
          </div>
          <div className="mb-16">
            <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
            <input type="password" className="form-control" id="exampleInputPassword1" />
          </div>
          <div className="mb-16 form-check">
            <input type="checkbox" className="form-check-input" id="exampleCheck1" />
            <label className="form-check-label" htmlFor="exampleCheck1">Check me out</label>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </ContentWrapper>
);

export default StyleGuide;
