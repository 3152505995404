import { Image, Container, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import arrowLeft from 'images/arrowLeft.svg';
import closeButton from 'images/closeButton.svg';
import triangleBg from 'images/yellowtrianglebackground.svg';
import squareBg from 'images/bluesquarebackground.svg';
import { $vendorAdd, handleBackButton, handleNext } from 'components/views/VendorAdd/VendorAdd.helpers';
import AddVendorMethod from './_components/AddVendorMethod';
import AddVendorManual from './_components/AddVendorManual';
import AddVendorConfirmInfo from './_components/AddVendorConfirmInfo';
import AddVendorUpload from './_components/AddVendorUpload';
import AddVendorUploadSuccess from './_components/AddVendorUploadSuccess';

const VendorAdd = () => {
  const { currentScreen } = $vendorAdd.value;
  const history = useHistory();

  return (
    <Container
      fluid
      className="min-vh-100 px-0 w-100 text-center position-relative"
      style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}
    >
      <Image className="position-absolute z-index-0 top-0 end-0" src={triangleBg} />
      <Image className="position-absolute bottom-0 start-0 z-index-0" src={squareBg} />
      <div className="d-flex min-vh-100 p-auto justify-content-center">
        <Container style={{ maxWidth: '1200px' }} className="z-index-1 pt-80 pt-lg-152">
          <Row>
            <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
              <Image
                src={arrowLeft}
                className="cursor-pointer"
                onClick={() => handleBackButton()}
              />
              <Image
                src={closeButton}
                className="cursor-pointer z-index-1"
                onClick={() => history.push('/vendors')}
              />
            </Col>
          </Row>
          {{
            method: <AddVendorMethod handleNext={handleNext} />,
            manual: <AddVendorManual handleNext={handleNext} />,
            confirmInfo: <AddVendorConfirmInfo handleNext={handleNext} />,
            upload: <AddVendorUpload handleNext={handleNext} />,
            uploadSuccess: <AddVendorUploadSuccess handleNext={handleNext} />,
          }[currentScreen]}
        </Container>
      </div>
    </Container>
  );
};

export default VendorAdd;
