import SidePanelBills from 'components/global/SidePanel/SidePanelBills';
import SidePanelContacts from 'components/global/SidePanel/SidePanelContacts';
import SidePanelDetails from 'components/global/SidePanel/SidePanelDetails';
import SidePanelProjects from 'components/global/SidePanel/SidePanelProjects';
import SidePanelConnections from 'components/global/SidePanel/SidePanelConnections';
import { $customersUi } from 'components/views/Customers/_helpers/Customers.signals';
import TabNav from 'components/global/TabNav/TabNav';

export const customerTabs = [
  {
    title: 'Details',
    key: 'details',
    component: <SidePanelDetails type="customer" className="rounded-no-1" />,
  },
  {
    title: 'Contacts',
    key: 'contacts',
    component: <SidePanelContacts type="customer" />,
  },
  {
    title: 'Invoices',
    key: 'invoices',
    component: <SidePanelBills type="customer" />,
  },
  {
    title: 'Projects',
    key: 'projects',
    component: <SidePanelProjects type="customer" />,
  },
  {
    title: 'Connections',
    key: 'connections',
    component: <SidePanelConnections type="customer" className="rounded-no-2" />,
  },
];

const CustomerDetailTabs = () => (
  <TabNav
    tabs={customerTabs.map(tab => ({ key: tab.key, title: tab.title }))}
    TabPaneComponents={customerTabs.map(tab => tab.component)}
    onClick={(t) => $customersUi.update({ openTab: t.key })}
    activeTab={$customersUi.value.openTab}
    defaultActiveTab="details"
  />
);

export default CustomerDetailTabs;
