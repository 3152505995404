import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import quickBooksDefault from '../../../images/connect-now-default.svg';
import quickBooksHover from '../../../images/connect-now-hover.svg';

const QuickBooksButton = ({ onClick, disabled = false }) => {
  const [image, setImage] = useState(quickBooksDefault);
  return (
    <button type="button" className="custom-button" disabled={disabled} onClick={onClick} aria-label="QuickBooks Connect Now Button">
      <Image width={200} src={image} onMouseEnter={() => setImage(quickBooksHover)} onMouseLeave={() => setImage(quickBooksDefault)} />
    </button>
  );
};

export default QuickBooksButton;
