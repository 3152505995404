import UniversalInput from 'components/global/Inputs/UniversalInput';
import magnifyingImg from 'images/magnifying.svg';
import closeButtonImg from 'images/closeButton.svg';
import { handleClearCustomersSearch, setCustomersFromAutocomplete } from 'components/views/Customers/_helpers/Customers.helpers';
import { $customersFilter } from 'components/views/Customers/_helpers/Customers.signals';
import { Image, InputGroup } from 'react-bootstrap';

const CustomersSearch = () => (
  <InputGroup
    className="py-8"
    onKeyDown={(e) => { if (e.key === 'Enter') setCustomersFromAutocomplete(); }}
  >
    <InputGroup.Text
      className="ps-32 border-0 bg-gray-100"
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
      }}
    >
      <Image src={magnifyingImg} />
    </InputGroup.Text>
    <UniversalInput
      name="search"
      signal={$customersFilter}
      className="custom-searchbox"
      autoComplete="off"
    />
    <InputGroup.Text
      className="ps-32 border-0"
      style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
      }}
      onClick={handleClearCustomersSearch}
    >
      {$customersFilter.value.search && (<Image src={closeButtonImg} />)}
    </InputGroup.Text>
  </InputGroup>
);

export default CustomersSearch;
