import Loadable from 'components/global/Loadable';
import { Form } from 'react-bootstrap';

const UniversalInput = ({
  variant = 'form-control', // || form-control-border
  type,
  name,
  placeholder,
  className,
  signal,
  inputFormatCallback,
  loadableAnimation,
  customValue,
  customOnChange, // ONLY USE IF NEEDED
  autoComplete = false,
  autoFocus = false,
  ...props
}) => {
  if ((!signal || !name) && !customOnChange) {
    return new Error(`Universal Input has no signal or name (Name: ${name})`);
  }

  if (signal) {
    return (
      <Loadable signal={signal} animation={loadableAnimation} className={className}>
        <Form.Control
          type={type || 'text'}
          value={signal?.value[name] || customValue || ''}
          placeholder={placeholder}
          className={`${variant} ${className || ''}`}
          name={name}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          onChange={customOnChange || ((e) => signal.update({
            [name]: inputFormatCallback ? inputFormatCallback(e.target.value) : e.target.value,
          }))}
          {...props}
        />
      </Loadable>
    );
  }
};

export default UniversalInput;
