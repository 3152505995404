import { useEffect } from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import processingIcon from 'images/processingIcon.svg';
import { setActiveForm } from 'components/views/Statement/Old/Statement.helpers';

const Processing = ({ className }) => {
  useEffect(() => {
    setTimeout(() => {
      setActiveForm('PaymentConfirmed');
    }, 3000);
  }, []);

  return (
    <Container className={className}>
      <div className="p-40 mt-8 m-24">
        <Row className="d-flex justify-content-center mt-40">
          <Image className="w-auto" src={processingIcon} alt="Accru smile icon" />
        </Row>
        <h2 className="text-primary fw-700 mt-24">Processing....</h2>
        <p className="lead fw-400 mb-24">This should only take a moment</p>
        <p className="lead fw-400">But while you are waiting....Dr. Pepper were neither a doctor, nor a pepper. Discuss</p>
      </div>
    </Container>
  );
};

export default Processing;
