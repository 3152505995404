import { accruClient } from 'api';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { $sidePanelFormDataDetails, $sidePanelSettingsDetails } from 'components/global/SidePanel/SidePanel.helpers';
import history from 'utils/history';
import {
  $selectedVendor,
  $vendorsUI,
  $vendors,
  $vendorsFilter,
  $vendorsSearchCache,
  $searchKey,
  $isCacheAvailable,
} from './Vendors.signals';

export const fetchAndSetAutocomplete = async () => {
  try {
    if (!$vendorsFilter.value.search) {
      $vendorsUI.update({ isSearchListOpen: false });
      return;
    }

    if ($vendorsFilter.value.search?.length < 3) {
      return;
    }

    $vendorsSearchCache.loadingStart();
    $vendorsUI.update({ isSearchListOpen: true });

    if ($isCacheAvailable.value) {
      return;
    }

    const { items } = await accruClient.vendors.get({
      organizationId: $user?.value?.currentOrganization?.id,
      name: $vendorsFilter.value.search,
      currentOrganizationId: $user.value.currentOrganization.id,
    });

    if ($searchKey.value.length) {
      $vendorsSearchCache.update({
        ...$vendorsSearchCache.value,
        [$searchKey.value]: items || [],
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $vendorsSearchCache.loadingEnd();
  }
};

export const getAllVendors = async ({
  name,
  after,
  first,
  before,
  last,
  skip,
  take,
  sorting,
}) => {
  try {
    const response = await accruClient.vendors.get({
      organizationId: $user.value.currentOrganization.id,
      name,
      after,
      first,
      before,
      last,
      skip,
      take,
      sorting,
    });
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const updateVendor = async ({ organizationVendorId, data }) => {
  try {
    const response = await accruClient.vendors.update({
      organizationId: $user.value.currentOrganization.id,
      organizationVendorId,
      data,
    });
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const getVendor = async ({ organizationVendorId }) => {
  try {
    $selectedVendor.loadingStart();
    const response = await accruClient.vendors.getOne({
      organizationId: $user.value.currentOrganization.id,
      organizationVendorId,
    });

    return response;
  } catch (error) {
    handleNotification(error);
  } finally {
    $selectedVendor.loadingEnd();
  }
};

export const handleUpdateVendor = async (updateData) => {
  try {
    $sidePanelSettingsDetails.loadingStart();
    const response = await updateVendor(updateData);
    const newVendorList = $vendors.value.items;
    const indexOfUpdatedVendor = newVendorList.findIndex(({ id }) => id === response.id);
    newVendorList[indexOfUpdatedVendor] = response;

    $vendors.update({ items: newVendorList });
    $selectedVendor.update(response);
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelSettingsDetails.loadingEnd();
  }
};

export const handleSelectVendor = async ({ vendor, isBreakpointDown }) => {
  try {
    $selectedVendor.loadingStart();

    const response = await getVendor({
      organizationVendorId: vendor.id,
    });

    $selectedVendor.update(response);

    if (isBreakpointDown && isBreakpointDown('md')) {
      $vendorsUI.update({ isMobileModalOpen: true });
    }
    $sidePanelFormDataDetails.update(vendor);
  } catch (error) {
    handleNotification(error);
  } finally {
    $selectedVendor.loadingEnd();
  }
};

export const fetchAndSetVendors = async () => {
  const {
    selectedSorting,
    page,
    perPage,
    searchTerm,
  } = $vendorsFilter.value;

  const take = perPage || 20;

  try {
    $vendors.loadingStart();
    const baseRequest = {
      skip: (page - 1) * take,
      take,
      sorting: selectedSorting,
    };

    if (searchTerm) {
      baseRequest.name = searchTerm;
    }
    const response = await getAllVendors(baseRequest);
    $vendors.update(response);
  } catch (error) {
    handleNotification(error);
  } finally {
    $vendors.loadingEnd();
  }
};

export const setVendorsFromAutocomplete = async () => {
  try {
    $vendors.loadingStart();
    $vendorsUI.update({ isSearchListOpen: false });
    if (!$searchKey.value) {
      await fetchAndSetVendors();
    } else {
      $vendors.update({
        items: $vendorsSearchCache.value[$searchKey.value] || [],
        totalCount: $vendorsSearchCache.value[$searchKey.value]?.length || 0,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $vendors.loadingEnd();
  }
};

export const handleSelectSorting = (newField) => {
  const { selectedSorting } = $vendorsFilter.value;
  const { field, order } = selectedSorting[0];
  let newOrder = order === 'ASC' ? 'DESC' : 'ASC';

  if (newField !== field) newOrder = 'ASC';

  $vendorsFilter.update({
    selectedSorting: [{
      order: newOrder,
      field: newField,
    }],
  });
};

export const handleSelectSearchItem = async ({ id, isBreakpointDown }) => {
  const vendor = { id };
  handleSelectVendor({
    vendor,
    isBreakpointDown,
  });
  setVendorsFromAutocomplete();
};

export const handleClearVendorsSearch = async () => {
  $vendorsFilter.update({ search: '', vendorId: null });
  $selectedVendor.reset();
  await fetchAndSetVendors();
};

export const vendorSortCallback = (a, b) => {
  const { selectedSorting } = $vendorsFilter.value;
  const { field, order } = selectedSorting[0];

  if (a?.[field] < b?.[field] || a.invoice_summary?.[field] < b.invoice_summary?.[field]) {
    return order === 'ASC' ? -1 : 1;
  }
  if (a?.[field] > b?.[field] || a.invoice_summary?.[field] > b.invoice_summary?.[field]) {
    return order === 'ASC' ? 1 : -1;
  }

  return 0;
};

export const updateSingleSignalVendor = ({ vendor }) => {
  if (!vendor || !$vendors.value.items) return;

  const updatedList = [...$vendors.value.items];
  const listIndex = updatedList.findIndex((v) => v.id === vendor.id);

  if (listIndex === -1) return;

  updatedList[listIndex] = vendor;

  $vendors.update({ items: updatedList });
  if ($selectedVendor.value.id === vendor.id) $selectedVendor.update(vendor);
};

export const handleOpenStatement = async ({ vendorId }) => {
  const vendor = $vendors.value.items.find(({ id }) => id === vendorId);
  if (!vendor) return;

  if (!vendor.connection?.is_connected) {
    $vendorsUI.update({ isConnectionModalOpen: true });
  } else {
    history.push(`/vendors/statement/${vendor.id}`);
  }
};
