import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import editImg from 'images/edit.svg';
import AddBillManualSelectModal from 'components/views/BillAdd/_components/AddBillManualSelectModal';

const AddBillManualSelect = ({ handleNext }) => (
  <>
    <Row>
      <Col className="text-center">
        <h1 className="text-primary fw-700 pt-16 pb-24">Upload or add bills manually</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        <h3 className="pb-56 fw-400">Select your preferred way of adding bills</h3>
      </Col>
    </Row>
    <Container className="pb-48">
      <Card onClick={() => handleNext({ current: 'manualSelect', next: 'form' })} style={{ maxWidth: 620 }} className="cursor-pointer py-16 mb-24 justify-content-left mx-auto">
        <Container>
          <Row className="d-flex justify-content-left align-items-center px-16">
            <Col lg={2}>
              <Image width={54} src={editImg} />
            </Col>
            <Col lg={9} className="text-start text-dark">
              <h5 className="fw-700">Enter bill details</h5>
              <p className="base mb-0">Add bill details manually</p>
            </Col>
          </Row>
        </Container>
      </Card>
    </Container>
    <AddBillManualSelectModal />
  </>
);

export default AddBillManualSelect;
