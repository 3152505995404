import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import {
  $sidePanelFormDataContacts,
  $sidePanelSettingsContacts,
  handleCloseModal,
  handleCreateContact,
  handleUpdateContact,
} from 'components/global/SidePanel/SidePanel.helpers';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import { Col, Form, Row } from 'react-bootstrap';

export const SidePanelContactsModal = ({ type }) => {
  const formData = $sidePanelFormDataContacts.value;
  const { isModalOpen, isEditingContact } = $sidePanelSettingsContacts.value;

  return (
    <SimpleModal
      isShow={isModalOpen}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}
      title={isEditingContact ? 'Update Contact' : 'New Contact'}
      onSave={isEditingContact ? () => handleUpdateContact({ type }) : () => handleCreateContact({ type })}
    >
      <>
        <Row>
          <Col className="mt-16">
            <UniversalInput
              placeholder="Name"
              name="name"
              signal={$sidePanelFormDataContacts}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-16">
            <UniversalInput
              placeholder="Email address"
              name="email"
              signal={$sidePanelFormDataContacts}
            />
          </Col>
        </Row>
        <Row>
          <Col className="mt-16">
            <PhoneNumberInput
              placeholder="Phone number (optional)"
              name="phone_number"
              signal={$sidePanelFormDataContacts}
              className="mb-16"
            />
          </Col>
        </Row>
        {/* NOT MVP */}
        <Row className="d-none">
          <Col className="mt-40 d-flex align-items-center">
            <div className="text-dark ms-8 me-16">Send reminders</div>
            <Form.Check
              inline
              checked={formData.enableReminders}
              name="group1"
              type="radio"
              id="reminders-on"
              className="d-inline me-0"
              onClick={() => $sidePanelFormDataContacts.update({
                formData: { ...formData, enableReminders: true },
              })}
            />
            <div className="text-dark fw-800 ms-8 me-16">yes</div>
            <Form.Check
              inline
              checked={!formData.enableReminders}
              name="group1"
              type="radio"
              id="reminders-off"
              className="d-inline me-0"
              onClick={() => $sidePanelFormDataContacts.update({ enableReminders: false })}
            />
            <div className="text-dark fw-800 ms-8 me-16">no</div>
          </Col>
        </Row>
      </>
    </SimpleModal>
  );
};

export default SidePanelContactsModal;
