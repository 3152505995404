import { Image, Container, Row, Col } from 'react-bootstrap';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { $intuit } from 'signals/Authentication.signals';
import { $addBill, $addBillManual, $billForm, handleBackButton, handleNext } from 'components/views/BillAdd/BillAdd.helpers';
import arrowLeft from 'images/arrowLeft.svg';
import closeButton from 'images/closeButton.svg';
import triangleBg from 'images/yellowtrianglebackground.svg';
import squareBg from 'images/bluesquarebackground.svg';
import AddBillMethod from './_components/AddBillMethod';
import AddBillManualSelect from './_components/AddBillManualSelect';
import AddBillManualForm from './_components/AddBillManualForm';
import AddBillManualUpload from './_components/AddBillManualUpload';
import AddBillSuccessfulUpload from './_components/AddBillSuccessfulUpload';

const AddBill = () => {
  const { currentScreen, backButtonHistory } = $addBill.value;
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const name = urlParams.get('name');
  const uid = urlParams.get('uid');

  useEffect(() => {
    $addBill.update({
      currentScreen: $intuit?.value?.redirectUrl ? 'sync' : 'method',
    });
    if (name && uid) {
      $addBillManual.reset();
      $addBill.reset();
      handleNext({ current: 'method', next: 'form', navigationHistory: [...$addBill.value.backButtonHistory] });
      $addBillManual.update({
        vendorSearchTerm: name,
        selectedCompany: {
          name,
          value: uid,
        },
      });
      $billForm.update({
        organization_vendor_id: uid,
      });
    }

    return () => $addBill.update({ backButtonHistory: [] });
  }, []);

  return (
    <Container
      fluid
      className="min-vh-100 px-0 w-100 text-center position-relative"
      style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}
    >
      <Image className="position-absolute z-index-0 top-0 end-0" src={triangleBg} />
      <Image className="position-absolute bottom-0 start-0 z-index-0" src={squareBg} />
      <div className="d-flex min-vh-100 p-auto justify-content-center">
        <Container style={{ maxWidth: '1200px' }} className="z-index-1 pt-80 pt-lg-152">
          <Row>
            <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
              <Image
                src={arrowLeft}
                className="cursor-pointer"
                onClick={() => handleBackButton([...backButtonHistory])}
              />
              <Image
                src={closeButton}
                className="cursor-pointer z-index-1"
                onClick={() => history.push('/bills')}
              />
            </Col>
          </Row>
          {{
            method: <AddBillMethod />,
            manualSelect: <AddBillManualSelect />,
            form: <AddBillManualForm />,
            upload: <AddBillManualUpload />,
            success: <AddBillSuccessfulUpload />,
          }[currentScreen]}
        </Container>
      </div>
    </Container>
  );
};

export default AddBill;
