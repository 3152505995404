import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Money from 'components/global/Money';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import { $newBankInfo, handleBankInfoSubmit, handleBankInfoCancel } from 'components/views/Statement/Old/Statement.helpers';

const BankInfo = ({ className }) => (
  <Container className={className}>
    <Row className="p-24 pb-8 border-2 border-gray-800 mb-8 border-bottom">
      <h2 className="mt-24 fw-700 text-primary">Payment Details</h2>
    </Row>
    <div className="d-flex flex-column px-80 pt-40 pb-72">
      <Form className="mb-0 px-32 pb-16">
        <Form.Group className="form-group mb-24 mt-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="routingNumber">
            <p className="lead fw-800 mb-0">Routing number</p>
          </Form.Label>
          <UniversalInput
            placeholder="#########"
            name="routingNumber"
            className="mt-8 mb-32"
            signal={$newBankInfo}
          />
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="accountNumber"><p className="lead fw-800 mb-0">Account number</p></Form.Label>
          <UniversalInput
            placeholder="#########"
            name="accountNumber"
            className="mt-8"
            signal={$newBankInfo}
          />
        </Form.Group>
      </Form>
      <Row className="d-flex justify-content-start align-content-center pt-24 px-32">
        <Col sm={8} xs={8} className="d-flex justify-content-start align-items-center">
          <Button
            variant="primary"
            className="flex-grow-1 px-40 px-16 py-8"
            onClick={() => handleBankInfoSubmit()}
            disabled={
              !$newBankInfo?.value?.routingNumber ||
              !$newBankInfo?.value?.accountNumber
            }
          >
            <small className="text-primary me-8">
              PAY
            </small>
            <Money amount={25000} className="text-primary" />
          </Button>
        </Col>
        <Col sm={4} xs={4} className="d-flex justify-content-end ps-0">
          <Button
            variant="outline-primary"
            label="CANCEL"
            className="flex-grow-1 py-8 text-dark"
            onClick={handleBankInfoCancel}
          >
            <small className="text-dark">
              CANCEL
            </small>
          </Button>
        </Col>
      </Row>
    </div>
  </Container>
);

export default BankInfo;
