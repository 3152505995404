import { useSignal } from '@preact/signals-react';
import { useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const height = '34px';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: height, // Adjust the min-height as needed
    height, // Set the height as needed
    borderColor: 'var(--bs-primary)',
    borderRadius: '5px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'var(--bs-primary)',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    height, // Match the height of the control
    padding: '0 8px', // Adjust padding as needed
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height,
  }),
  option: (provided, state) => {
    let backgroundColor = null;
    let color = null;
    if (state.isSelected) {
      backgroundColor = 'var(--bs-primary)';
      color = 'var(--bs-white)';
    }

    if (state.isFocused) {
      backgroundColor = 'var(--bs-primary)';
      color = 'var(--bs-white)';
    }

    return {
      ...provided,
      backgroundColor,
      '&:hover': { backgroundColor },
      color,
    };
  },
};

const SimpleSelect = ({
  options,
  value,
  onChange,
  isLoading,
  isDisabled,
  isAsync = false,
  isSearchable = true,
  loadOptions = async () => { },
  placeholder = 'Select...',
}) => {
  // Local Signals rather than using State
  const $options = useSignal(options);
  const $value = useSignal(null);

  useEffect(() => {
    $options.value = options;
    if ($options.value.length > 0) {
      $value.value = $options.value.find((option) => option.value === value);
    }
  }, [options]);

  useEffect(() => {
    $options.value = options;
    if ($options.value.length > 0) {
      $value.value = $options.value.find((option) => option.value === value);
    }
  }, [value]);

  return (
    <>
      {!isAsync ? (
        <Select
          placeholder={placeholder}
          styles={customStyles}
          options={$options.value}
          onChange={onChange}
          isClearable
          isLoading={isLoading}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          value={$value.value || null}
        />
      ) : (
        <AsyncSelect
          styles={customStyles}
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          placeholder="Select an option"
          className="border border-primary"
          classNamePrefix="react-select"
        />
      )}
    </>
  );
};

export default SimpleSelect;
