export const formatPhoneNumForApi = (num, nullable = true) => {
  if (!num && nullable) return null;
  if (!num) throw new Error('Empty phone number');
  const formattedNum = String(num).replace(/[^0-9+]+/g, '');

  if (formattedNum.startsWith('+')) return num;

  if (formattedNum.startsWith('1') && formattedNum.length === 11) return `+${formattedNum}`;

  if (formattedNum.length === 10) return `+1${formattedNum}`;

  throw new Error('Invalid phone number');
};

export default formatPhoneNumForApi;
