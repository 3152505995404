import moment from 'moment';
import { accruClient } from 'api';
import Signal from 'signals/Signal';
import csvToJson from 'utils/csvToJson';
import { validateLength } from 'utils/validateInput';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { $invoice } from 'components/views/Invoices/Invoices.helpers';
import { $searchableDropdown } from 'signals/Global.signals';
import logEvent from 'utils/logEvent';
import { effect } from '@preact/signals-react';
import { sendInvoiceDetailEmail } from 'components/views/InvoiceDetail/InvoiceDetail.helpers';

export const $invoiceAdd = Signal({
  currentScreen: '',
  backButtonHistory: [],
});

effect(() => {
  if ($invoiceAdd.value.currentScreen) {
    logEvent(`add_invoice.${$invoiceAdd.value.currentScreen}`);
  }
});

export const $invoiceAddSelect = Signal({
  infoModalOpen: false,
});

const invoiceFormInitialState = {
  number: null,
  description: '',
  due_date: null,
  amount: null,
  organization_customer_id: null,
};

export const $invoiceForm = Signal(invoiceFormInitialState);

export const $invoiceManualForm = Signal({
  allCustomers: [],
  customerSearchTerm: '',
  selectedCompany: null,
});

export const $invoiceUpload = Signal([]);

export const createInvoice = async ({ organizationId, data }) => {
  try {
    const newInvoice = await accruClient.invoices.create({
      organizationId,
      data,
    });
    return newInvoice;
  } catch (error) {
    handleNotification(error);
  }
};

export const handleAddInvoice = async ({ handleNext, send }) => {
  try {
    $invoice.loadingStart();
    const invoicesToAdd = $invoiceUpload.value.length
      ? $invoiceUpload.value
      : [$invoiceForm.value];

    const inv = invoicesToAdd[0]; // refactored while we feature-gate csv
    const discountAmount = 0; // hardcoded until discount feature
    let amountFormatted = inv.amount;
    if (amountFormatted.toString().includes(',')) {
      amountFormatted = parseFloat(amountFormatted.replace(/,/g, ''));
    }
    const totalAmount = Number(amountFormatted) * 100;
    validateLength(totalAmount, 'Amount', 0, 100);
    validateLength(inv.description, 'Description', 0, 100);
    validateLength(inv.due_date, 'Due date', 0, 100);
    validateLength(inv.organization_customer_id, 'Customer', 0, 100);
    const newInvoice = await createInvoice({
      organizationId: $user?.value?.currentOrganization?.id,
      data: {
        currency_code: 'USD',
        description: inv.description,
        discount_amount: discountAmount,
        amount: Number(totalAmount),
        total_amount: Number(totalAmount),
        invoice_date: moment(),
        due_date: inv.due_date,
        organization_project_id: null,
        organization_customer_id: inv.organization_customer_id,
      },
    });
    if (newInvoice) {
      $invoice.update({ allInvoices: [...[...$invoice.value.allInvoices || []], newInvoice] });
      if (send) {
        await sendInvoiceDetailEmail({ id: newInvoice.id });
      }
      $invoiceForm.reset();
      $invoiceManualForm.reset();
      $searchableDropdown.reset();

      handleNotification('Invoice created!', { variant: 'success' });
      handleNext({ current: 'preview', next: 'successfulSend' });
    } else {
      handleNotification('Invoice creation was unsuccessful', { variant: 'danger' });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $invoice.loadingEnd();
  }
};

export const handleFileChange = async (event) => {
  try {
    $invoiceUpload.loadingStart();
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const textResult = e.target.result;
      const converted = csvToJson(textResult);
      $invoiceUpload.update(converted);
    };
    reader.readAsText(fileObj);

    const tempArray = $invoiceUpload.value;
    tempArray.push({ fileName: fileObj.name, fileSize: fileObj.size });

    $invoiceUpload.update({
      uploadedFiles: tempArray,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $invoiceUpload.loadingEnd();
  }
};

export async function fetchAndSetCustomers() {
  const { customerSearchTerm } = $invoiceManualForm.value;
  try {
    $invoiceManualForm.loadingStart();
    if (!customerSearchTerm || customerSearchTerm.length < 3) {
      $invoiceManualForm.update({
        allCustomers: [],
      });
      return;
    }

    const customerRecords = await accruClient.customers.get({ name: customerSearchTerm, organizationId: $user.value.currentOrganization?.id });
    const labeledCustomers = customerRecords?.items?.map(c => ({
      name: c.name,
      value: c.id,
      metaData: {
        email: c.email,
      },
    }));
    $invoiceManualForm.update({
      allCustomers: labeledCustomers || [],
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $invoiceManualForm.loadingEnd();
  }
}
