import { memo, useEffect, useState } from 'react';

import { handlePaymentGatewayLoadingFailure } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import NuveiCreditCardPayment from './NuveiCreditCardForm';

const loadScript = (src) => new Promise((resolve, reject) => {
  const existingScript = document.querySelector(`script[src="${src}"]`);
  if (existingScript) {
    resolve();
    return;
  }

  const scriptElem = Object.assign(document.createElement('script'), {
    type: 'text/javascript',
    defer: true,
    src,
    onerror: (e) => {
      reject(e);
    },
  });
  scriptElem.onload = () => {
    resolve();
  };
  document.body.appendChild(scriptElem);
});

const NuveiPaymentModule = memo(({ config }) => {
  if (!config) return <></>;
  const {
    merchant_id,
    merchant_site_id,
    environment,
  } = config;
  if (!merchant_id || !merchant_site_id || !environment) return <></>;

  const [safeCharge, setSafeCharge] = useState(null);

  useEffect(() => {
    loadScript(
      'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js',
    ).then(() => {
      setSafeCharge(
        window.SafeCharge({
          env: environment,
          merchantId: merchant_id,
          merchantSiteId: merchant_site_id,
        }),
      );
    }).catch(() => handlePaymentGatewayLoadingFailure());
  }, []);

  return (
    <NuveiCreditCardPayment safeCharge={safeCharge} />
  );
});

export default NuveiPaymentModule;
