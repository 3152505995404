/* eslint-disable no-useless-escape */
import { Col, Row } from 'react-bootstrap';
import { $settingsLogsUI } from 'components/views/SettingsLogs/_helpers/SettingsLogs.signals';
import isOddOrEven from 'utils/oddOrEven';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

const SettingsLogsDetailModal = () => {
  const { isDetailModalOpen, selectedLog } = $settingsLogsUI.value;
  const sanitize = (str) => (
    str
      .replaceAll('&#39;', '\'')
      .replaceAll('&quot;', '\"')
      .replaceAll('&amp;', '\&')
  );

  return (
    <SimpleModal
      isHideSave
      isShow={isDetailModalOpen}
      onClose={() => $settingsLogsUI.update({ isDetailModalOpen: false })}
      onCancel={() => $settingsLogsUI.update({ isDetailModalOpen: false })}
      size="lg"
      cancelText="close"
      title="Sync Detail"
    >
      <Row className="text-start">
        {selectedLog?.errors?.length ? (
          <Col className="bg-white">
            {selectedLog?.errors?.map((error, idx) => (
              <Row key={error} className={isOddOrEven(idx) === 'Odd' ? 'bg-gray-100' : 'bg-none'}>
                <div className="py-8">{sanitize(error)}</div>
              </Row>
            ))}
          </Col>
        ) : (
          <Col className="bg-white">
            <div className="text-dark text-center py-16">
              No issues found
            </div>
          </Col>
        )}
      </Row>
    </SimpleModal>
  );
};

export default SettingsLogsDetailModal;
