import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import PhoneNumberInput from 'components/global/Inputs/PhoneNumberInput';
import $user from 'signals/User.signals';

import { $settingsOrganization, $settingsOrganizationUI } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.signals';
import { handleVerifyContactFormChange, updatePhoneFinish, updatePhoneStart } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.helpers';
import { cancelContactUpdate } from 'components/views/SettingsProfile/_helpers/SettingsProfile.helpers';

const ContactInfo = () => {
  const {
    phoneNumber,
    contactVerificationCode,
  } = $settingsOrganization.value;
  const { contactPage } = $settingsOrganizationUI.value;
  const { user } = $user.value;

  useEffect(() => {
    if (user) {
      $settingsOrganization.update({
        phoneNumber: user.phone_number,
      });
    }
  }, []);

  return (
    <Row className="mx-0">
      <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
        {contactPage === 'contact' && (
          <Form.Group>
            <Form.Label htmlFor="email"><strong>Phone Number</strong></Form.Label>
            <Row className="mx-0">
              <Col sm={9} className="p-0">
                <PhoneNumberInput
                  name="phoneNumber"
                  value={phoneNumber}
                  signal={$settingsOrganization}
                  className="mb-16"
                />
              </Col>
              <Col sm={3} className="p-0">
                <Button
                  variant="primary"
                  onClick={() => updatePhoneStart()}
                  className="w-100 mb-16"
                  disabled={!phoneNumber || phoneNumber === user.phone_number}
                >
                  UPDATE
                </Button>
              </Col>
            </Row>
          </Form.Group>
        )}
        {contactPage === 'validation' && (
          <Form.Group>
            <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
            <p className="base fw-400 text-dark text-center">
              For your security, we just need to make sure this is you. We sent you a 6 digit code to {phoneNumber}
            </p>
            <Row>
              <div className="px-0 me-8 d-flex justify-content-around">
                {contactVerificationCode.map((value, index) => (
                  <Form.Control
                    key={index}
                    className={`mb-40 h3 mx-8 text-center p-0 py-16 validation-form ${value === '' ? 'active' : ''}`}
                    type="text"
                    max="1"
                    value={value}
                    name="verificationCode"
                    onChange={(e) => handleVerifyContactFormChange(e, index, contactVerificationCode)}
                  />
                ))}
              </div>
            </Row>
            <Row className="mx-0 px-0">
              <Col className="ps-0">
                <Button
                  variant="outline-primary"
                  onClick={() => cancelContactUpdate()}
                  className="w-100 mb-16 mt-24"
                >
                  CANCEL
                </Button>
              </Col>
              <Col className="pe-0">
                <Button
                  variant="primary"
                  onClick={() => updatePhoneFinish()}
                  className="w-100 mb-16 mt-24"
                  disabled={contactVerificationCode.includes('')}
                >
                  CONFIRM
                </Button>
              </Col>
            </Row>
          </Form.Group>
        )}

      </Row>
    </Row>
  );
};

export default ContactInfo;
