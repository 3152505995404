import { Card, Col, Row } from 'react-bootstrap';
import { $settingsSubscriptionUI, subscriptionBaseItemInfoMap, subscriptionItemCategoryMap } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import PriceOption from 'components/views/SettingsSubscription/_components/PriceOption';

const TotalCard = () => {
  const { selectedPricing } = $settingsSubscriptionUI.value;
  const { original_price_total_amount, price_total_amount, selected_base_item, renew_interval } = selectedPricing;
  const { item_type, category } = selected_base_item;
  const selectedPlanInfo = subscriptionBaseItemInfoMap[item_type];

  return (
    <Card className="bg-light border-primary-50 border p-24 mx-0 mt-24">
      <Row className="mx-0 mb-16">
        <Col xs={8} className="p-0 text-start">
          <h2 className="text-dark mb-0 fw-400">
            <span className="fw-bold">{selectedPlanInfo.title} {subscriptionItemCategoryMap[category] || ''}</span>
          </h2>
        </Col>
        <Col xs={4} className="d-flex align-items-center justify-content-end p-0 text-end">
          <h4><PriceOption className="text-primary fw-600" originalPrice={original_price_total_amount} currentPrice={price_total_amount} recurrence={renew_interval} /></h4>
        </Col>
      </Row>
      <Row>
        <h5 className="fw-300">{selectedPlanInfo.description}</h5>
      </Row>
    </Card>
  );
};

export default TotalCard;
