import { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { AuthLayout } from 'components/global/Layout';
import { $onboarding, initOnboarding, ONBOARDING_STEPS } from 'components/views/Onboarding/Onboarding.helpers';
import CompletedOnboarding from 'components/views/Onboarding/_components/CompletedOnboarding';
import AccountTypeForm from './_components/AccountTypeForm';
import PersonalInfoForm from './_components/PersonalInfoForm';
import CompanyInfoForm from './_components/CompanyInfoForm';

const Onboarding = () => {
  const { step } = $onboarding.value;

  useEffect(() => {
    initOnboarding();
  }, []);

  return (
    <AuthLayout>
      <Tabs activeKey={step} className="border-0 d-none">
        <Tab eventKey={ONBOARDING_STEPS.ACCOUNT_TYPE} title="Account Type">
          <AccountTypeForm />
        </Tab>
        <Tab eventKey={ONBOARDING_STEPS.PERSONAL_INFO} title="Personal Info">
          <PersonalInfoForm />
        </Tab>
        <Tab eventKey={ONBOARDING_STEPS.COMPANY_INFO} title="Company Info">
          <CompanyInfoForm />
        </Tab>
        <Tab eventKey={ONBOARDING_STEPS.COMPLETE} title="Complete">
          <CompletedOnboarding />
        </Tab>
      </Tabs>
    </AuthLayout>
  );
};

export default Onboarding;
