import { isOnboardingRequired } from 'components/helpers/User.helpers';
import { Redirect as RedirectDOM, useLocation } from 'react-router-dom';
import { $global } from 'signals/Global.signals';
import $user from 'signals/User.signals';
import isForbiddenOrganizationUser from 'utils/isForbiddenOrganizationUser';

const Redirect = ({ to }) => {
  const { search } = useLocation();
  const { isSignedIn } = $global.value;
  const { currentOrganization, user: { organizations = [] } } = $user.value;

  const RedirectUrl = isSignedIn ? '/onboarding' : '/login';

  if (isSignedIn) {
    if (isOnboardingRequired.value) {
      return (
        <RedirectDOM to={{
          pathname: '/onboarding',
          search,
        }}
        />
      );
    }

    if (isForbiddenOrganizationUser()) {
      return (
        <RedirectDOM to={{
          pathname: '/profile',
          search,
        }}
        />
      );
    }

    if (currentOrganization?.id && organizations?.length > 0) {
      return (
        <RedirectDOM to={{
          pathname: '/customers',
          search,
        }}
        />
      );
    }
  }

  return (<RedirectDOM to={to || RedirectUrl} />);
};

export default Redirect;
