import history from 'utils/history';
import $selectAdminOrganizationDetail from 'components/views/SelectAdminOrganization/SelectAdminOrganization.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { accruClient } from 'api';
import { selectAdminOrganization } from 'components/helpers/User.helpers';

export const fetchAndSetAdminOrganizations = async () => {
  try {
    $selectAdminOrganizationDetail.loadingStart();

    if (!$selectAdminOrganizationDetail.value.search?.length) {
      $selectAdminOrganizationDetail.update({
        items: [],
      });
      return;
    }

    const data = await accruClient.admin.organizations.get({
      name: $selectAdminOrganizationDetail.value.search,
    });

    $selectAdminOrganizationDetail.update({
      items: data.items,
    });
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $selectAdminOrganizationDetail.loadingEnd();
  }
};

export const handleSelectAdminOrganizationModalOpen = () => {
  $selectAdminOrganizationDetail.update({
    isModalOpen: true,
  });
};

export const handleSelectAdminOrganizationModalClose = () => {
  $selectAdminOrganizationDetail.reset();
};

export const handleSelectAdminOrganizationSearchClear = () => {
  $selectAdminOrganizationDetail.update({
    search: '',
  });
};

export const handleSelectAdminOrganizationListClick = (organization) => {
  selectAdminOrganization(organization);
  history.push('/');
  handleSelectAdminOrganizationModalClose();
};
