/* eslint-disable jsx-a11y/control-has-associated-label */
import { Tab, Tabs } from 'react-bootstrap';
import { AuthLayout } from 'components/global/Layout';
import EmailSignupForm from './_components/EmailSignupForm';
import VerificationForm from './_components/VerificationForm';
import PasswordForm from './_components/PasswordForm';
import { $signUpFunctionality } from './Signup.helpers';

const SignUp = () => (
  <AuthLayout>
    <Tabs activeKey={$signUpFunctionality.value?.currentPage} className="border-0 d-none">
      <Tab eventKey="emailForm" title="Email Form">
        <EmailSignupForm />
      </Tab>
      <Tab eventKey="verify" title="Verify">
        <VerificationForm
          invitedUser={false}
        />
      </Tab>
      <Tab eventKey="createPassword" title="Create Password">
        <PasswordForm />
      </Tab>
    </Tabs>
  </AuthLayout>
);

export default SignUp;
