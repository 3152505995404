import Loadable from 'components/global/Loadable';
import { handleSelectSearchItem } from 'components/views/Customers/_helpers/Customers.helpers';
import { $customersAutocomplete, $customersSearchCache, $customersUi } from 'components/views/Customers/_helpers/Customers.signals';
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap';

const CustomersAutoComplete = () => (
  <>
    {$customersUi.value.isSearchListOpen && (
      <Card className="border shadow py-16 position-absolute w-100" style={{ zIndex: 1000 }}>
        <Loadable signal={$customersSearchCache} template="spinner">
          <ListGroup as="ul">
            {$customersAutocomplete.value.map(({ name: customerName, id }, idx) => (
              <ListGroupItem
                as="a"
                action
                key={idx.toString()}
                onClick={() => handleSelectSearchItem({ id })}
                className="border-0 selectable"
              >
                {customerName}
              </ListGroupItem>
            ))}
            {!$customersAutocomplete.value.length && (
              <ListGroupItem className="border-0 text-dark fst-italic py-0">
                No results
              </ListGroupItem>
            )}
          </ListGroup>
        </Loadable>
      </Card>
    )}
  </>
);

export default CustomersAutoComplete;
