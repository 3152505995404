export const currencyFallback = '$ --.--';

/**
 * Formats the given amount in cents to a localized currency string.
 *
 * @param {Object} options - The options for formatting the currency.
 * @param {number|bigint|string} options.amountCents - The amount in cents to be formatted.
 * @param {string} options.currency - The currency code (e.g., "USD", "EUR").
 * @param {string} options.language - The language code (e.g., "en-US", "fr-FR").
 *
 * @returns {string} The formatted currency string.
 */
const formatCurrency = ({
  amountCents,
  currency = 'USD',
  language = 'en-US',
}) => {
  if (amountCents === null || amountCents === undefined) return currencyFallback;

  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  });

  const amount = String(amountCents).replace(/(?!^)-|[^0-9-]/g, '');
  if (!amount) return currencyFallback;

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format#using_format_with_a_string (not supported by TS yet)
  return formatter.format(`${amount}E-2`);
};

export default formatCurrency;
