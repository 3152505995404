import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Card, Image } from 'react-bootstrap';
import { ACCT_PROVIDER } from 'accru-client';

import useOAuthCallback from 'hooks/useOAuthCallback';
import { handleNotification } from 'components/global/Alert/Alert';
import { $syncing } from 'signals/Global.signals';
import { $accountingConnSettings, $accountingOAuth, $settingsAccounting, availableAcctIntegrations, fetchAndSetOrganizationAcctProviders, getAccountingOAuthUrl, handleAccountingOAuthCallback } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';

import ConnectionModal from 'components/views/SettingsAccounting/_components/ConnectionModal';
import QuickBooksImage from 'images/quickbooks-logo.png';
import Signal from 'signals/Signal';
import arrowLeft from 'images/arrowLeft.svg';
import closeButton from 'images/closeButton.svg';
import triangleBg from 'images/yellowtrianglebackground.svg';
import squareBg from 'images/bluesquarebackground.svg';
import QuickBooksButton from 'components/global/QuickBooksButton/QuickBooksButton';
// import freshbooksImg from 'images/freshbooks.svg';
// import waveLogoImg from 'images/wavelogo.svg';
// import zohoLogoImg from 'images/zohologo.svg';

const $connectAccountingSoftware = Signal({
  hasRequestedProcess: false,
});

const redirectTo = {
  customers: '/customers',
  vendors: '/vendors',
  invoices: '/invoices',
  bills: '/bills',
};

const Sync = ({ handleNext = () => null }) => {
  const history = useHistory();
  const { connections } = $settingsAccounting.value;
  const { hasRequestedProcess } = $connectAccountingSoftware.value;
  const { isSyncing, isFinished, referredBy } = $syncing.value;

  const isLoading = $accountingConnSettings.value.isLoading || $settingsAccounting.value.isLoading;
  const isConnecting = !!$accountingOAuth.value.provider;

  const { dispatchOAuth } = useOAuthCallback({
    signal: $accountingOAuth,
    onSuccess: handleAccountingOAuthCallback,
    onFailure: () => handleNotification('Something went wrong while connecting your accounting software. Please try again.', { variant: 'danger' }),
  });

  const handleAccountingSelect = async (provider) => {
    if (navigator?.userAgent?.includes('Safari') && !navigator?.userAgent?.includes('Chrome')) {
      handleNotification('Pop-up Blockers must be disabled', { variant: 'danger' });
    }
    const url = await getAccountingOAuthUrl(provider);
    dispatchOAuth(url, provider);
  };

  const ACCOUNTING_OPTIONS = useMemo(() => [{
    name: 'QuickBooks',
    provider: ACCT_PROVIDER?.QUICKBOOKS,
    image: QuickBooksImage,
    connectButton: <QuickBooksButton onClick={!isLoading ? () => handleAccountingSelect(ACCT_PROVIDER?.QUICKBOOKS) : null} />,
    width: 200,
    signUpLink: 'https://accounts.intuit.com/app/sign-in?app_group=Identity&asset_alias=Intuit.cto.iam.ius',
  }].map((option) => ({
    ...option,
    enabled: availableAcctIntegrations.some((acct) => acct.provider === option.provider),
    connected: connections?.items?.some((conn) => conn.acct_provider === option.provider),
  }), [connections]));
    // , {
    //   name: 'Freshbooks',
    //   provider: 'FRESHBOOKS',
    //   image: freshbooksImg,
    // }, {
    //   name: 'Zoho',
    //   provider: 'ZOHO',
    //   image: zohoLogoImg,
    // }, {
    //   name: 'Wave',
    //   provider: 'WAVE',
    //   image: waveLogoImg,
    // }]
    //  ]), [connections]);

  useEffect(() => {
    fetchAndSetOrganizationAcctProviders();
  }, []);

  useEffect(() => {
    if (hasRequestedProcess && !isSyncing && !isLoading && !isConnecting && isFinished && !!redirectTo[referredBy]) {
      history.replace(redirectTo[referredBy]);
      $connectAccountingSoftware.update({ hasRequestedProcess: false });
    }
  }, [
    hasRequestedProcess,
    isSyncing,
    isLoading,
    isConnecting,
    isFinished,
    history,
  ]);

  return (

    <Container
      fluid
      className="min-vh-100 px-0 w-100 text-center position-relative"
      style={{ backgroundColor: 'rgb(231, 192, 170, 0.4)' }}
    >
      <Image className="position-absolute z-index-0 top-0 end-0" src={triangleBg} />
      <Image className="position-absolute bottom-0 start-0 z-index-0" src={squareBg} />
      <div className="d-flex min-vh-100 p-auto justify-content-center">
        <Container style={{ maxWidth: '1200px' }} className="z-index-1 pt-80 pt-lg-152">
          <Row>
            <Col sm={{ span: 10, offset: 1 }} offset={1} className="d-flex justify-content-between">
              <Image
                src={arrowLeft}
                className="cursor-pointer"
                data-cy="back-button"
                onClick={() => history.goBack()}
              />
              <Image
                src={closeButton}
                className="cursor-pointer z-index-1"
                onClick={() => history.push(`/${referredBy}`)}
              />
            </Col>
          </Row>

          <Container className="pb-48">
            <Row>
              <Col className="text-center">
                <h1 className="text-primary fw-700 pt-16 pb-24">Connect your accounting software</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="pb-64 pb-lg-112">We will automatically import all of your data.</h2>
              </Col>
            </Row>
            <Container>
              <Row className="d-flex justify-content-center">
                {ACCOUNTING_OPTIONS.map((option, index) => (
                  <Col sm={12} md={6} lg={4} key={index}>
                    {/* <Card onClick={() => option.enabled && !isLoading && handleAccountingSelect(option.provider)} style={{ border: option.enabled ? '1px solid #92DEE0' : 'none', maxHeight: '120px' }} className={`cursor-pointer custom-shadow pt-48 pb-48 align-items-center justify-content-center mb-16 mb-lg-0 ${option.enabled ? '' : 'disabled'}`}> */}
                    <Card style={{ border: option.enabled ? '1px solid #92DEE0' : 'none', maxHeight: '120px' }} className={`custom-shadow py-80 align-items-center justify-content-center mb-16 mb-lg-0 ${option.enabled ? '' : 'disabled'}`}>
                      {
                        isLoading ||
                          !connections?.items ||
                          (!!isConnecting && String($accountingOAuth.value.provider) === String(option.provider)) ||
                          (!!$syncing.value.providersInSync?.length && $syncing.value.providersInSync.find(provider => String(provider) === String(option.provider))) ? (
                            <div className="w-100 d-flex justify-content-center text-primary">
                              <div className="spinner-border" />
                            </div>
                          ) : (
                            <>
                              <Image width={option.width} src={option.image} className="my-8" />
                              {!option.enabled && <p className="lead text-dark fw-800 mb-0 pt-8">coming soon</p>}
                              {option.enabled && !option.connected && !option.connectButton && <p className="lead text-dark fw-800 mb-0 pt-8">connect now</p>}
                              {option.enabled && !option.connected && option.connectButton && <div className="mt-8">{option.connectButton}</div>}
                            </>
                          )
                      }
                    </Card>
                    {option.enabled && option?.signUpLink && (
                      <Container className="text-center p-0 h6 fw-400 mt-16">
                        Don&apos;t have an Intuit account?
                        {' '}
                        <a
                          href={option?.signUpLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-primary"
                        >
                          Sign Up
                        </a>
                      </Container>
                    )}
                  </Col>
                ))}
              </Row>
            </Container>
          </Container>

          <ConnectionModal actionLabel="Continue" actionCallback={() => typeof handleNext === 'function' && handleNext()} />
        </Container>
      </div>
    </Container>
  );
};

export default Sync;
