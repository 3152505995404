import { $accountingConnSettings, $accountingOAuth, availableAcctIntegrations, isValidAcctConnUpdateData, matchingConfigLabels, updateAccountingConnection } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
import { Button, Container, Dropdown, Form, Row } from 'react-bootstrap';

const ConnectionOptions = ({ type }) => {
  const { connection, updateData } = $accountingConnSettings.value;
  const configLabels = matchingConfigLabels[connection.acct_provider]?.[type];

  return (
    <>
      {Object.entries($accountingConnSettings.value.settingOptions[type]).map(([key, value]) => (
        <Row className="mt-16" key={key}>
          <h5 className="text-start">Select {configLabels[key].label || key}:</h5>
          <Dropdown
            onSelect={(str) => $accountingConnSettings.update({ updateData: { ...$accountingConnSettings.value.updateData, [type]: { ...$accountingConnSettings.value.updateData[type], [key]: str } } })}
          >
            <Dropdown.Toggle
              disabled={$accountingConnSettings.value.isLoading}
              variant="primary"
              className="rounded w-100 text-start bg-white d-flex align-items-center"
            >
              <h4 className="me-auto fw-400 mb-0">{value?.find(option => option.value === updateData[type][key])?.label || 'Select'}</h4>
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-8 w-100">
              {value.map(option => (
                <Dropdown.Item eventKey={option.value} className="rounded" key={option.value}>{option.label}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Row>
      ))}
    </>
  );
};

const ConnectionsSettings = (
  { actionLabel,
    actionCallback,
    onSave,
    exitAfterSave,
  },
) => {
  const { connection, updateData, settingOptions } = $accountingConnSettings.value;

  if (!connection || !updateData || connection.id !== updateData.id || !settingOptions?.pull_configuration || !settingOptions?.push_configuration || !matchingConfigLabels[connection.acct_provider]) { return <></>; }

  const providerInfo = !!connection.acct_provider && availableAcctIntegrations.find(acct => acct.provider === connection.acct_provider);

  const isUpdateDataValid = isValidAcctConnUpdateData();

  const handleConfirm = async (save, finish) => {
    if (!isUpdateDataValid) return;

    if (save) {
      await updateAccountingConnection();
      if (typeof onSave === 'function') onSave();
    }

    if (finish || exitAfterSave) {
      $accountingConnSettings.reset();
      $accountingOAuth.reset();
    }
  };

  return (
    <Container>
      <Row className="mt-16">
        <h4 className="fw-400 text-dark">Configure your {providerInfo.name} connection</h4>
      </Row>
      <ConnectionOptions type="pull_configuration" />
      {!!updateData?.automatic_push_enabled && (<ConnectionOptions type="push_configuration" />)}
      <Row>
        <Form className="text-start mt-16">
          <Form.Check
            type="checkbox"
            label={`Automatically pull from ${providerInfo.name}`}
            checked={updateData.automatic_pull_enabled}
            disabled={$accountingConnSettings.value.isLoading}
            onClick={() => $accountingConnSettings.update({ updateData: { ...$accountingConnSettings.value.updateData, automatic_pull_enabled: !updateData.automatic_pull_enabled } })}
            onChange={() => { }}
          />
          <Form.Check
            type="checkbox"
            label={`Automatically sync to ${providerInfo.name} (Coming soon!)`}
            disabled
            checked={updateData.automatic_push_enabled}
            onClick={() => $accountingConnSettings.update({ updateData: { ...$accountingConnSettings.value.updateData, automatic_push_enabled: !updateData.automatic_push_enabled } })}
            onChange={() => { }}
          />
        </Form>
      </Row>
      <Row className="mt-16">
        <Button onClick={() => handleConfirm(true)} variant="primary" disabled={$accountingConnSettings.value.isLoading || !isUpdateDataValid} className="px-64">
          CONFIRM SETTINGS
        </Button>
      </Row>
      {typeof actionLabel === 'string' && typeof actionCallback === 'function' && (
        <Row className="mt-16">
          <Button onClick={() => actionCallback(handleConfirm)} variant="outline-primary" disabled={$accountingConnSettings.value.isLoading || !isUpdateDataValid} className="px-64">
            {actionLabel}
          </Button>
        </Row>
      )}
    </Container>
  );
};

export default ConnectionsSettings;
