import { accruClient } from 'api';
import $user from 'signals/User.signals';

export const getAllConnections = async ({ status }) => {
  const data = await accruClient.connections.get({
    organizationId: $user.value.currentOrganization.id,
    ...(!!status && { status }),
  });

  return data;
};

export const inviteCustomer = async ({ organizationCustomerId }) => {
  const data = await accruClient.connections.inviteCustomer({
    organizationId: $user.value.currentOrganization.id,
    organizationCustomerId,
  });

  return data;
};

export const inviteVendor = async ({ organizationVendorId }) => {
  const data = await accruClient.connections.inviteVendor({
    organizationId: $user.value.currentOrganization.id,
    organizationVendorId,
  });

  return data;
};

export const acceptConnection = async ({ organizationConnectionId }) => {
  const data = await accruClient.connections.acceptConnection({
    organizationId: $user.value.currentOrganization.id,
    organizationConnectionId,
  });

  return data;
};

export const rejectConnection = async ({ organizationConnectionId }) => {
  const data = await accruClient.connections.rejectConnection({
    organizationId: $user.value.currentOrganization.id,
    organizationConnectionId,
  });

  return data;
};
