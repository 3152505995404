import { accruClient } from 'api';
import moment from 'moment';
import Signal from 'signals/Signal';
import history, { historyPush } from 'utils/history';
import { handleNotification } from 'components/global/Alert/Alert';
import $user from 'signals/User.signals';
import logEvent from 'utils/logEvent';
import normalizeTextCase from 'utils/normalizeTextCase';
import isValidUrl from 'utils/isValidUrl';
import { ORGANIZATION_ACCT_PROVIDER_CONN_STATUS } from 'accru-client';
import openBase64Pdf from 'utils/openBase64Pdf';

export const defaultStatementSortingOption = [{ field: 'date', order: 'DESC' }];
export const statementLinesPerPage = 100;

export const $statement = Signal({
  viewerMode: null,
  targetId: null,
  unconnectedRequestData: null,
  items: [],
});

export const $unconnectedInvoice = Signal({
  data: {},
  isLoading: true,
  showModal: true,
});

export const $statementParams = Signal({
  startDate: moment().startOf('month').local(),
  endDate: moment().endOf('month').local(),

  sorting: defaultStatementSortingOption,

  skip: 0,
  take: statementLinesPerPage,
});

export const $statementUi = Signal({
  selectedItems: [],
  page: 1,
  isDateRangeOpen: false,
  isViewOnlyItemModalOpen: false,
  viewOnlyItem: null,
});

export const $activeForm = Signal('ChoosePaymentReturning');
export const $skipToDetails = Signal({ isVisible: false });
export const $paymentDetails = Signal({ checkedBills: [] });
export const $newCheckSentInfo = Signal({
  checkNumber: '',
  deliveryDate: '',
});
export const $newCardInfo = Signal({
  holderName: '',
  cardNumber: '',
  expDate: '',
  cvc: '',
});
export const $cardsArray = Signal([]);
export const $currentCard = Signal({});
export const $newBankInfo = Signal({
  routingNumber: '',
  accountNumber: '',
});

export async function fetchAndSetStatement({
  pathName,
  pathParams,
  queryParams,
}) {
  $statement.loadingStart();
  $statementUi.update({ selectedItems: [] });

  try {
    if (!pathName || !pathParams || !queryParams || !(queryParams instanceof URLSearchParams)) throw new Error('Invalid request.');

    const isLogged = !!$user?.value?.currentOrganization?.id;
    const targetId = pathParams.id;
    const unconnectedRequestData = {
      token: queryParams.get('token'),
      email: queryParams.get('email'),
      expires_at: moment(queryParams.get('expires_at')),
    };

    if (!targetId) throw new Error('Invalid target id.');

    const selectedParams = $statementParams.value;
    const params = {
      currency: selectedParams.currency,
      startDate: moment(selectedParams.startDate).local(),
      endDate: moment(selectedParams.endDate).local(),
      isOverdue: selectedParams.isOverdue,

      after: selectedParams.after,
      first: selectedParams.first,

      before: selectedParams.before,
      last: selectedParams.last,

      skip: selectedParams.skip,
      take: selectedParams.take,

      sorting: selectedParams.sorting,
    };

    let data = null;
    let viewerMode = null;
    if (isLogged && pathName.includes('/customers')) {
      viewerMode = 'VENDOR';
      data = await accruClient.customers.getStatement({
        organizationId: $user.value.currentOrganization.id,
        organizationCustomerId: targetId,
        ...params,
      });
    } else if (isLogged && pathName.includes('/vendors')) {
      viewerMode = 'CUSTOMER';
      data = await accruClient.statements.get({
        organizationId: $user.value.currentOrganization.id,
        organizationVendorId: targetId,
        ...params,
      });
    } else {
      viewerMode = 'UNCONNECTED_CUSTOMER';

      if (!!unconnectedRequestData.token && !!unconnectedRequestData.email && !!unconnectedRequestData.expires_at) {
        data = await accruClient.statements.getUnconnectedStatement({
          email: unconnectedRequestData.email,
          token: unconnectedRequestData.token,
          uniqueCode: targetId,
          ...params,
        });
      } else {
        $statement.update({
          viewerMode,
          unconnectedRequestData,
        });
        return;
      }
    }

    $statement.update({
      ...data,
      viewerMode,
      targetId,
      unconnectedRequestData,
    });

    logEvent({ name: 'view_statement' });
  } catch (error) {
    handleNotification(error);
    $statementParams.reset();
    $statement.reset();
    history.replace(pathName.includes('/customers') ? '/customers' : '/vendors');
    $statement.loadingEnd();
  } finally {
    $statement.loadingEnd();
  }
}

export const handleFieldSortingToggle = (selectedField) => {
  const { sorting } = $statementParams.value;
  const { field, order } = sorting[0];

  let targetOrder = order === 'ASC' ? 'DESC' : 'ASC';
  if (selectedField !== field) targetOrder = 'DESC';

  $statementParams.update({
    sorting:
      order === 'ASC' && field === selectedField
        ? defaultStatementSortingOption
        : [{
          field: selectedField,
          order: targetOrder,
        }],
  });
};

export const handleSelectItemToggle = (id) => {
  const { selectedItems } = $statementUi.value;
  $statementUi.update({
    selectedItems: selectedItems.includes(id)
      ? selectedItems.filter((i) => i !== id)
      : [...selectedItems, id],
  });
};

export const handleSelectAllToggle = () => {
  const { selectedItems } = $statementUi.value;
  const ids = $statement.value.items.map((item) => item.id);
  $statementUi.update({
    selectedItems: selectedItems.length && selectedItems.every((i) => ids.includes(i))
      ? []
      : ids,
  });
};

export const isStatementItemOverdue = (item) => moment(item.due_date).isBefore(moment(), 'day');
export const isStatementItemPaid = (item) => item.paid_amount >= item.amount;

export const getStatementItemStatus = (item) => {
  const text = normalizeTextCase(item.type === 'INVOICE' ? item.invoice_status : item.transaction_status);
  if (!isStatementItemPaid(item) && isStatementItemOverdue(item)) {
    return {
      text: 'Overdue',
      isOverdue: true,
    };
  }

  return {
    text,
    isOverdue: false,
  };
};

export const handleClickViewPay = async (item) => {
  $statement.loadingStart();
  try {
    if (!isStatementItemPaid(item) && item.type === 'INVOICE') {
      if (item.organization_invoice?.payment_options?.length) {
        const selectedPaymentOption = item.organization_invoice.payment_options.find(option => !!option.url);
        if (selectedPaymentOption && isValidUrl(selectedPaymentOption.url)) {
          window.open(selectedPaymentOption.url, '_blank');
        } else {
          throw new Error('Invalid payment URL - please contact your accounting provider');
        }
      } else {
        $statementUi.update({
          isViewOnlyItemModalOpen: true,
          viewOnlyItem: item,
        });
      }
      return;
    }

    const statement = $statement.value;

    const acctProvider = (item.organization_invoice?.organization_acct_provider_conn_invoices || item.organization_invoice_transaction?.organization_acct_provider_conn_invoice_transactions)?.find(itemConn => String(itemConn.organization_acct_provider_conn.status) === String(ORGANIZATION_ACCT_PROVIDER_CONN_STATUS.CONNECTED))?.organization_acct_provider_conn?.acct_provider;
    if (!acctProvider) throw new Error('No view/pay option available.');

    let pdfBase64 = null;
    if (statement.viewerMode === 'VENDOR') {
      if (item.type === 'INVOICE') {
        pdfBase64 = await accruClient.invoices.getAcctProviderPdf({
          acctProvider,
          organizationId: $user.value.currentOrganization.id,
          organizationInvoiceId: item.organization_invoice_id,
        });
      } else {
        pdfBase64 = await accruClient.transactions.getAcctProviderPDF({
          acctProvider,
          organizationId: $user.value.currentOrganization.id,
          organizationInvoiceTransactionId: item.organization_invoice_transaction_id,
        });
      }
    } else if (statement.viewerMode === 'CUSTOMER') {
      pdfBase64 = await accruClient.statements.getLinePDF({
        organizationId: $user.value.currentOrganization.id,
        organizationVendorId: statement.targetId,
        organizationCustomerStatementLineId: item.id,
        acctProvider,
        organizationInvoiceId: item.organization_invoice_id,
      });
    } else if (statement.viewerMode === 'UNCONNECTED_CUSTOMER' && statement.unconnectedRequestData) {
      pdfBase64 = await accruClient.statements.getUnconnectedStatementLinePDF({
        email: statement.unconnectedRequestData.email,
        token: statement.unconnectedRequestData.token,
        uniqueCode: statement.targetId,
        organizationCustomerStatementLineId: Number(item.id),
        acctProvider,
      });
    } else {
      throw new Error('Fail to setup view/pay.');
    }

    if (pdfBase64) { openBase64Pdf(pdfBase64); } else { throw new Error('Fail to open pdf.'); }
  } catch (error) {
    handleNotification(error);
  } finally {
    $statement.loadingEnd();
  }
};

export const getUnconnectedStatementSingleInvoice = async ({ email, token, uniqueCode, invoiceId }) => {
  $unconnectedInvoice.loadingStart();
  try {
    const invoice = await accruClient.statements.getUnconnectedStatementLine({
      email,
      token,
      uniqueCode,
      organizationInvoiceId: invoiceId,
      acctProvider: 'QUICKBOOKS',
    });
    $unconnectedInvoice.update({ data: invoice });
  } catch (error) {
    handleNotification(error);
  } finally {
    $unconnectedInvoice.loadingEnd();
  }
};

export function setActiveForm(value) {
  $activeForm.value = value;
}

export function handleCheckAllBills(checked) {
  $paymentDetails.update({
    checkedBills: checked ? $statement.value.items : [],
  });
}

export const resetAndExit = () => {
  setActiveForm('ChoosePayment');
  historyPush('/statement');
};

export function handleCheckBill(bill, checked) {
  const { checkedBills } = $paymentDetails.value;

  if (checked) {
    const tempArray = checkedBills;
    tempArray.push(bill);
    $paymentDetails.update({
      checkedBills: tempArray,
    });
  } else {
    const tempArray = checkedBills.filter(b => b.id !== bill.id);
    $paymentDetails.update({
      checkedBills: tempArray,
    });
  }
}

export const handleSubmit = () => {
  setActiveForm('Processing');
};

export const handleCancel = () => {
  setActiveForm('ChoosePayment');
};

export const handleSubmitCardInfo = () => {
  $currentCard.value = $newCardInfo.value;
  $cardsArray.value = [...$cardsArray.value, [$newCardInfo.value]];
  setActiveForm('Processing');
};

export const handleCancelCardInfo = () => {
  setActiveForm('ChoosePayment');
};

export const handleBankInfoSubmit = () => {
  setActiveForm('Processing');
};

export const handleBankInfoCancel = () => {
  setActiveForm('ChoosePayment');
};
