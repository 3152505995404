import { useCallback } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
// import ellipse277 from 'images/ellipse277.svg';
import $user from 'signals/User.signals';
import { isProdVersion } from 'components/views/MVP/MVP.helpers';

const BillHero = ({ data }) => {
  const { currentOrganization } = $user.value;
  const handleButton = useCallback(() => {});
  return (
    <Col md={12} lg={6} className="mb-sm-48">
      {/* <Row className="pb-16">
        <Image src={ellipse277} className="w-auto h-auto" />
      </Row> */}
      <Row>
        <h1 className="fw-400 text-dark">
          {data?.organization_vendor?.name || currentOrganization.name}
        </h1>
      </Row>
      {isProdVersion() && <h4 className="text-warning">Accru pay is coming Soon!</h4>}
      {isProdVersion() && <div className="w-75">They may not be clickable but they sure are pretty buttons right!? Here is whats upcoming in future releases of Accru.</div>}
      <div className="mb-48 mb-lg-32 mt-32 mt-lg-16 d-flex gap-16">
        <Button
          className="fw-400 px-40 px-md-24 py-8 border border-2 border-primary pill-btn-height w-auto"
          disabled
          onClick={() => handleButton()}
        >
          SET UP ACCRU PAY
        </Button>
        <Button
          variant="outline-primary"
          className="px-40 px-lg-24 py-8 fw-400 border border-2 border-primary text-dark pill-btn-height w-auto"
          disabled
          onClick={() => handleButton()}
        >
          PAYMENT PLANS
        </Button>
      </div>
      {isProdVersion() && (
        <>
          <Row>
            <h5 className="fw-700 text-dark">Why enroll in auto pay?</h5>
          </Row>
          <Row className="mb-8">
            <Col className="pe-0">
              <p className="lead fw-400 text-dark d-flex">Set it and forget!
                {' '}
                <a href="TODO: Add Route" className="lead fw-400 text-primary text-decoration-underline ms-8">Learn more about the benefits of autopay</a>
              </p>
            </Col>
          </Row>
          <Row className="mt-8">
            <h5 className="fw-700 text-dark pe-0">Why set up a payment plan?</h5>
          </Row>
          <Row>
            <Col className="pe-0">
              <Container className="lead fw-400 text-dark m-0 px-0">
                We get it... sometimes you need to budget your money and paying a bill in full is not the best for your business.
                {' '}
                <a href="TODO: Add Route" className="lead fw-400 text-primary text-decoration-underline">
                  Learn more about the benefits of setting up payment plans
                </a>
              </Container>
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
};

export default BillHero;
