import { Container, Row, Col } from 'react-bootstrap';
import Money from 'components/global/Money';
import { $customersUi, $customerDetail } from 'components/views/Customers/_helpers/Customers.signals';
import CustomerDetailTabs from 'components/views/Customers/_components/CustomerDetailTabs';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

const CustomerDetailModal = () => (
  <SimpleModal
    isHideSave
    isHideCancel
    isShow={$customersUi.value.isMobileModalOpen}
    onClose={() => $customersUi.update({
      isMobileModalOpen: false,
    })}
  >
    {$customerDetail.value && (
      <Container>
        <Row>
          <Col className="pt-16">
            <h1 className="mb-0">{$customerDetail.value.name}</h1>
          </Col>
        </Row>
        <Row className="py-16 my-16 bg-gray-50" style={{ borderRadius: '15px' }}>
          <Col className="d-flex justify-content-between align-items-center px-24">
            <div>
              <p className="lead fw-800 mb-0">Balance</p>
              <h3><Money amount={$customerDetail.value.balance} /></h3>
            </div>
            <div>
              <p className="lead fw-800 mb-0">Overdue</p>
              <h3><Money amount={$customerDetail.value.invoice_summary?.overdue_amount} /></h3>
            </div>
            {/* <div>
              <Button variant="primary" onClick={() => history.push('/payment', { from: history.location })} className="px-32">PAY BILLS</Button>
            </div> */}
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col className="bg-white px-0">
            <CustomerDetailTabs />
          </Col>
        </Row>
      </Container>
    )}
  </SimpleModal>
);

export default CustomerDetailModal;
