import { useMemo } from 'react';
import { Row } from 'react-bootstrap';
import { $settingsSubscriptionPurchaseDetail } from 'components/views/SettingsSubscription/SettingsSettingsSubscription.helpers';
import Loader from 'components/global/Loader';
import Countdown from 'components/views/SettingsSubscription/_components/Countdown';
import TotalCard from './TotalCard';
import NuveiPaymentModule from './nuvei/NuveiPaymentModule';

const PaymentInformation = () => {
  const { preTransactionData, isLoading, isProcessingPayment, isPurchaseSessionLoading } = $settingsSubscriptionPurchaseDetail.value;

  const CreditCardForm = useMemo(() => {
    $settingsSubscriptionPurchaseDetail.loadingStart();
    return (<NuveiPaymentModule config={preTransactionData} />);
  }, [preTransactionData]);

  return (
    <>
      <TotalCard />
      <Countdown />
      <div style={{ position: 'relative' }}>
        <Row className="mx-0 mt-24 mb-24 py-24 bg-gray-50 rounded">
          <h2 className="fw-700 mb-16">Payment Information</h2>
          {CreditCardForm}
        </Row>

        {(!!isLoading || !!isProcessingPayment || !!isPurchaseSessionLoading) && (
          <div className="cursor-wait d-flex align-items-start justify-content-center bg-white position-absolute top-0 start-0 w-100 h-100">
            <Loader className="z-1 pt-64 d-flex flex-column align-items-center justify-content-center" message={isProcessingPayment ? 'Processing Payment' : 'Preparing Safe Payment Environment'} />
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentInformation;
