import { accruClient } from 'api';
import { history } from 'utils/history';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { $sidePanelFormDataDetails, $sidePanelSettingsDetails } from 'components/global/SidePanel/SidePanel.helpers';
import { getBaseRequest } from 'components/helpers/Filters.helpers';
import formatPhoneNumForApi from 'utils/formatPhoneNumForApi';
import {
  $customersSearchCache,
  $customersUi,
  $customersFilter,
  $customers,
  $customerDetail,
  $searchKey,
  $isCacheAvailable,
  $customerStatement,
} from 'components/views/Customers/_helpers/Customers.signals';

export const fetchAndSetAutocomplete = async () => {
  try {
    if (!$customersFilter.value.search) {
      $customersUi.update({ isSearchListOpen: false });
      return;
    }

    if ($customersFilter.value.search?.length < 3) {
      return;
    }

    $customersSearchCache.loadingStart();
    $customersUi.update({ isSearchListOpen: true });

    if ($isCacheAvailable.value) {
      return;
    }

    const { items } = await accruClient.customers.get({
      organizationId: $user?.value?.currentOrganization?.id,
      name: $customersFilter.value.search,
      currentOrganizationId: $user.value.currentOrganization.id,
    });

    if ($searchKey.value.length) {
      $customersSearchCache.update({
        ...$customersSearchCache.value,
        [$searchKey.value]: items || [],
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $customersSearchCache.loadingEnd();
  }
};

export const fetchAndSetCustomers = async () => {
  try {
    $customers.loadingStart();
    $customersUi.update({ isModalShow: false, isSearchListOpen: false });

    if ($customersFilter.value.customerId) {
      const response = await accruClient.customers.getOne({
        organizationCustomerId: $customersFilter.value.customerId,
        organizationId: $user?.value?.currentOrganization?.id,
      });
      $customers.update(response);
    } else {
      const filter = getBaseRequest($customersFilter.value);
      const response = await accruClient.customers.get(filter);
      $customers.update(response);
    }
  } catch (error) {
    handleNotification(error);
    $customers.loadingEnd();
  } finally {
    $customers.loadingEnd();
  }
};

export const setCustomersFromAutocomplete = async () => {
  try {
    $customers.loadingStart();
    $customersUi.update({ isSearchListOpen: false });
    if (!$searchKey.value) {
      await fetchAndSetCustomers();
    } else {
      $customers.update({
        items: $customersSearchCache.value[$searchKey.value] || [],
        totalCount: $customersSearchCache.value[$searchKey.value]?.length || 0,
      });
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $customers.loadingEnd();
  }
};

export const handleSelectCustomer = async ({ customer, isBreakpointDown }) => {
  try {
    $customerDetail.loadingStart();

    const result = await accruClient.customers.getOne({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId: customer.id,
    });

    $customerDetail.update(result);

    if (isBreakpointDown && isBreakpointDown('md')) {
      $customersUi.update({ isMobileModalOpen: true });
    }
    $sidePanelFormDataDetails.update(customer);
  } catch (error) {
    handleNotification(error);
  } finally {
    $customerDetail.loadingEnd();
  }
};

export const handleUpdateCustomer = async (updateData) => {
  try {
    $sidePanelSettingsDetails.loadingStart();
    const result = await accruClient.customers.update(updateData);
    const newCustomers = $customers.value.items;
    const indexOfUpdatedCustomer = newCustomers.findIndex((customer) => customer.id === result.id);
    newCustomers[indexOfUpdatedCustomer] = result;
    $customers.update({ items: newCustomers });
    return result;
  } catch (error) {
    handleNotification(error);
  } finally {
    $sidePanelSettingsDetails.loadingEnd();
  }
};

export const handleInvoiceClickOpen = ({ invoice }) => {
  history.push(`/invoices/${invoice.id}`, { invoice, from: history.location });
};

export const handleSelectSorting = (newField) => {
  const { selectedSorting } = $customersFilter.value;
  const { field, order } = selectedSorting[0];
  let newOrder = order === 'ASC' ? 'DESC' : 'ASC';

  if (newField !== field) newOrder = 'ASC';

  $customersFilter.update({
    selectedSorting: [{
      order: newOrder,
      field: newField,
    }],
  });
};

export const handleSelectSearchItem = async ({ id, isBreakpointDown }) => {
  const customer = { id };
  handleSelectCustomer({
    customer,
    isBreakpointDown,
  });
  setCustomersFromAutocomplete();
};

export const handleClearCustomersSearch = async () => {
  $customersFilter.update({ search: '', customerId: null });
  $customerDetail.reset();
  await fetchAndSetCustomers();
};

export const customerSortCallback = (a, b) => {
  const { selectedSorting } = $customersFilter.value;
  const { field, order } = selectedSorting[0];

  if (a?.[field] < b?.[field] || a.invoice_summary?.[field] < b.invoice_summary?.[field]) {
    return order === 'ASC' ? -1 : 1;
  }
  if (a?.[field] > b?.[field] || a.invoice_summary?.[field] > b.invoice_summary?.[field]) {
    return order === 'ASC' ? 1 : -1;
  }

  return 0;
};

export const updateSingleSignalCustomer = ({ customer }) => {
  if (!customer || !$customers.value.items) return;

  const updatedRecords = [...$customers.value.items];
  const edgeIndex = updatedRecords.findIndex(({ id }) => id === customer.id);

  if (edgeIndex === -1) return;

  updatedRecords[edgeIndex] = customer;

  $customers.update({ updatedRecords });

  if ($customerDetail.value.id === customer.id) $customerDetail.update(customer);
};

export const sendCustomerStatementEmails = async ({ customerId, customerContacts }) => {
  try {
    const sendToBaseCustomerEmail = customerContacts.includes('PRIMARY');

    const result = await accruClient.customers.sendStatementEmail({
      targets: {
        contact_ids: customerContacts.filter(id => id !== 'PRIMARY'),
        organization_customer_id: customerId,
        send_to_base_customer_email: sendToBaseCustomerEmail,
      },
      organizationId: $user.value.currentOrganization.id,
    });

    $customerStatement.update({ isStatementSendSuccessModalOpen: true });
    return result;
  } catch (error) {
    handleNotification(error);
  }
};

export const handleGetStatementLink = async (customerId) => {
  try {
    const response = await accruClient.customers.getStatementLink({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId: customerId,
    });
    return response;
  } catch (error) {
    handleNotification(error);
  }
};

export const handleAddCustomerContactFromStatement = async () => {
  try {
    const { contactFormName, contactFormEmail, contactFormPhoneNo } = $customerStatement.value;
    const response = await accruClient.contacts.createCustomerContact({
      organizationId: $user.value.currentOrganization.id,
      organizationCustomerId: $customerDetail.value.id,
      data: {
        email: contactFormEmail,
        is_default: false,
        name: contactFormName,
        send_invoice_reminders: false,
        phone_number: contactFormPhoneNo ? formatPhoneNumForApi(contactFormPhoneNo) : null,
      },
    });
    if (response) {
      $customerStatement.update({
        isContactFormOpen: false,
        contactFormName: '',
        contactFormEmail: '',
        contactFormPhoneNo: '',
      });
      handleSelectCustomer({ customer: $customerDetail.value });
    }
    return response;
  } catch (error) {
    handleNotification(error);
  }
};
