import { Button, Row } from 'react-bootstrap';
import { $acceptInviteDetail } from 'components/views/Auth/_shared/Auth.helpers';
import history from 'utils/history';

const InviteAccepted = () => (
  <div className="text-center">
    <h3>Invite from <strong>{$acceptInviteDetail.value.organization_name}</strong> accepted!</h3>
    <p>You will be redirected to homepage soon. Click the button below if you are not redirected.</p>
    <Row className="d-flex align-items-center justify-content-center my-24 mx-0">
      <Button
        variant="outline-primary"
        type="submit"
        onClick={() => history.replace('/customers')}
        className="w-100 h-100"
      >
        Go to home
      </Button>
    </Row>
  </div>
);

export default InviteAccepted;
