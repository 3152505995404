import { $settingsOrganizationUI } from 'components/views/SettingsOrganization/_helpers/SettingsOrganization.signals';
import ContactInfo from 'components/views/SettingsOrganization/_components/ContactInfo';
import TabNav from 'components/global/TabNav/TabNav';
import CompanyInfo from './_components/CompanyInfo';
import OrganizationUsers from './_components/OrganizationUsers';

const organizationTabs = [
  {
    key: 'company-info',
    title: 'Company Info',
    component: <CompanyInfo />,
  },
  {
    key: 'contact',
    title: 'Contact Info',
    component: <ContactInfo />,
  },
  {
    key: 'users',
    title: 'Users',
    component: <OrganizationUsers />,
  },
];

const OrganizationSettings = () => {
  const { currentTab } = $settingsOrganizationUI.value;
  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Organization Settings</h1>
      <TabNav
        tabs={organizationTabs.map(tab => ({ key: tab.key, title: tab.title }))}
        TabPaneComponents={organizationTabs.map(tab => tab.component)}
        onClick={(t) => $settingsOrganizationUI.update({ currentTab: t.key })}
        activeTab={currentTab}
        defaultActiveTab="info"
        template="underline"
      />
    </div>
  );
};

export default OrganizationSettings;
