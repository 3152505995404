/* eslint-disable no-unused-vars */
import { Col, Modal, Row, Image } from 'react-bootstrap';
import comingSoonBody from 'images/comingSoonBody.svg';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

// EXAMPLES
// { /* Video Coming Soon Modal */ }
// { /* <ComingSoonModal show={isComingSoonOpen} handleClose={() => setIsComingSoonOpen(!isComingSoonOpen)} videoLink="https://www.youtube.com/embed/EQZHGCo3Q2s" /> */ }
// { /* Items Coming Soon Modal */ }
// { /* <ComingSoonModal show={isComingSoonOpen} handleClose={() => setIsComingSoonOpen(!isComingSoonOpen)} items={['item1', 'item2', 'item3']} /> */ }
const ComingSoonModal = ({
  show,
  handleClose,
  videoLink,
  items,
}) => {
  if (videoLink) {
    return (
      <SimpleModal
        title="Coming Soon!"
      >
        <iframe
          className="rounded mt-16"
          width="auto"
          height="auto"
          src={videoLink}
          frameBorder="0"
          allowFullScreen
          title="Coming Soon Video"
        />
      </SimpleModal>
    );
  }
  return (
    <SimpleModal
      isShow={show}
      title="Coming Soon!"
      onCancel={handleClose}
      onClose={handleClose}
      cancelText="Close"
      isHideSave
    >
      <Row>
        <Col sm={4}>
          <Image src={comingSoonBody} width="auto" height="auto" />
        </Col>
        <Col sm={8} className="text-start my-auto">
          <h5>This is going to be so cool!</h5>
          We are working hard to bring this feature to life. Be sure to check back in soon!
          <ul className="mt-16 custom-bullet">
            {items && items.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </SimpleModal>
  );
};

export default ComingSoonModal;
