import { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import { faArrowLeft, faCheckCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Image, Card } from 'react-bootstrap';
import Money from 'components/global/Money';
import ContentWrapper from 'components/global/ContentWrapper';
import ComponentLoader from 'components/global/ComponentLoader';
import $user from 'signals/User.signals';
import { $invoice } from 'components/views/Invoices/Invoices.helpers';
import {
  $invoiceDetail,
  sendInvoiceDetailEmail,
  fetchAndSetInvoiceData,
} from 'components/views/InvoiceDetail/InvoiceDetail.helpers';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';
import fileIcon from 'images/file.svg';
import trashIcon from 'images/trash.svg';
import noBillsImg from 'images/noInvoices.svg';
import logEvent from 'utils/logEvent';

const InvoiceDetail = () => {
  const { id } = useParams();
  const history = useHistory();

  const init = useCallback(async () => {
    await fetchAndSetInvoiceData({ id });
  });

  useEffect(() => {
    init();
  }, [id, $user?.value?.currentOrganization?.id]);

  const handleSendInvoiceEmail = useCallback(async () => {
    await sendInvoiceDetailEmail({ id });
    logEvent({
      name: 'first_sent_invoice',
      metadata: {
        invoice: $invoice?.value,
        id,
      },
      isTrigger: true,
    });
  }, [$user?.value?.currentOrganization?.id, $invoice?.value]);

  const handleBack = useCallback(() => {
    if (history?.location?.state?.from) {
      history.goBack();
    } else {
      history.replace('/invoices');
    }
  }, [history]);

  if ($invoice?.value.isLoading) return <ComponentLoader />;

  if (!$invoice?.value?.id) {
    return (
      <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0">
        <Container fluid className="bg-gray-50 p-0 min-vh-100">
          <Row>
            <Col className="mt-80 col-md-6 offset-md-3">
              <Image src={noBillsImg} height={640} />
            </Col>
          </Row>
        </Container>
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0">
      <Container className="pt-lg-32 pt-16">
        <Row>
          <Button
            variant="eye"
            className="d-flex border-0 shadow-none align-items-center justify-content-start position-relative p-32 pb-24 cursor-pointer"
            onClick={() => handleBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-primary" />
            <p className="text-primary fw-400 ms-8 mb-0">Back to {history?.location?.state?.from?.pathname.replace('/', '') || 'invoices'}</p>
          </Button>
          {/* <Row className="d-flex justify-content-center mb-16">
            <Image src={ellipse277} className="w-auto h-auto" />
          </Row> */}
          <Row className="d-flex justify-content-center text-center mb-lg-32">
            <h1>{$invoice.value?.organization_customer?.name}</h1>
          </Row>
          <Col lg={2} className="d-none d-lg-flex align-items-end mb-48">
            <ThemeImage height={330} name="invoice_detail_left" />
          </Col>
          <Col lg={8} className="mb-sm-48">
            <Container className="pb-0 mb-0 position-relative bill-summary-container">
              <Card className="bg-gray-50 pt-40 pb-16 border w-100 h-auto">
                <div className={`px-56 my-16 w-100 ${$invoice.value?.file ? 'border-2 border-gray-800 border-bottom' : ''}`}>
                  <Row>
                    <Col xs={9}>
                      <p className="lead fw-800 text-primary mb-8">Balance Due:</p>
                    </Col>
                    <Col xs={3} className="d-flex justify-content-end pe-8">
                      <p className="fw-800 text-dark mb-8">id:{$invoice.value?.number}</p>
                    </Col>
                  </Row>
                  <Row>
                    <h1 className="fw-400 text-dark p-0 ps-16">
                      <Money amount={$invoice.value?.balance} />
                    </h1>
                    <hr />
                  </Row>
                  {$invoice.value?.status === 'PAID' && (
                    <Row>
                      <small className="text-success fw-500">
                        <FontAwesomeIcon icon={faCheckCircle} className="text-suc me-8" />
                        Paid
                      </small>
                    </Row>
                  )}
                  {$invoice.value?.is_overdue && (
                    <Row>
                      <small className="text-danger fw-500">
                        <FontAwesomeIcon icon={faXmark} className="text-danger me-8" />
                        Overdue {Math.abs(moment($invoice.value?.due_date).diff(moment(), 'days'))} days
                      </small>
                    </Row>
                  )}
                  <Row className="mt-32 mb-16">
                    <Col sm={7} lg={6} className="pe-64 pe-lg-16 w-auto">
                      <Row className="mb-16">
                        <small className="fw-800 text-dark">Bill Date</small>
                      </Row>
                      <h4 className="fw-400 text-dark">
                        {moment($invoice.value?.invoice_date).format('l')}
                      </h4>
                    </Col>
                    <Col sm={5} lg={6}>
                      <Row className="mb-16">
                        <small className="fw-800 text-dark">Due Date</small>
                      </Row>
                      <h4 className="fw-400 text-dark">
                        {moment($invoice.value?.due_date).format('l')}
                      </h4>
                    </Col>
                  </Row>
                  <Row className="mb-16">
                    <small className="fw-800 text-dark">Sent to</small>
                  </Row>
                  <Row className="mb-16">
                    <h4 className="fw-400 text-dark">{$invoice.value?.customer_email}</h4>
                  </Row>
                  <Row>
                    <small className="fw-400">Please note that the displayed invoice has been synchronized from QuickBooks and cannot be edited on this platform. To make any changes or modifications, kindly access and edit the invoice directly within your QuickBooks account.</small>
                  </Row>
                  <Row className="w-auto mb-16 mt-40">
                    <Button
                      variant="primary"
                      className="py-16"
                      disabled={$invoiceDetail?.value?.isLoading}
                      onClick={handleSendInvoiceEmail}
                    >
                      {$invoice.value.email_sent_at ? 'RESEND INVOICE' : 'SEND INVOICE'}
                    </Button>
                  </Row>
                </div>
                {$invoice.value?.file && (
                  <Row className="px-56">
                    <Col className="d-flex align-items-center p-8 cursor-pointer" style={{ border: '1px solid #F2F2F2', borderRadius: '15px' }}>
                      <div className="p-8" style={{ borderRadius: '10px', width: 45, height: 45 }}>
                        <Image src={fileIcon} height={24} />
                      </div>
                      <div className="d-flex flex-column ms-8">
                        <small>document.csv</small>
                        <small className="mb-0 text-start">kb</small>
                      </div>
                      <div className="ms-auto px-24">
                        <Button
                          variant="eye"
                          className=""
                          onClick={() => console.info('MVP?')}
                        >
                          <Image src={trashIcon} height={16} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}

              </Card>
            </Container>
          </Col>
          <Col lg={2} className="d-none d-lg-flex align-items-end mb-48">
            <ThemeImage height={420} name="invoice_detail_right" />
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default InvoiceDetail;
