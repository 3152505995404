import { accruClient } from 'api';
import moment from 'moment/moment';
import $user from 'signals/User.signals';
import { handleNotification } from 'components/global/Alert/Alert';
import { $bill, $bills, $billsFilter, $billsUI, $billsVendorOptions } from 'components/views/Bills/Bills.signals';
import { getStatusAndIsOverdue } from 'components/views/Invoices/Invoices.helpers';
import history from 'utils/history';
import formatCurrency from 'utils/formatCurrency';

export async function fetchAndSetBills() {
  try {
    $billsUI.update({
      isTableLoading: true,
    });
    $bills.loadingStart();

    const { status, isOverdue } = getStatusAndIsOverdue($billsFilter.value.status);

    const data = await accruClient.bills.get({
      latestAcctProviderStatus: status,
      latestAcctProviderIsOverdue: isOverdue,
      currency: 'USD',
      organizationId: $user?.value?.currentOrganization?.id,
      organizationVendorId: $billsFilter.value.organizationVendorId,
      skip: ($billsFilter.value.page - 1) * $billsFilter.value.take,
      take: $billsFilter.value.take,
      sorting: ($billsFilter.value.sortKey && $billsFilter.value.sortDirection)
        ? {
          field: $billsFilter.value.sortKey || undefined,
          order: $billsFilter.value.sortDirection?.toUpperCase() || undefined,
        }
        : undefined,
      startDate: $billsFilter.value.startDate ? moment($billsFilter.value.startDate).local() : undefined,
      endDate: $billsFilter.value.endDate ? moment($billsFilter.value.endDate).local() : undefined,
    });

    $bills.update(data);
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $billsUI.update({ selectedItems: [], isTableLoading: false });
    $bills.loadingEnd();
  }
}

export const fetchAndSetVendorOptions = async () => {
  try {
    $billsVendorOptions.loadingStart();

    const vendors = await accruClient.vendors.get({
      organizationId: $user.value.currentOrganization.id,
    });

    const options = vendors.edges.map(({ node: { id, name } }) => ({
      label: name, value: id,
    }));
    $billsVendorOptions.update({
      options,
    });
  } catch (error) {
    handleNotification(error);
  } finally {
    $billsVendorOptions.loadingEnd();
  }
};

export const fetchAndSetBillDetail = async (id) => {
  try {
    $bill.loadingStart();
    const response = await accruClient.bills.getOne({
      organizationId: $user.value.currentOrganization.id,
      organizationBillId: id,
    });
    $bill.update(response);
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $bill.loadingEnd();
  }
};

export const exportBillsCsv = (bills) => {
  const csvContent = bills.map((bill) => ({
    billNumber: bill.number,
    billDate: moment(bill.bill_date).format('l'),
    dueDate: moment(bill.due_date).format('l'),
    vendorName: bill.organization_vendor?.name,
    vendorEmail: bill.organization_vendor?.email,
    description: bill.description,
    status: bill.latest_acct_provider_status,
    isOverdue: bill.latest_acct_provider_is_overdue,
    amount: formatCurrency({ amountCents: Number(bill.total_amount) }),
    balance: formatCurrency({ amountCents: Number(bill.balance) }),
  })).reduce((acc, curr) => `${acc}${Object.values(curr).join(',')}\n`, 'data:text/csv;charset=utf-8,Bill Number,Bill Date,Due Date,Vendor Name,Vendor Email,Description,Status,Is Overdue,Amount,Balance\n');

  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
};

export function handleBillsRowClick({ bill }) {
  history.push(`/bills/${bill.id}`, { bill, from: history.location });
}

export async function handleBulkActionConfirm() {
  $billsUI.update({ isBulkActionsLoading: true });

  try {
    if ($billsUI.value.selectedBulkAction === 'Export CSV') {
      const csvData = $billsUI.value.selectedItems.map(({ data }) => data);
      exportBillsCsv(csvData);
    }
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  } finally {
    $billsUI.update({ isBulkActionsLoading: false, isBulkActionShow: false, selectedBulkAction: null });
  }
}
