import { createBrowserHistory } from 'history';

// We initialize a custom history
// object so that we can use it outside of components
// i.e. forcing login redirect
// when the BE returns 401
export const history = createBrowserHistory();
export const historyPush = (value) => {
  window.scrollTo(0, 0);
  return history.push(value);
};

export default history;
