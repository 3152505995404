import { Card, Row, Col, Image, Button, Form } from 'react-bootstrap';
import projectsConnections from 'images/projectsConnections.svg';
import checkCircleIcon from 'images/checkCircle.svg';
import { $customerDetail } from 'components/views/Customers/_helpers/Customers.signals';
import { $selectedVendor } from 'components/views/Vendors/_helpers/Vendors.signals';
import { $sidePanelSettingsConnections, handleConnect, handleDisconnect, handleUpdateConnLock } from 'components/global/SidePanel/SidePanelConnections.helpers';
import { useMemo } from 'react';
import { isProdVersion } from 'components/views/MVP/MVP.helpers';

const SidePanelConnections = ({ type, className }) => {
  const data = type === 'vendor'
    ? $selectedVendor.value
    : $customerDetail.value;

  const disconnectedActions = useMemo(() => {
    if (!data?.connection) {
      return (
        <Button
          className="px-16"
          onClick={() => handleConnect({ type })}
        >LET&apos;S CONNECT
        </Button>
      );
    }

    if (
      (type === 'customer' && data?.connection?.vendor_conn_status === 'ACCEPTED') ||
      (type === 'vendor' && data?.connection?.customer_conn_status === 'ACCEPTED')) {
      return <h4>Awaiting response from the {type}</h4>;
    }

    if (data.connection.is_valid) {
      return (
        <Button
          className="px-16"
          onClick={() => handleConnect({ type })}
        >ACCEPT INVITE
        </Button>
      );
    }

    return (
      <h4>This connection is invalid. Please reach out the support team.</h4>
    );
  }, [data]);

  return (
    <Card className={`bg-gray-50 ${className || ''}`}>
      {data?.connection?.is_connected ? (
        <Row className="m-0">
          <Col sm={12} className="text-center p-40">
            <Image src={checkCircleIcon} className="d-flex justify-content-center mx-auto mb-16" />
            <h3 className="text-primary fw-700">You are Connected with this Organization </h3>
            <hr className="border-gray-100" />
            <Row>
              <Col sm={10} className="text-start">
                <h5 className="fw-500 text-dark">Sync Contact Info</h5>
                <p className="text-dark fw-350">Syncing your bills in the accounting app streamlines your financial transactions and ensures accurate record-keeping, making it easier to track and manage your expenses.</p>
              </Col>
              <Col sm={2}>
                <Form.Check
                  className="form-check-radio-custom"
                  type="switch"
                  name="syncContactInfo"
                  checked={!!data?.conn_locked_data_at}
                  disabled={$sidePanelSettingsConnections.value.isLoading || isProdVersion()}
                  onChange={() => handleUpdateConnLock({
                    type, action: data?.conn_locked_data_at ? 'unlock' : 'lock',
                  })}
                />
                {isProdVersion() && <small className="text-primary">Coming Soon</small>}
              </Col>
            </Row>
            <hr className="border-gray-100" />
            <Button
              className="px-24 mt-16"
              variant="outline-primary"
              disabled={$sidePanelSettingsConnections.value.isLoading}
              onClick={() => handleDisconnect({ type })}
            >
              DISCONNECT
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="m-0">
          <Col sm={6} className="my-auto ps-40 py-16">
            <h3 className="text-dark fw-700">Connect with this Organization</h3>
            <p className="text-dark fw-350">This will enable you to sync their contact information, fostering smoother communication, while also streamlining your financial workflow and reducing manual data entry efforts.</p>
            {disconnectedActions}
          </Col>
          <Col sm={6} className="pe-0">
            <Image src={projectsConnections} width="100%" height="100%" className="rounded-4" />
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default SidePanelConnections;
