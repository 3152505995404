import { useCallback, useEffect } from 'react';
import { Row, Col, Container, Card, Form, InputGroup, Button } from 'react-bootstrap';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import DatePicker from 'components/global/Inputs/DatePicker';
import SearchableDropDown from 'components/global/Inputs/SearchableDropdown';
import { $addBillManual, $billForm, fetchAndSetVendorsManualBill, handleAddBill } from 'components/views/BillAdd/BillAdd.helpers';
import formatUsd from 'utils/formatUsd';
import { historyPush } from 'utils/history';

const AddBillManualForm = () => {
  const { allVendors, vendorSearchTerm, selectedCompany } = $addBillManual.value;
  const formData = $billForm?.value;

  const callBackFetchAndSetVendorsManualBill = useCallback(async () => {
    await fetchAndSetVendorsManualBill();
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      callBackFetchAndSetVendorsManualBill();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [vendorSearchTerm]);

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Bill Details</h1>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 justify-content-left mx-auto">
              <Form className="mt-80 mb-64">
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="fw-800 pb-0 small" htmlFor="inputDefault">Vendor name</Form.Label>
                  <SearchableDropDown
                    selected={selectedCompany}
                    options={allVendors}
                    onChangeText={(e) => $addBillManual.update({
                      vendorSearchTerm: e,
                    })}
                    value={vendorSearchTerm}
                    onSelect={(e) => {
                      if (e) {
                        $billForm.update({
                          ...formData,
                          organization_vendor_id: e.value,
                          metadata: e.metaData,
                        });
                        $addBillManual.update({
                          selectedCompany: e,
                          vendorSearchTerm: e.name,
                        });
                      }
                    }}
                    createNewItem={() => historyPush('/vendors/add')}
                    createNewType="Vendor"
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="control-label fw-800 pb-0 small">Amount</Form.Label>
                  <Form.Group className="form-group">
                    <InputGroup className="input-group mb-24">
                      <InputGroup.Text className="input-group-text border-0 ps-0 pe-1 bg-transparent">$</InputGroup.Text>
                      <UniversalInput
                        aria-label="Amount"
                        name="amount"
                        signal={$billForm}
                        inputFormatCallback={formatUsd}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="fw-800 pb-0 small" htmlFor="inputDefault">Bill number</Form.Label>
                      <UniversalInput
                        name="number"
                        type="number"
                        signal={$billForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="control-label fw-800 pb-0 small">Due Date</Form.Label>
                      <Form.Group className="form-group">
                        <InputGroup className="input-group mb-24">
                          <DatePicker
                            name="due_date"
                            placeholder="Due Date"
                            value={formData.due_date}
                            signal={$billForm}
                            className="mb-16"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="fw-800 pb-0 small" htmlFor="inputDefault">Additional Details</Form.Label>
                  <UniversalInput
                    name="description"
                    signal={$billForm}
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={handleAddBill}
                >
                  SAVE
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBillManualForm;
