import { Card, Row, Col, Image } from 'react-bootstrap';
import projectsComingSoon from 'images/projectsComingSoon.svg';

const SidePanelProjects = () => (
  <Card className="bg-gray-50 p-24 p-md-96">
    <Row>
      <Col className="text-center mb-16">
        <h2 className="fw-700 text-primary">Coming soon!</h2>
      </Col>
    </Row>
    <Row>
      <Col className="text-center mb-16">
        <div className="lead text-dark">We are currently working on the providing you a view for all of your projects. We just want to make sure it&apos;s just right before launching. </div>
      </Col>
    </Row>
    <Row>
      <Col className="text-center mb-64">
        <div className="base text-dark fw-800">Fun fact: Did you know that the most frustrating thing in the world is working on an app with a bunch of bugs?</div>
      </Col>
    </Row>
    <Row>
      <Col className="text-center">
        <Image src={projectsComingSoon} height={350} />
      </Col>
    </Row>
  </Card>
);

export default SidePanelProjects;
