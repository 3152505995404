import { useEffect, useState } from 'react';
import SuccessSmile from 'components/global/Alert/SuccessSmile';
import AlertExclamation from 'components/global/Alert/AlertExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Toast = ({ notification, onRemoveNotification }) => {
  const [isExiting, setIsExiting] = useState(false);
  const { timeout = 6000, variant, displayMessage, id } = notification;

  const closeWithAnimation = () => {
    setIsExiting(true);
    setTimeout(() => {
      onRemoveNotification(id);
    }, 1000);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => closeWithAnimation(), timeout);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const exclamationColor = variant === 'error' ? '#d23b32' : '#b88c1a';

  return (
    <div className={`z-index-9999 mx-auto w-50 mt-24 slide ${isExiting ? 'slide-out' : 'slide-in'}`}>
      <div className={`notification notification-${variant} shadow-sm pt-24 rounded-4`}>
        <div className="d-flex justify-content-center align-items-center pb-24">
          <div className="decoration-icon">
            {variant === 'success' ?
              <SuccessSmile /> :
              <AlertExclamation color={exclamationColor} />}
          </div>
          <div className="text-center">
            {(Array.isArray(displayMessage) ? displayMessage : [displayMessage]).map(message => (
              <div key={message}>{message}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="position-absolute" tabIndex={0} style={{ right: '30px', top: '16px' }} role="button" aria-label="close" onClick={() => onRemoveNotification(id)}>
        <FontAwesomeIcon
          style={{ height: '15px', width: '15px' }}
          icon={faTimes}
        />
      </div>
    </div>
  );
};

export default Toast;
