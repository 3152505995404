import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ContentWrapper from 'components/global/ContentWrapper';
import {
  $invoices,
  $invoicesUI,
  fetchAndSetInvoices,
  fetchAndSetCustomerOptions,
} from 'components/views/Invoices/Invoices.helpers';
import logEvent from 'utils/logEvent';
import { useEffectAsync } from 'utils/useEffectAsync';
import InvoiceBulkActions from 'components/views/Invoices/_components/InvoiceBulkActions';
import InvoiceFilters from 'components/views/Invoices/_components/InvoiceFilters';
import { $invoicesFilter } from 'components/views/Invoices/Invoices.signals';
import updateQueryParams, { updateSignalWithQueryParams } from 'utils/updateQueryParams';
import { handleInvoiceClickOpen } from 'components/views/Customers/_helpers/Customers.helpers';
import Totals from 'components/global/Totals';
import InvoicesTable from 'components/global/InvoicesTable';
import { setInvoiceTableHeaders } from 'components/global/InvoicesTable/invoiceTableFormat.helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Invoices = () => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const organizationCustomerId = urlParams.get('organizationCustomerId');

  useEffect(() => {
    logEvent({ name: 'view_all_invoices' });
    $invoicesUI.reset();
    updateSignalWithQueryParams($invoicesFilter);
    setInvoiceTableHeaders();
  }, [location]);

  useEffect(() => {
    updateQueryParams($invoicesFilter.value);
  }, [$invoicesFilter.value]);

  useEffectAsync(fetchAndSetCustomerOptions, []);

  useEffectAsync(async () => {
    await fetchAndSetInvoices();
  }, [$invoicesFilter.value]);

  return (
    <ContentWrapper fluid className="min-vh-100 w-100 p-lx-auto px-8 p-0">
      <Container className="p-0 pt-40 pt-lg-80">
        <Row>
          <Col>
            <div className="d-flex justify-content-center align-items-center text-center">
              <div className="me-2 pe-2">
                <h1 className="text-center me-2">Invoices</h1>
              </div>
              <Button
                variant="info"
                className="mb-8 circle-30 d-flex align-items-center justify-content-center"
                onClick={() => history.push('/invoices/add')}
              >
                <FontAwesomeIcon icon={faPlus} width={12} />
              </Button>
            </div>
          </Col>
        </Row>
        <Totals overdue={$invoices.value.overdue_amount} open={$invoices.value.balance} $signal={$invoices} />
        <div className={$invoicesUI.value.selectedItems?.length > 0 ? 'd-block' : 'd-none'}>
          <InvoiceBulkActions />
        </div>
        <div className={$invoicesUI.value.selectedItems?.length > 0 ? 'd-none' : 'd-block'}>
          <InvoiceFilters organizationCustomerId={organizationCustomerId} />
        </div>
        <Row className="mt-32">
          <Col>
            <InvoicesTable
              $ui={$invoicesUI}
              $filter={$invoicesFilter}
              items={$invoices.value.items}
              totalCount={$invoices.value.totalCount}
              currentPage={$invoicesFilter.value.page}
              currentPageItemsCount={$invoices.value.items?.length || 0}
              onRowClick={(row) => handleInvoiceClickOpen({ invoice: row.data })}
              onCurrentPageChange={(value) => $invoicesFilter.update({ page: value })}
            />
          </Col>
        </Row>
      </Container>
    </ContentWrapper>
  );
};

export default Invoices;
