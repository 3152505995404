import { Button, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import $user from 'signals/User.signals';
import { $settingsProfile, $settingsProfileUI } from 'components/views/SettingsProfile/_helpers/SettingsProfile.signals';
import { handleVerifyFormChange, resetPasswordStart, resetPasswordFinish, validatePassword } from 'components/views/SettingsProfile/_helpers/SettingsProfile.helpers';

const UpdatePassword = () => {
  const {
    currentPassword,
    newPassword,
    confirmPassword,
    passwordRequirements,
    verificationCode,
  } = $settingsProfile.value;
  const {
    passwordPage,
    passwordVisible,
    newPasswordVisible,
    confirmPasswordVisible,
  } = $settingsProfileUI.value;

  useEffect(() => {
    if (newPassword) {
      validatePassword();
    }
  }, [newPassword]);

  return (
    <Row className="mx-0">
      <h1 className="fw-400 mb-0">Password</h1>
      <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
        {passwordPage === 'password' && (
          <Form.Group>
            <h2 className="fw-700 mb-8">Update Password</h2>
            <Form.Label htmlFor="CurrentPassword"><strong>Current Password</strong></Form.Label>
            <Row className="m-0">
              <Col xs={11} className="p-0">
                <UniversalInput
                  className="mb-16"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Confirm Password"
                  name="currentPassword"
                  signal={$settingsProfile}
                />
              </Col>
              <Col xs={1} className="p-0 mb-16 border-bottom border-gray border-1">
                <Button
                  role="button"
                  tabIndex="0"
                  onClick={() => $settingsProfileUI.update({ passwordVisible: !passwordVisible })}
                  variant="eye"
                  className="p-0 w-100 h-100 text-primary m-auto rounded-0 border-dark d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon className="d-flex justify-content-center" icon={passwordVisible ? faEye : faEyeSlash} />
                </Button>
              </Col>
            </Row>
            <Form.Label htmlFor="NewPassword"><strong>New Password</strong></Form.Label>
            <Row className="m-0">
              <Col xs={11} className="p-0">
                <UniversalInput
                  className="mb-16"
                  placeholder="Enter New Password"
                  name="newPassword"
                  signal={$settingsProfile}
                  type={newPasswordVisible ? 'text' : 'password'}
                />
              </Col>
              <Col xs={1} className="p-0 mb-16 border-bottom border-gray border-1">
                <Button
                  role="button"
                  tabIndex="0"
                  onClick={() => $settingsProfileUI.update({ newPasswordVisible: !newPasswordVisible })}
                  variant="eye"
                  className="p-0 w-100 h-100 text-primary m-auto rounded-0 border-dark d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon className="d-flex justify-content-center" icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                </Button>
              </Col>
            </Row>
            <Form.Label htmlFor="Password"><strong>Confirm New Passwords</strong></Form.Label>
            <Row className="m-0">
              <Col xs={11} className="p-0">
                <UniversalInput
                  className="mb-16"
                  placeholder="Confirm Password"
                  type={confirmPasswordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  signal={$settingsProfile}
                />
              </Col>
              <Col xs={1} className="p-0 mb-16 border-bottom border-gray border-1">
                <Button
                  role="button"
                  tabIndex="0"
                  onClick={() => $settingsProfileUI.update({ confirmPasswordVisible: !confirmPasswordVisible })}
                  variant="eye"
                  className="p-0 w-100 h-100 text-primary m-auto rounded-0 border-dark d-flex justify-content-center align-items-center"
                >
                  <FontAwesomeIcon className="d-flex justify-content-center" icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                </Button>
              </Col>
            </Row>
            <Form.Label>Password must:</Form.Label>
            <div className="text-dark">
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.reqLength ? '#41696C' : '#CDCDCD'} />Be 12-36 characters in length</div>
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.number ? '#41696C' : '#CDCDCD'} />Contain at least one number</div>
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.uppercase ? '#41696C' : '#CDCDCD'} />Contain at least one uppercase letter</div>
              <div className="ms-16"><FontAwesomeIcon className="me-8" icon={faCheckCircle} color={passwordRequirements.symbol ? '#41696C' : '#CDCDCD'} />Contain at least one special character</div>
            </div>
          </Form.Group>
        )}
        {passwordPage === 'validation' && (
          <Form.Group>
            <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
            <p className="base fw-400 text-dark text-center">
              For your security, we just need to make sure this is you. We sent you a 6 digit code to {$user.value.user.email}
            </p>
            <Row>
              <div className="px-0 me-8 d-flex justify-content-around">
                {verificationCode.map((value, index) => (
                  <Form.Control
                    key={index}
                    className={`mb-40 h3 mx-8 text-center p-0 py-16 validation-form ${value === '' ? 'active' : ''}`}
                    type="text"
                    max="1"
                    value={value}
                    name="verificationCode"
                    onChange={(e) => handleVerifyFormChange(e, index, verificationCode)}
                  />
                ))}
              </div>
            </Row>
          </Form.Group>
        )}
      </Row>
      <Row className="mx-0">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            onClick={() => {
              $settingsProfile.update({
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                passwordRequirements: {
                  reqLength: false,
                  number: false,
                  uppercase: false,
                  symbol: false,
                },
                verificationCode: ['', '', '', '', '', ''],
              });
              $settingsProfileUI.update({
                passwordPage: 'password',
                passwordVisible: false,
                confirmPasswordVisible: false,
              });
            }}
            className="w-100 mb-16 mt-24"
            disabled={!newPassword && !currentPassword && !confirmPassword}
          >
            CANCEL
          </Button>
        </Col>
        <Col className="pe-0">
          {passwordPage === 'password' ? (
            <Button
              variant="primary"
              onClick={() => resetPasswordStart()}
              className="w-100 mb-16 mt-24"
              disabled={
                !newPassword ||
                !currentPassword ||
                confirmPassword !== newPassword ||
                !passwordRequirements.reqLength ||
                !passwordRequirements.number ||
                !passwordRequirements.uppercase ||
                !passwordRequirements.symbol
              }
            >
              UPDATE
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => resetPasswordFinish()}
              className="w-100 mb-16 mt-24"
              disabled={verificationCode.includes('')}
            >
              Confirm
            </Button>
          )}
        </Col>
      </Row>
    </Row>
  );
};

export default UpdatePassword;
