import { Card, Row, Col, Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSignal } from '@preact/signals-react';

const SelectOrganizationCard = ({ organization, isSeatAvailable, onSelect, isSelected, ...rest }) => {
  const isHovered = useSignal(false);

  return (
    <OverlayTrigger
      key="overlay-trigger"
      placement="bottom"
      show={!isSeatAvailable && isHovered.value}
      overlay={(
        <Tooltip id="tooltip-bottom" className="d-block">
          Your organization has reached the maximum number occupied of seats. Please ask the owner to upgrade your plan to add more seats.
        </Tooltip>
    )}
    >
      <Card
        onClick={(e) => {
          if (!isSeatAvailable) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            onSelect(organization);
          }
        }}
        onMouseEnter={() => { isHovered.value = true; }}
        onMouseLeave={() => { isHovered.value = false; }}
        className={`border bg-gray-50 rounded-4 py-16 px-32 ${isSeatAvailable ? 'cursor-pointer' : 'cursor-not-allowed'} mb-16 ${isSelected ? 'border-1 border-info' : 'row-hover-effect'}`}
        {...rest}
      >
        <Row className="align-items-center m-0">
          <Col className="p-0">
            <h5 className="text-dark-900 fw-700">{organization.name}</h5>
          </Col>
        </Row>
        <Row className="align-items-center m-0">
          {isSelected ? (
            <Col className="p-0 align-items-center">
              <Form.Check
                checked
                readOnly
                inline
                className="m-0 p-0 border-0"
              />
              <span className="m-0 p-0 base fw-500 text-dark ms-8">Currently signed in</span>
            </Col>
          ) : (
            <Col className="p-0">
              <Button
                variant="link"
                disabled={!isSeatAvailable}
                className="p-0 m-0 border-0 text-decoration-none"
              >

                <span className="fw-500 base text-decoration-underline text-primary">Sign in here</span> <FontAwesomeIcon className="text-primary" icon={faArrowRight} />
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </OverlayTrigger>
  );
};

export default SelectOrganizationCard;
