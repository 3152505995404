import { Button, Col, Form, Row } from 'react-bootstrap';
import { invoiceBulkActionsOptions } from 'utils/selectArrays';
import {
  $invoicesUI,
  handleBulkActionConfirm,
} from 'components/views/Invoices/Invoices.helpers';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import SimpleSelect from 'components/global/Inputs/SimpleSelect';

const InvoiceBulkActions = () => (
  <>
    <Row>
      <Col xs={12} sm={4}>
        <Form.Group>
          <Form.Label className="d-flex justify-content-between">
            <div className="text-secondary fw-700">Bulk Actions ({$invoicesUI.value.selectedItems?.length} Items Selected)</div>
            <Button
              variant="link"
              className="p-0 m-0 border-0 text-secondary"
              onClick={() => $invoicesUI.update({ selectedItems: [], isSelectAllChecked: false })}
            >
              Clear
            </Button>
          </Form.Label>
          <SimpleSelect
            placeholder="Select Bulk Action..."
            value={$invoicesUI.value.selectedBulkAction}
            options={invoiceBulkActionsOptions}
            onChange={(selectedOption) => {
              if (!selectedOption?.value) {
                $invoicesUI.update({
                  selectedBulkAction: null,
                });
                return;
              }
              $invoicesUI.update({
                isBulkActionShow: true,
                selectedBulkAction: selectedOption.value,
              });
            }}
            isLoading={$invoicesUI.value.isBulkActionsLoading}
            isClearable
          />
        </Form.Group>
      </Col>
    </Row>
    <SimpleModal
      isShow={$invoicesUI.value.isBulkActionShow}
      title="Confirm Action..."
      onClose={() => $invoicesUI.update({ isBulkActionsLoading: false, isBulkActionShow: false, selectedBulkAction: null })}
      onCancel={() => $invoicesUI.update({ isBulkActionsLoading: false, isBulkActionShow: false, selectedBulkAction: null })}
      onSave={handleBulkActionConfirm}
      saveText="Confirm"
    >
      <div className="py-32">
        <h5>Are you sure you would like to <b>{$invoicesUI.value.selectedBulkAction}</b> with selected items?</h5>
      </div>
    </SimpleModal>
  </>
);

export default InvoiceBulkActions;
