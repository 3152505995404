import Signal from 'signals/Signal';

export const $settingsLogs = Signal({
  logs: [],
  originalLogs: [],
});

export const $settingsLogsUI = Signal({
  page: 1,
  perPage: 20,
  isDetailModalOpen: false,
  selectedLog: null,
});
