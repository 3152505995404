import Signal from 'signals/Signal';
import { effect } from '@preact/signals-react';
import logEvent from 'utils/logEvent';

const billsArray = [
  {
    name: 'Bill',
    id: 1,
    amount: 5000,
  },
  {
    name: 'Bill',
    id: 2,
    amount: 20000,
  }];

export const $autoPay = Signal({
  autopayStep: 'method',
  backButtonHistory: [],
});

effect(() => logEvent(`autopay_${$autoPay.value.autopayStep}`));

export const $choosePayment = Signal({
  isEditingTotal: false,
});

export const $choosePaymentReturning = Signal({
  selectedPaymentType: null,
});

export const $bills = Signal({
  open: false,
  delete: false,
  list: billsArray,
});

export const handleBillsClicked = () => {
  $bills.value = {
    ...$bills.value,
    ...($bills.value.open ? { delete: false } : {}),
    open: !$bills.value.open,
  };
};
