import { $detailStatement } from 'components/views/Statement/statement.signals';

const StatementAddress = () => (
  <div className="text-lg-end mt-40 mt-lg-0">
    {/* Todo: Get the 700 font weight */}
    <div className="text-dark">Vendor Info:</div>
    <h2 className="fw-700">{$detailStatement.value.vendor.name}</h2>
    <h4 className="fw-400 mb-0">{$detailStatement.value.vendor.address1}</h4>
    <h4 className="fw-400 mb-0">{$detailStatement.value.vendor.address2}</h4>
    <h4 className="fw-400 mb-0">{$detailStatement.value.vendor.city} {$detailStatement.value.vendor.state} {$detailStatement.value.vendor.zip}</h4>
    <h4 className="fw-400 mb-0">{$detailStatement.value.vendor.email}</h4>
    <h4 className="fw-400 mb-16">{$detailStatement.value.vendor.phone}</h4>
    {$detailStatement.value.vendor.logo && (<img src={$detailStatement.value.vendor.logo} style={{ height: 56 }} alt="logo" />)}
  </div>
);

export default StatementAddress;
