import { ORGANIZATION_USER_ROLE } from 'accru-client';
import { findOrganizationUser, isImpersonating } from 'components/helpers/User.helpers';
import { handleNotification } from 'components/global/Alert/Alert';
import Signal from 'signals/Signal';
import $user from 'signals/User.signals';

export const $settings = Signal({
  currentTab: 'organization-settings',
  isMobileModalOpen: false,
});

/**
 * Map of organization permission levels.
 *
 * Lower numbers indicate higher permissions.
 *
 * @type {Record<ORGANIZATION_USER_ROLE, number>}
 */
export const organizationPermissionLevelMap = {
  [ORGANIZATION_USER_ROLE.OWNER]: 0,
  [ORGANIZATION_USER_ROLE.ADMIN]: 1,
  [ORGANIZATION_USER_ROLE.EDITOR]: 2,
  [ORGANIZATION_USER_ROLE.VIEWER]: 3,
};

/**
 * Checks if the user is allowed to perform a specific action based on the required permission.
 *
 * @param {ORGANIZATION_USER_ROLE} requiredPermission - The required permission for the action.
 * @returns {boolean} - Returns true if the user is allowed, false otherwise.
 */
export const hasPermission = (requiredPermission) => {
  if (isImpersonating.value) return true;
  const currentOrganizationUser = findOrganizationUser($user.value.currentOrganization?.id)?.role;

  const requiredLevel = organizationPermissionLevelMap[requiredPermission];
  const currentLevel = organizationPermissionLevelMap[currentOrganizationUser];

  if (typeof requiredLevel !== 'number' || typeof currentLevel !== 'number') {
    handleNotification('Unable to verify current user permissions in organization.', { variant: 'danger' });
    return false;
  }

  return currentLevel <= requiredLevel;
};
