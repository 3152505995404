import { Button, Col, Row } from 'react-bootstrap';
import Loadable from 'components/global/Loadable';
import { $acceptInviteDetail } from 'components/views/Auth/_shared/Auth.helpers';
import { handleInviteAccept, handleInviteReject } from 'components/views/Auth/AcceptInvite/AcceptInvite.helpers';

const ViewInvitation = () => (
  <div className="text-center">
    <Loadable template="text" signal={$acceptInviteDetail}>
      <h3>You have been invited to join organization <strong>{$acceptInviteDetail.value.organization_name}</strong></h3>
    </Loadable>
    <Loadable template="text" signal={$acceptInviteDetail}>
      <p>Org ID: {$acceptInviteDetail.value.organization_id}</p>
    </Loadable>
    <Row className="d-flex align-items-center justify-content-center my-24 mx-0">
      <Col xs={6} className="d-flex justify-content-around pt-0 ps-0">
        <Loadable template="text" signal={$acceptInviteDetail}>

          <Button
            variant="outline-primary"
            type="submit"
            onClick={() => handleInviteReject()}
            className="w-100 h-100"
          >
            Reject
          </Button>
        </Loadable>
      </Col>
      <Col xs={6} className="d-flex justify-content-around pe-0">
        <Loadable template="text" signal={$acceptInviteDetail}>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleInviteAccept()}
            className="w-100 h-100"
          >
            Accept
          </Button>
        </Loadable>
      </Col>
    </Row>
  </div>
);

export default ViewInvitation;
