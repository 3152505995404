import { useState } from 'react';
import { Image } from 'react-bootstrap';

const RoundedImage = ({ src, alt, height: componentHeight = '80px', width: componentWidth = '80px' }) => {
  // useState instead of a global signal since there will be multiple implementations
  const [dimensions, setDimensions] = useState(null);

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setDimensions({ width: naturalWidth, height: naturalHeight });
  };

  return (
    <div
      className="rounded-circle border border-primary position-relative d-flex align-items-center justify-content-center overflow-hidden"
      style={{ height: componentHeight, width: componentWidth }}
    >
      <Image
        src={src}
        alt={alt}
        height={dimensions?.height > dimensions?.width ? 'auto' : '100%'}
        width={dimensions?.height < dimensions?.width ? 'auto' : '100%'}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default RoundedImage;
