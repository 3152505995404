import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import Money from 'components/global/Money';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import {
  $newCardInfo,
  handleSubmitCardInfo,
  handleCancelCardInfo,
} from 'components/views/Statement/Old/Statement.helpers';
import formatCardExpirationDate from 'utils/formatExpirationDate';

const CardInfo = ({ className }) => (
  <Container className={className}>
    <Row className="p-24 pb-8 border-2 border-gray-800 mb-8 border-bottom">
      <h2 className="mt-24 fw-700 text-primary">Payment Details</h2>
    </Row>
    <div className="d-flex flex-column px-80 pt-40 pb-72">
      <Form className="mb-0 pb-16">
        <Form.Group className="form-group mb-24">
          <Form.Label className="d-flex justify-content-start" htmlFor="cardHolder">
            <p className="lead fw-800 mb-0">Card Holder</p>
          </Form.Label>
          <UniversalInput
            placeholder="Full Name"
            name="holderName"
            className="mt-8"
            signal={$newCardInfo}
          />
        </Form.Group>
        <Form.Group className="form-group mb-16">
          <Form.Label className="d-flex justify-content-start" htmlFor="cardNumber"><p className="lead fw-800 mb-0">Card Number</p></Form.Label>
          <UniversalInput
            placeholder="#### #### #### ####"
            name="cardNumber"
            className="mt-8 mb-32"
            signal={$newCardInfo}
          />
        </Form.Group>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="form-group mb-16">
              <Form.Label className="d-flex justify-content-start" htmlFor="expDate">
                <p className="lead fw-800 mb-0">Exp date</p>
              </Form.Label>
              <UniversalInput
                placeholder="MM/YY"
                name="expDate"
                customOnChange={(e) => $newCardInfo.update({
                  expDate: formatCardExpirationDate(e.target.value),
                })}
                className="mt-8"
                signal={$newCardInfo}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="form-group mb-16">
              <Form.Label className="d-flex justify-content-start" htmlFor="cvc">
                <p className="lead fw-800 mb-0">CVC</p>
              </Form.Label>
              <UniversalInput
                placeholder="***"
                name="cvc"
                type="password"
                className="mt-8"
                signal={$newCardInfo}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row className="d-flex justify-content-start align-content-center6 pt-16">
        <Col sm={8} xs={8} className="d-flex justify-content-start align-items-center">
          <Button
            variant="primary"
            className="flex-grow-1 px-40 py-8"
            onClick={() => handleSubmitCardInfo()}
            disabled={
              !$newCardInfo?.value?.holderName ||
              !$newCardInfo?.value?.cardNumber ||
              !$newCardInfo?.value?.expDate ||
              !$newCardInfo?.value?.cvc
            }
          >
            <small className="text-primary me-8">PAY</small>
            <Money amount={25000} className="text-primary" />
          </Button>
        </Col>
        <Col sm={4} xs={4} className="d-flex justify-content-end ps-0">
          <Button
            variant="outline-primary"
            label="CANCEL"
            className="text-dark flex-grow-1 py-8"
            onClick={handleCancelCardInfo}
          >
            <small className="text-dark">CANCEL</small>
          </Button>
        </Col>
      </Row>
    </div>
  </Container>
);

export default CardInfo;
