import UniversalInput from 'components/global/Inputs/UniversalInput';
import magnifyingImg from 'images/magnifying.svg';
import closeButtonImg from 'images/closeButton.svg';
import { Image, InputGroup } from 'react-bootstrap';
import { handleSelectOrganizationSearchClear } from 'components/views/SelectOrganization/SelectOrganization.helpers';
import $selectOrganization from 'components/views/SelectOrganization/SelectOrganization.signals';

const SelectOrganizationSearch = () => (
  <InputGroup
    className="py-8"
  >
    <InputGroup.Text
      className="ps-24 border-0"
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
      }}
    >
      <Image src={magnifyingImg} />
    </InputGroup.Text>
    <UniversalInput
      name="search"
      signal={$selectOrganization}
      className="custom-searchbox"
      autoComplete="off"
      placeholder="Search Organization"
    />
    <InputGroup.Text
      className="ps-24 border-0"
      style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
      }}
      onClick={handleSelectOrganizationSearchClear}
    >
      {$selectOrganization.value.search && (<Image src={closeButtonImg} />)}
    </InputGroup.Text>
  </InputGroup>
);

export default SelectOrganizationSearch;
