import { Col, Row } from 'react-bootstrap';
import TabNav from 'components/global/TabNav/TabNav';
import { $settingsReminder, deleteReminder, fetchAndSetReminderSettings } from 'components/views/SettingsReminders/SettingsReminders.helpers';
import Loader from 'components/global/Loader';
import { useEffect } from 'react';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import InvoiceReminders from './_components/InvoiceReminders';
import OverdueNotifications from './_components/OverdueNotifications';

const reminderTabs = [
  {
    key: 'invoice-reminders',
    title: 'Invoice Reminders',
    component: <InvoiceReminders />,
  },
  {
    key: 'overdue-notifications',
    title: 'Overdue Notifications',
    component: <OverdueNotifications />,
  },
];

const SettingsReminders = () => {
  const { currentTab, dueReminderSettings, overdueReminderSettings, deleteId } = $settingsReminder.value;

  useEffect(() => {
    fetchAndSetReminderSettings();
  }, []);

  if (!dueReminderSettings || !overdueReminderSettings) { return <Loader className="d-flex align-items-center justify-content-center my-40" />; }

  return (
    <div>
      <h1 className="d-none d-lg-block d-xl-block">Reminder Settings</h1>
      <TabNav
        tabs={reminderTabs.map(tab => ({ key: tab.key, title: tab.title }))}
        TabPaneComponents={reminderTabs.map(tab => tab.component)}
        onClick={(t) => $settingsReminder.update({ currentTab: t.key })}
        activeTab={currentTab}
        defaultActiveTab="info"
        template="underline"
      />
      <SimpleModal
        isShow={deleteId}
        title="Delete Reminder"
        onClose={() => $settingsReminder.update({ deleteId: null })}
        onCancel={() => $settingsReminder.update({ deleteId: null })}
        onSave={() => deleteReminder()}
        saveText="Delete"
        cancelText="Cancel"
      >
        <Row className="mb-24">
          <Col>
            Deleting a reminder is a permanent action.<br />
            You&apos;ll need to create again if you want to use it in the future. Confirm?
          </Col>
        </Row>
      </SimpleModal>
    </div>
  );
};

export default SettingsReminders;
