import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { $global } from 'signals/Global.signals';
import img from 'images/404Background.svg';
import NavBar from 'components/global/NavBar';
import ThemeImage from 'components/global/ThemeImage/ThemeImage';
import QuickBooksButton from 'components/global/QuickBooksButton';
import { $accountingOAuth, getAccountingOAuthUrl, handleAccountingOAuthCallback } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
import useOAuthCallback from 'hooks/useOAuthCallback';
import { handleNotification } from 'components/global/Alert/Alert';

const Disconnect = () => {
  const history = useHistory();
  const isConnecting = !!$accountingOAuth.value.provider;
  const { isSignedIn, isLoading } = $global.value;
  const { dispatchOAuth } = useOAuthCallback({
    signal: $accountingOAuth,
    onSuccess: handleAccountingOAuthCallback,
    onFailure: () => handleNotification('Something went wrong while connecting your accounting software. Please try again.', { variant: 'danger' }),
  });

  const handleConnect = async (provider) => {
    const url = await getAccountingOAuthUrl(provider);
    dispatchOAuth(url, provider);
  };

  return (
    <div>
      <div
        className="d-flex p-0 justify-content-between flex-column 100vh bg-404"
        style={{ backgroundImage: `url(${img})` }}
      >
        {!isLoading && isSignedIn && (
          <div className="pb-40">
            <NavBar />
          </div>
        )}
        <div className="container d-flex h-100 justify-content-center align-items-center">
          <div className="d-flex flex-column align-items-center justify-content-around">
            <h2 className="text-dark fw-700 text-center">Looks like QuickBooks Disconnected</h2>
            <p className="text-dark-300 fw-300 text-center">
              Your QuickBooks integration has been disconnected, and you can no longer send <br /> invoices directly from Accru to your QuickBooks account.
            </p>
            <ThemeImage name="disconnect.svg" className="img-fluid" alt="disconnect" />
            <p className="text-dark-300 fw-300 text-center">
              To reconnect <span className="text-primary fw-500">Accru</span> to your QuickBooks accounts, click
              {' '}
              <a href="mailto:info@accru.co" className="text-primary text-decoration-underline">
                here
              </a>
              {' '}
              to get help or <br /> click the  button below to reconnect now!
            </p>
            <QuickBooksButton onClick={() => handleConnect('QUICKBOOKS')} disabled={isConnecting || isLoading} />
            {!isLoading && !isSignedIn && (
              <Button onClick={() => history.push('/login')} className="mt-16" variant="primary">Login to Reconnect</Button>
            )}
            {!isLoading && isSignedIn && (
            <Button onClick={() => history.push('/customers')} className="pt-0 text-dark mt-16" variant="link">Back to Customers</Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disconnect;
