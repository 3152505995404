import { Col, Row } from 'react-bootstrap';
import SelectOrganizationCard from 'components/views/SelectOrganization/_components/SelectOrganizationCard';
import SelectOrganizationSearch from 'components/views/SelectOrganization/_components/SelectOrganizationSearch';
import { handleSelectOrganizationListClick, handleSelectOrganizationModalClose } from 'components/views/SelectOrganization/SelectOrganization.helpers';
import $selectOrganization from 'components/views/SelectOrganization/SelectOrganization.signals';
import $user from 'signals/User.signals';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

const SelectOrganizationModal = () => {
  const { user, currentOrganization } = $user.value;
  const { search } = $selectOrganization.value;

  const organizations = user?.organizations;

  return (
    <SimpleModal
      isShow={$selectOrganization.value.isModalOpen}
      title="Switch to another organization"
      onClose={() => handleSelectOrganizationModalClose()}
      onCancel={() => handleSelectOrganizationModalClose()}
      isHideSave
    >
      <Row>
        <Col>
          <p className="base fw-500 font-dark">You are associated with {organizations.length} {!!organizations?.length && organizations?.length > 1 ? 'different organizations' : 'organization'}. Select the company below you would like to view.</p>
        </Col>
      </Row>
      <Row>
        <SelectOrganizationSearch />
      </Row>
      <Row className="mt-16">
        <Col className="overflow-scroll-y">
          {organizations.filter(({ organization }) => !search || String(organization.name).toLowerCase().includes(String(search).toLowerCase()))
            .map(({ organization, organization_id, is_current_organization_user_seat_available }) => (
              <SelectOrganizationCard key={organization_id} organization={organization} isSeatAvailable={is_current_organization_user_seat_available} onSelect={() => handleSelectOrganizationListClick(organization_id)} isSelected={currentOrganization?.id === organization_id} />
            ))}
        </Col>
      </Row>
    </SimpleModal>
  );
};

export default SelectOrganizationModal;
