import Signal from './Signal';

/**
 * @typedef {Object} CurrentOrganization
 * @property {string} name
 * @property {string} email
 * @property {string} id
 * @property {OrganizationUser['role']} role
 * @property {number} subscriptionLevel
 * @property {Organization} data
 * @property {OrganizationUser} organizationUser
 */

export const $user = Signal({
  /** @type {User} */
  user: {
    first_name: '',
    last_name: '',
    organizations: [],
  },

  /** @type {OrganizationUser} */
  organizationOverride: null,

  selectedOrganizationId: null,
  isSyncModalOpen: false,

  /** @type {CurrentOrganization} */
  currentOrganization: null,
});

export default $user;
