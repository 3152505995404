import { accruClient } from 'api';
import moment from 'moment';
import Signal from 'signals/Signal';
import history, { historyPush } from 'utils/history';
import { handleNotification } from 'components/global/Alert/Alert';
import { $searchableDropdown } from 'signals/Global.signals';
import { validateLength } from 'utils/validateInput';
import $user from 'signals/User.signals';
import { getAllVendors } from 'components/views/Vendors/_helpers/Vendors.helpers';
import logEvent from 'utils/logEvent';
import { effect } from '@preact/signals-react';

export const $addBill = Signal({
  currentScreen: null,
  backButtonHistory: [],
});

effect(() => {
  if ($addBill.value.currentScreen) {
    logEvent(`add_bill.${$addBill.value.currentScreen}`);
  }
});

export const $billForm = Signal({
  number: null,
  description: '',
  due_date: null,
  amount: null,
  organization_vendor_id: null,
});

export const $addBillManual = Signal({
  allVendors: [],
  vendorSearchTerm: '',
  selectedCompany: null,
  infoModalOpen: false,
  uploadedFiles: [],
});

export const $addBillSync = Signal({
  isQuickbooksConnected: false,
  connectingModalOpen: false,
  isConfiguringOptions: false,
  pushOptions: null,
  selectedPushItem: null,
  selectedPushAccount: null,
  pullToggle: false,
  pushToggle: false,
});

export const handleBackButton = (navigationHistory) => {
  if (navigationHistory.length) {
    const current = navigationHistory.pop();
    $addBill.update({
      currentScreen: current,
      backButtonHistory: navigationHistory,
    });
  } else {
    history.push('/bills');
  }
};

export const handleNext = ({ current, next, navigationHistory }) => {
  navigationHistory.push(current);
  $addBill.update({
    currentScreen: next,
    backButtonHistory: navigationHistory,
  });
};

export const createBill = async ({ data }) => {
  try {
    const newBill = await accruClient.bills.create({
      organizationId: $user.value.currentOrganization.id,
      data,
    });
    return newBill;
  } catch (error) {
    handleNotification(error);
  }
};

export const handleFileChange = (event) => {
  const { uploadedFiles } = $addBillManual.value;
  const fileObj = event.target.files && event.target.files[0];
  if (!fileObj) {
    return;
  }
  const tempArray = uploadedFiles;
  tempArray.push({
    fileName: fileObj.name, fileSize: fileObj.size,
  });
  $addBillManual.update({
    uploadedFiles: tempArray,
  });
};

export const handleAddBill = async () => {
  try {
    $billForm.loadingStart();
    const {
      description,
      due_date: dueData,
      amount,
      organization_vendor_id: organizationVendorId,
    } = $billForm?.value;

    validateLength(organizationVendorId, 'Vendor name', 0, 100);
    validateLength(dueData, 'Due date', 0, 100);
    validateLength(amount, 'Amount due', 0, 100);
    validateLength(description, 'Description', 0, 100);

    const totalAmount = Number(amount.replace(/,/g, '')) * 100;
    const response = await createBill({
      data: {
        currency_code: 'USD',
        description,
        discount_amount: 0,
        amount: totalAmount,
        total_amount: totalAmount,
        bill_date: moment(),
        due_date: dueData,
        organization_project_id: null,
        organization_vendor_id: organizationVendorId,
      },
    });
    if (response) {
      $searchableDropdown.reset();
      logEvent({ name: 'add_bill_success' });
      historyPush('/bills');
    }
  } catch (error) {
    handleNotification(error);
  } finally {
    $billForm.loadingEnd();
  }
};

export const fetchAndSetVendorsManualBill = async () => {
  try {
    $addBillManual.loadingStart();
    const { vendorSearchTerm } = $addBillManual.value;
    if (!vendorSearchTerm || vendorSearchTerm.length < 3) {
      $addBillManual.update({ allVendors: [] });
      return;
    }
    const vendors = await getAllVendors({ name: vendorSearchTerm });
    const labeledVendors = vendors?.items?.map(c => ({
      name: c.name,
      value: c.id,
      metaData: {
        email: c.email,
      },
    }));
    $addBillManual.update({ allVendors: labeledVendors || [] });
  } catch (error) {
    handleNotification(error);
  } finally {
    $addBillManual.loadingEnd();
  }
};
