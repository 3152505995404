import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import { useEffect } from 'react';
import { $areYouStillHereModalOpen, $areYouStillHereTimerId, $authTimerId, $global } from 'signals/Global.signals';
import { resetSessionTimer } from 'utils/auth';

export const AreYouStillHereModal = () => {
  // Check if the user is signed in and reset session timer on each CLICK
  const { isSignedIn } = $global.value;
  useEffect(() => {
    if (!isSignedIn) return;
    window.addEventListener('click', resetSessionTimer);
    return () => {
      window.removeEventListener('click', resetSessionTimer);
      clearTimeout($authTimerId.value);
      clearTimeout($areYouStillHereTimerId.value);
    };
  }, [isSignedIn]);
  return (
    <SimpleModal
      isShow={$areYouStillHereModalOpen.value}
      onClose={() => $areYouStillHereModalOpen.reset()}
      onCancel={() => $areYouStillHereModalOpen.reset()}
      cancelText="Yes, I'm here"
      title="Are you still there?"
      isHideSave
    >
      <p>We noticed you&apos;ve been inactive for a while. Please confirm you&apos;re still here.</p>
    </SimpleModal>
  );
};

export default AreYouStillHereModal;
