/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { handleNotification } from 'components/global/Alert/Alert';
import {
  handleVerifyFormChange,
  handleEmailSubmitPartial,
  handleCodeVerificationPartial,
  $signUpForm,
} from 'components/views/Auth/SignUp/Signup.helpers.js';
import { useEffect } from 'react';
import ReturnToLoginRow from './ReturnToLoginRow';

// this email already exists in the system so we can simply propagate it and resubmit it
async function handleResendSubmit(verificationSignal) {
  try {
    verificationSignal.update({
      timerActive: true,
      timeLeft: 180,
    });
    await handleEmailSubmitPartial(verificationSignal?.value?.email);
    handleNotification('Verification code successfully sent', { variant: 'success' });
  } catch (error) {
    handleNotification(error, { variant: 'danger' });
  }
}

const VerificationForm = ({ invitedUser, isEmbed }) => {
  const handleTimerExpire = () => {
    $signUpForm.update({
      timerActive: false,
    });
  };

  useEffect(() => {
    if ($signUpForm.value?.timerActive) {
      const timerInterval = setInterval(() => {
        $signUpForm.update({
          timeLeft: $signUpForm.value?.timeLeft - 1,
        });
      }, 1000);
      if ($signUpForm.value?.timeLeft === 0) {
        clearInterval(timerInterval);
        handleTimerExpire();
      }
      return () => clearInterval(timerInterval);
    }
  }, [$signUpForm.value?.timerActive, $signUpForm.value?.timeLeft]);

  return (
    <Form.Group>
      <h2 className="fw-700 text-center mb-32">Verify it&apos;s you</h2>
      <p className="base fw-400 text-dark text-center">
        For your security, we just need to make sure this is you. We sent you a 6 digit code to {$signUpForm?.value?.email}
      </p>
      <Row>
        <div className="px-0 me-8 d-flex justify-content-around">
          {$signUpForm?.value?.verificationCode.map((code, index) => (
            <Form.Control
              key={index}
              className="mb-40 h1 mx-8 text-center p-0 py-16 validation-form"
              type="text"
              max="1"
              value={code}
              name="verificationCode"
              onChange={(e) => handleVerifyFormChange(e, index, $signUpForm?.value.verificationCode)}
            />
          ))}
        </div>
      </Row>
      <Button
        variant="primary"
        type="submit"
        onClick={() => handleCodeVerificationPartial(invitedUser)}
        className="w-100 btn-lg mb-16"
        disabled={$signUpForm?.value?.verificationCode.includes('')}
      >
        SUBMIT
      </Button>
      {!invitedUser && !isEmbed && (
        <ReturnToLoginRow />
      )}
      <Row className="d-flex align-items-center justify-content-center">
        <Col xs={12} className="d-flex justify-content-center align-items-center text-dark mb-24 fw-400 small">
          <Container className="text-center">
            Didn&apos;t receive verification code?
            {' '}
            {$signUpForm?.value?.timerActive ? (
              <span className="text-primary">
                Resend in {Math.floor($signUpForm?.value?.timeLeft / 60)}:{$signUpForm?.value?.timeLeft % 60 < 10 ? '0' : ''}
                {$signUpForm?.value?.timeLeft % 60}{' '}
              </span>
            ) : (
              <a
                onClick={() => handleResendSubmit($signUpForm)}
                target="_blank"
                className="text-primary"
                disabled={$signUpForm?.value?.timerActive}
              >
                Resend
              </a>
            )}
            {' '}or{' '}
            <a
              onClick={() => window.location.reload()}
              target="_blank"
              className="text-primary"
            >
              Use different email
            </a>
          </Container>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default VerificationForm;
