import { Button, Col, Form, Row } from 'react-bootstrap';
import { billsBulkActionsOptions } from 'utils/selectArrays';
import { handleBulkActionConfirm } from 'components/views/Bills/Bills.helpers';
import { $billsUI } from 'components/views/Bills/Bills.signals';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import SimpleSelect from 'components/global/Inputs/SimpleSelect';

const BillsBulkActions = () => (
  <>
    <Row>
      <Col xs={12} sm={4}>
        <Form.Group>
          <Form.Label className="d-flex justify-content-between">
            <div className="text-secondary fw-700">Bulk Actions ({$billsUI.value.selectedItems?.length} Items Selected)</div>
            <Button
              variant="link"
              className="p-0 m-0 border-0 text-secondary"
              onClick={() => $billsUI.update({ selectedItems: [], isSelectAllChecked: false })}
            >
              Clear
            </Button>
          </Form.Label>
          <SimpleSelect
            placeholder="Select Bulk Action..."
            value={$billsUI.value.selectedBulkAction}
            options={billsBulkActionsOptions}
            onChange={(selectedOption) => {
              if (!selectedOption?.value) {
                $billsUI.update({
                  selectedBulkAction: null,
                });
                return;
              }
              $billsUI.update({
                isBulkActionShow: true,
                selectedBulkAction: selectedOption.value,
              });
            }}
            isLoading={$billsUI.value.isBulkActionsLoading}
            isClearable
          />
        </Form.Group>
      </Col>
    </Row>
    <SimpleModal
      isShow={$billsUI.value.isBulkActionShow}
      title="Confirm Action..."
      onClose={() => $billsUI.update({ isBulkActionsLoading: false, isBulkActionShow: false, selectedBulkAction: null })}
      onCancel={() => $billsUI.update({ isBulkActionsLoading: false, isBulkActionShow: false, selectedBulkAction: null })}
      onSave={handleBulkActionConfirm}
      saveText="Confirm"
    >
      <div className="py-32">
        <h5>Are you sure you would like to <b>{$billsUI.value.selectedBulkAction}</b> with selected items?</h5>
      </div>
    </SimpleModal>
  </>
);

export default BillsBulkActions;
