import SimpleModal from 'components/global/SimpleModal/SimpleModal';
import { $settingsAccounting, disconnectAccountingProvider } from 'components/views/SettingsAccounting/SettingsAccounting.helpers';
import { Col, Row } from 'react-bootstrap';

const DisconnectionModal = () => (
  <SimpleModal
    isShow={$settingsAccounting.value.disconnectedProviderCode}
    title="Are You Sure You Want to Disconnect?"
    onClose={() => $settingsAccounting.update({ disconnectedProviderCode: null })}
    onCancel={() => $settingsAccounting.update({ disconnectedProviderCode: null })}
    onSave={() => disconnectAccountingProvider()}
    saveText="YES, DISCONNECT ME"
    cancelText="NO, I&apos;LL STAY CONNECTED"
    size="lg"
  >
    <h5 className="fw-700 text-dark">Disconnecting may result in missing out on stress-reducing features.</h5>
    <Row>
      <Col>
        <h5 className="fw-400 text-dark">Staying connected with QuickBooks provides businesses with</h5>
      </Col>
    </Row>
    <Row className="row-cols-auto">
      <Col>
        <ul className="text-start">
          <li><h5 className="fw-400 text-dark">Real-time financial insights</h5></li>
          <li><h5 className="fw-400 text-dark">Informed decision-making</h5></li>
          <li><h5 className="fw-400 text-dark">Streamlined accounting processes</h5></li>
          <li><h5 className="fw-400 text-dark">Reduced manual errors</h5></li>
          <li><h5 className="fw-400 text-dark">Accurate and timely financial data</h5></li>
        </ul>
      </Col>
    </Row>
  </SimpleModal>
);

export default DisconnectionModal;
