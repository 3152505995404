/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

function loadClarity() {
  const clarityToken = process.env.NODE_ENV === 'production' ? 'ntjrzx2e64' : 'nxkeuuivhh';
  (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", clarityToken);
};

loadClarity();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
