import { useEffect, useCallback, useRef } from 'react';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(context, args), delay);
  };
};

export const useEffectAsync = (asyncCallbacks, dependencyArray = [], debounceTime = 0) => {
  // Function to execute async tasks
  const fetchData = async () => {
    if (Array.isArray(asyncCallbacks)) {
      await Promise.all(asyncCallbacks.map(func => func()));
    } else {
      await asyncCallbacks();
    }
  };

  // Debounced version of fetchData
  const debouncedFetchData = useRef(debounce(fetchData, debounceTime)).current;
  const fetchDataCallbackDebounced = useCallback(async () => {
    await debouncedFetchData();
  }, [debouncedFetchData]);

  useEffect(() => {
    fetchDataCallbackDebounced();
  }, [...dependencyArray]);
};

export default useEffectAsync;
