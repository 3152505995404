import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect } from 'react';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import $user from 'signals/User.signals';
import { $settingsProfile } from 'components/views/SettingsProfile/_helpers/SettingsProfile.signals';
import { cancelProfileUpdate, deleteProfilePicture, handleBrowse, updateProfileData, uploadProfilePicture } from 'components/views/SettingsProfile/_helpers/SettingsProfile.helpers.js';
import Loadable from 'components/global/Loadable';
import RoundedImage from 'components/global/RoundedImage';
import ProfilePicUpload from 'components/global/ProfileCircle';

const BasicInfo = () => {
  const {
    profilePhoto,
    firstName,
    lastName,
  } = $settingsProfile.value;
  const { first_name: tempFirstName, last_name: tempLastName } = $user.value.user;
  useEffect(() => {
    const { user } = $user.value;
    if (user) {
      $settingsProfile.update({
        firstName: user.first_name,
        lastName: user.last_name,
        profilePhoto: user?.profile_picture_file?.public_url || null,
      });
    }
  }, []);

  return (
    <Row className="mx-0">
      <h1 className="fw-400 mb-0">Basic Info</h1>
      <Row className="mx-0 mt-24 bg-gray-50 rounded">
        <Col sm={3} className="p-0 h-100">
          <div className="cursor-pointer h-100 my-16" role="button" tabIndex={0} onClick={() => handleBrowse()}>
            <Loadable template="profilePic" signal={$settingsProfile}>
              <input
                id="file-input"
                className="d-none"
                type="file"
                accept="image/*"
                onChange={uploadProfilePicture}
              />
              {profilePhoto ? (
                <RoundedImage src={profilePhoto} alt="User Profile Picture" />
              ) : (
                <ProfilePicUpload firstName={firstName} lastName={lastName} />
              )}
            </Loadable>
          </div>
        </Col>
        <Col sm={9} className="p-0 my-auto py-32">
          <h2 className="fw-700 mb-8">Profile Picture</h2>
          <p className="fw-400 mb-0">Upload your Profile picture here</p>
          {profilePhoto && (
            <Button
              variant="outline-danger"
              onClick={() => deleteProfilePicture()}
              className="w-auto mt-8 btn-sm"
            >
              Delete Profile Picture
            </Button>
          )}
        </Col>
      </Row>
      <Row className="mx-0 mt-24 py-24 bg-gray-50 rounded">
        <Form.Group>
          <h2 className="fw-700 mb-16">Profile Info</h2>
          <Form.Label htmlFor="firstName"><strong>First Name</strong></Form.Label>
          <UniversalInput
            placeholder="First Name"
            className="mb-16"
            name="firstName"
            signal={$settingsProfile}
          />
          <Form.Label htmlFor="lastName"><strong>Last Name</strong></Form.Label>
          <UniversalInput
            placeholder="First Name"
            className="mb-16"
            name="lastName"
            signal={$settingsProfile}
          />
        </Form.Group>
      </Row>
      <Row className="mx-0 px-0">
        <Col className="ps-0">
          <Button
            variant="outline-primary"
            onClick={() => cancelProfileUpdate()}
            className="w-100 mb-16 mt-24"
            disabled={(firstName === tempFirstName && lastName === tempLastName)}
          >
            CANCEL
          </Button>
        </Col>
        <Col className="pe-0">
          <Button
            variant="primary"
            onClick={updateProfileData}
            className="w-100 mb-16 mt-24"
            disabled={!firstName || !lastName || (firstName === tempFirstName && lastName === tempLastName)}
          >
            SAVE CHANGES
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default BasicInfo;
