import Money from 'components/global/Money';
import { StatusBadge } from 'components/global/StatusPills/StatusPills';
import { handleBillsRowClick } from 'components/views/Bills/Bills.helpers';
import { $billsUI } from 'components/views/Bills/Bills.signals';
import moment from 'moment';
import { Button } from 'react-bootstrap';

export function setBillsTableHeaders() {
  const headers = [
    {
      sortKey: 'vendor_name',
      key: 'Name',
      value: 'Name',
    },
    {
      sortKey: 'bill_date',
      key: 'BillDate',
      value: 'Bill Date',
    },
    {
      sortKey: 'due_date',
      key: 'DueDate',
      value: 'Due Date',
    },
    {
      sortKey: 'number',
      key: 'Number',
      value: 'Number',
    },
    {
      sortKey: 'latest_acct_provider_status',
      key: 'Status',
      value: 'Status',
    },
    {
      sortKey: 'total_amount',
      key: 'Amount',
      value: 'Amount',
    },
    {
      sortKey: 'latest_acct_provider_balance',
      key: 'Balance',
      value: 'Balance',
    },
  ];
  $billsUI.update({ tableHeaders: headers });
}

export function getBillsTableRows(items = []) {
  const rows = items.map((item) => ({
    id: item.id,
    Name: item.organization_vendor.name,
    BillDate: () => (
      <>{moment(item.bill_date).format('MM/DD/YYYY')}</>
    ),
    DueDate: () => (
      <>{moment(item.due_date).format('MM/DD/YYYY')}</>
    ),
    Number: item.number,
    Status: () => {
      if (!item.last_sync_at) return <span />;
      if (item.latest_acct_provider_status === 'OPEN') {
        if (moment(item.due_date) < moment()) {
          return (<StatusBadge status="open" isOverdue />);
        }
        return (<StatusBadge status="open" className="border" />);
      }
      return (<StatusBadge status="paid" className="border" />);
    },
    Amount: () => (
      <span><Money amount={item.amount} /></span>
    ),
    Balance: () => (
      <span className="fw-700"><Money amount={item.latest_acct_provider_balance} /></span>
    ),
    Delivery: () => {
      const className = item.email_sent_at ? 'text-primary' : 'text-danger';
      const value = item.email_sent_at
        ? `Sent ${moment(item.email_sent_at).format('MM/DD/YYYY')}`
        : 'Unsent';

      return (
        <span className={className}>{value}</span>
      );
    },
    View: () => (
      <Button
        type="button"
        variant="link"
        onClick={() => handleBillsRowClick({ bill: item })}
      >
        View
      </Button>
    ),
    data: item,
  }));

  return rows;
}
