import { Container, Row, Col, Image } from 'react-bootstrap';
import billCsvExampleImg from 'images/billCsvExample.svg';
import { $invoiceAddSelect } from 'components/views/InvoiceAdd/InvoiceAdd.helpers';
import SimpleModal from 'components/global/SimpleModal/SimpleModal';

const InvoiceManualAddSelectModal = ({ handleNext }) => {
  const { infoModalOpen } = $invoiceAddSelect.value;
  return (
    <SimpleModal
      isShow={infoModalOpen}
      title="Required Details for upload"
      onClose={() => $invoiceAddSelect.update({
        infoModalOpen: false,
      })}
      onCancel={() => handleNext({ current: 'manualSelect', next: 'upload' })}
      onSave={() => { }}
      saveText="DOWNLOAD TEMPLATE"
      cancelText="UPLOAD .CSV FILE"
    >
      <Container fluid>
        <Row className="mt-8 pb-48">
          <Col>
            <lead className="fw-400 text-dark">Don&apos;t worry if you spreadsheet has other details, but please make sure it contains the following columns</lead>
          </Col>
        </Row>
        <Row>
          <Image src={billCsvExampleImg} />
        </Row>
      </Container>
    </SimpleModal>
  );
};
export default InvoiceManualAddSelectModal;
