/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
import { faSort, faSortDown, faSortUp, faGear, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableLoader from 'components/global/TableLoader';
import React from 'react';
import { Form, Table, Popover, OverlayTrigger } from 'react-bootstrap';

const SignalTable = ({
  $filter,
  $ui,
  onRowClick = () => { },
  onHeaderClick = () => { },
  headers = [],
  rows = [],
  isSelectable = false,
  canFilterColumns = false,
  onColumnToggle,
}) => {
  const handleToggleColumn = (header) => {
    if (typeof onColumnToggle === 'function') {
      const isVisible = !header.isHidden;
      onColumnToggle(header.key, isVisible);
    } else {
      const tempHeaders = [...headers];
      const headerIdx = tempHeaders.findIndex(h => h.key === header.key);
      tempHeaders[headerIdx].isHidden = !header.isHidden;
      $ui.update({ tableHeaders: tempHeaders });
    }
  };

  const columnTogglerPopover = (
    <Popover>
      <Popover.Body>
        <div className="text-dark fw-800 p-8">Displayed Columns</div>
        {headers.map(header => (
          <div
            key={header.key}
            role="button"
            tabIndex={0}
            className="cursor-pointer p-8 w-100 d-flex justify-content-between table-popover-option"
            onClick={() => handleToggleColumn(header)}
          >
            <div className="me-8">{header.value}</div>
            <div className={header.isHidden && 'invisible'}>
              <FontAwesomeIcon icon={faCheck} color="#41696C" />
            </div>
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="p-0 overflow-hidden" style={{ borderRadius: 12 }}>
      {/* Todo: Setup font size to be 16 based on a class */}
      <Table striped responsive style={{ fontSize: 16 }} hover>
        <thead className="bg-light">
          <tr>
            {isSelectable && (
              <td className="border-0 fw-800 bg-light ps-24 py-16">
                <Form.Check
                  checked={$ui.value.isSelectAllChecked}
                  onChange={(e) => {
                    e.stopPropagation();
                    $ui.update({
                      isSelectAllChecked: e.target.checked,
                      selectedItems: e.target.checked ? rows : [],
                    });
                  }}
                />
              </td>
            )}
            {headers.map(({ key, value, sortKey, isHidden = false }, idx) => (
              <React.Fragment key={key}>
                {!isHidden && (
                  <td
                    key={`table-${idx}`}
                    className={`border-0 fw-800 bg-light ps-24 text-nowrap py-16 ${idx === headers.length - 1 ? 'text-right' : ''}`}
                    onClick={() => {
                      // If there is no sort key it is not sortable.
                      if (!sortKey) {
                        return;
                      }
                      $filter.update({ page: 1 }); // Always reset the page to 1 when changing sort.

                      if ($filter?.value?.sortKey === sortKey && $filter?.value?.sortDirection === 'asc') {
                        $filter?.update({ sortDirection: undefined, sortKey: undefined }); // Reset if the current selected sort is asc
                      } else if ($filter?.value?.sortKey === sortKey && $filter?.value?.sortDirection === 'desc') {
                        $filter?.update({ sortDirection: 'asc' }); // Only need to set the direction to asc
                      } else {
                        $filter?.update({ sortDirection: 'desc', sortKey }); // Set both new key and direction
                      }

                      onHeaderClick({ key, value, sortKey }, idx);
                    }}
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    tabIndex={idx}
                  >
                    <b className="d-flex align-items-center">
                      <span className="me-8">{value}</span>
                      {sortKey && (
                        <>
                          {$filter?.value?.sortKey === sortKey ? (
                            $filter?.value?.sortDirection === 'asc'
                              ? <FontAwesomeIcon icon={faSortUp} className="my-auto me-8" size="xs" />
                              : <FontAwesomeIcon icon={faSortDown} className="my-auto me-8" size="xs" />
                          ) : (
                            <FontAwesomeIcon icon={faSort} className="my-auto me-8" size="xs" />
                          )}
                        </>
                      )}
                    </b>
                  </td>
                )}
              </React.Fragment>
            ))}
            {canFilterColumns && (
              <OverlayTrigger rootClose trigger="click" overlay={columnTogglerPopover}>
                <td className="border-0 bg-light pe-24 py-16 cursor-pointer">
                  <div className="w-100 d-flex justify-content-end">
                    <div><FontAwesomeIcon icon={faGear} /></div>
                  </div>
                </td>
              </OverlayTrigger>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 && !$ui.value.isTableLoading && (
            <tr>
              <td className="border-0 w-100" colSpan={headers.length + (canFilterColumns ? 2 : 1)}>No records found</td>
            </tr>
          )}
          {!!$ui.value?.isTableLoading && (
            <tr>
              <td colSpan={headers.length + (canFilterColumns ? 2 : 1)} className="text-center border-0">
                <TableLoader />
              </td>
            </tr>
          )}
          {rows.map((row, rowIdx) => (
            <tr onClick={() => onRowClick(row)} style={{ cursor: 'pointer', visibility: $ui.value?.isTableLoading ? 'hidden' : 'visible' }} key={rowIdx}>
              {isSelectable && (
                <td className="border-0 ps-24 py-16">
                  <Form.Check
                    checked={$ui.value.selectedItems?.find(({ id }) => id === row.id)}
                    onClick={(e) => {
                      $ui.update({ isShiftKey: e.shiftKey });
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      e.stopPropagation(); // Prevents the row click event from being triggered
                      let tmpItems = $ui.value.selectedItems || [];

                      if ($ui.value.isShiftKey && e.target.checked && $ui.value.lastCheckedIndex !== null) {
                        // Determine range
                        const start = Math.min($ui.value.lastCheckedIndex, rowIdx);
                        const end = Math.max($ui.value.lastCheckedIndex, rowIdx);

                        // Loop through all checkboxes in the range
                        rows.forEach((row2, index) => {
                          if (index >= start && index <= end) {
                            if (!tmpItems.find((id) => id === row2.id)) {
                              tmpItems.push(row2);
                            }
                          }
                        });
                      } else {
                        // Normal click
                        if (!e.target.checked) {
                          tmpItems = tmpItems.filter(({ id }) => id !== row.id);
                        } else {
                          tmpItems.push(row);
                        }
                      }

                      // Update state
                      $ui.update({
                        selectedItems: tmpItems,
                        lastCheckedIndex: rowIdx,
                        isShiftKey: false,
                      });
                    }}
                  />
                </td>
              )}
              {headers.map(({ key, isHidden }, idx) => (
                <React.Fragment key={idx}>
                  {!isHidden && (
                    <td
                      key={`header-${idx}`}
                      className={`border-0 ps-24 ${idx === headers.length - 1 ? 'text-right' : ''}`}
                      style={{ display: 'table-cell', verticalAlign: 'middle' }}
                    >
                      {typeof row?.[key] === 'function' && (
                        <>{row?.[key]()}</>
                      )}
                      {typeof row?.[key] === 'string' && (
                        <>{row?.[key]}</>
                      )}
                    </td>
                  )}
                </React.Fragment>
              ))}
              {canFilterColumns && <td />}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SignalTable;
