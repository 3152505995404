import { Row, Col, Container, Card, Form, InputGroup, Button } from 'react-bootstrap';
import { useEffect, useCallback } from 'react';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import DatePicker from 'components/global/Inputs/DatePicker';
import SearchableDropDown from 'components/global/Inputs/SearchableDropdown';
import {
  $invoiceForm,
  $invoiceManualForm,
  fetchAndSetCustomers,
  handleAddInvoice,
} from 'components/views/InvoiceAdd/InvoiceAdd.helpers';
import formatUsd from 'utils/formatUsd';
import { historyPush } from 'utils/history';

const InvoiceManualForm = ({ handleNext }) => {
  const { allCustomers, customerSearchTerm, selectedCompany } = $invoiceManualForm.value;

  const callbackFetchAndSetCustomers = useCallback(async () => {
    await fetchAndSetCustomers();
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      callbackFetchAndSetCustomers();
    },
    1000);
    return () => clearTimeout(timeout);
  }, [customerSearchTerm]);

  return (
    <>
      <Row>
        <Col className="text-center">
          <h1 className="text-primary fw-700 pt-16 pb-24">Invoice Details</h1>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col>
            <Card style={{ maxWidth: 620 }} className="mb-24 px-80 justify-content-left mx-auto">
              <Form className="mt-80 mb-64">
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Customer/Company name</Form.Label>
                  <SearchableDropDown
                    selected={selectedCompany}
                    options={allCustomers}
                    value={customerSearchTerm}
                    onChangeText={(e) => $invoiceManualForm.update({
                      customerSearchTerm: e,
                    })}
                    onSelect={(e) => {
                      $invoiceForm.update({
                        organization_customer_id: e.value,
                        metadata: e.metaData,
                      });
                      $invoiceManualForm.update({
                        selectedCompany: e,
                      });
                    }}
                    createNewItem={() => historyPush('/customers/add')}
                    createNewType="Customer"
                  />
                </Form.Group>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="control-label fw-800 pb-0 small">Amount</Form.Label>
                  <Form.Group className="form-group">
                    <InputGroup className="input-group mb-24">
                      <InputGroup.Text className="input-group-text border-0 ps-0 pe-1 bg-transparent">$</InputGroup.Text>
                      <UniversalInput
                        placeholder="Amount"
                        name="amount"
                        signal={$invoiceForm}
                        inputFormatCallback={formatUsd}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Group>
                <Row>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Invoice number</Form.Label>
                      <UniversalInput
                        name="number"
                        type="number"
                        signal={$invoiceForm}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group text-start mb-24">
                      <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Due Date</Form.Label>
                      <Form.Group className="form-group">
                        <InputGroup className="input-group">
                          <DatePicker
                            name="due_date"
                            placeholder="Due Date"
                            value={$invoiceForm?.value.due_date}
                            signal={$invoiceForm}
                            className=""
                          />
                        </InputGroup>
                      </Form.Group>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="form-group text-start mb-24">
                  <Form.Label className="col-form-label fw-800 pb-0 small" htmlFor="inputDefault">Additional Details</Form.Label>
                  <UniversalInput
                    name="description"
                    signal={$invoiceForm}
                  />
                </Form.Group>
                <Button
                  type="button"
                  variant="primary"
                  className="w-100 py-16 mb-24"
                  onClick={() => handleNext({ current: 'form', next: 'preview' })}
                  disabled={
                    !$invoiceForm.value.description ||
                    !$invoiceForm?.value.due_date ||
                    !$invoiceForm?.value.amount ||
                    !$invoiceForm?.value.number ||
                    !$invoiceForm?.value.organization_customer_id
                  }
                >
                  REVIEW INVOICE
                </Button>
                <Button
                  type="button"
                  variant="outline-primary border border-2 border-primary"
                  className="w-100 py-16"
                  onClick={() => handleAddInvoice({ handleNext })}
                  disabled={
                    !$invoiceForm.value.description ||
                    !$invoiceForm?.value.due_date ||
                    !$invoiceForm?.value.amount ||
                    !$invoiceForm?.value.number ||
                    !$invoiceForm?.value.organization_customer_id
                  }
                >
                  SAVE & CLOSE
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InvoiceManualForm;
