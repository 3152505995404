import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Image } from 'react-bootstrap';
import accruBlinkImg from 'images/accruSmileBlink.svg';

const AutopaySuccess = () => {
  const history = useHistory();
  return (
    <>
      <Row>
        <Col>
          <Row className="mb-8">
            <Image src={accruBlinkImg} height={70} />
          </Row>
          <Row className="mb-16">
            <h2>Your autopay is all set!</h2>
          </Row>
          <Row className="mb-32">
            <h5 className="text-primary fw-700">Confirmation 2DF49SV</h5>
          </Row>
          <Row className="mb-40">
            <h5>Your first payment will be Oc 1, 2022</h5>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-start align-content-center mb-40 px-32">
        <Col className="d-flex justify-content-start align-items-center">
          <Button
            variant="outline-primary"
            className="flex-grow-1 px-40 py-8"
            onClick={() => history.push('/statement')}
          >
            <small className="me-8">
              BACK TO STATEMENT
            </small>
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AutopaySuccess;
