import { Placeholder, Row } from 'react-bootstrap';

export const NameNav = () => (
  <>
    <Row className="mx-0">
      <Placeholder xs={5} as="h3" className="text-loadable" />
      <Placeholder xs={5} className="ms-8 text-loadable" as="h3" />
    </Row>
    <Placeholder xs={12} as="p" className="mb-0 text-loadable" />
  </>
);

export default NameNav;
