import { useLocation } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { AuthLayout } from 'components/global/Layout';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import PasswordInput from 'components/global/Inputs/PasswordInput';
import { handleResetPasswordPartial, $resetPassword } from './ResetPassword.helpers';

const ResetPassword = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const verificationCode = urlParams.get('token');
  const email = urlParams.get('email');
  const userId = urlParams.get('user_id');
  const { passwordVisible } = $resetPassword.value;

  return (
    <AuthLayout>
      <Form.Group>
        <Form.Label htmlFor="EmailAddress"><strong>New Password</strong></Form.Label>
        <UniversalInput
          placeholder="Enter New Password"
          className="mb-16"
          type={passwordVisible ? 'text' : 'password'}
          name="password"
          signal={$resetPassword}
        />
        <Form.Label htmlFor="Password"><strong>Confirm New Password</strong></Form.Label>
        <Row className="m-0">
          <Col sm={12} className="p-0">
            <PasswordInput
              signal={$resetPassword}
              name="confirmPassword"
              visabilityName="passwordVisible"
            />
          </Col>
        </Row>
        <Form.Label className="mt-16">Password must:</Form.Label>
        <ul className="text-dark">
          <li>Be 12-36 characters in length</li>
          <li>Contain at least one number</li>
          <li>Contain at least one uppercase letter</li>
        </ul>
        <Button variant="primary" type="submit" onClick={() => handleResetPasswordPartial(email, verificationCode, userId)} className="w-100 btn-lg mb-32">SUBMIT</Button>
      </Form.Group>
    </AuthLayout>
  );
};

export default ResetPassword;
