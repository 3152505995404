import ThemeImage from 'components/global/ThemeImage/ThemeImage';

const Loader = ({
  message = '',
  width = '100',
  className,
}) => (
  <div className={className}>
    <ThemeImage name="loader" height={width} />
    {message && (
      <h4 className="mt-16">{message}</h4>
    )}
  </div>
);

export default Loader;
